import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { iTargetSticky } from "../../interface/components/on-sticky.interface";
import { PlatformService } from "../../services/platform.service";

@Directive({
	selector: "[onSticky]",
})
export class OnStickyDirective {
	@Input() top: string | number;

	@Input() intransition: number = 300;

	@Input() outTransition: number = 200;

	@Output() isSticky = new EventEmitter<boolean>();

	@Output() onStickyScroll = new EventEmitter<iTargetSticky>();

	private timeoutID;

	private delay: number = (75 * this.outTransition) / 100;

	@HostListener("window:scroll", ["$event"])
	onScroll($event?) {
		if (!this.isBrowser) return;

		const wY = window.pageYOffset;
		let target = this.el.nativeElement;
		let y: number;
		if (this.top === "content") {
			y = target.clientHeight;
		} else {
			if (target.offsetTop <= 0) {
				y = this.top as number;
			} else {
				y = target.offsetTop - (this.top as number);
			}
		}

		this.onStickyScroll.emit({
			target: target,
			top: y,
			windowTop: wY,
		});

		if (wY >= y) {
			target.style.animationDuration = `${this.intransition}ms`;
			if (!target.classList.contains("active")) {
				target.classList.add("active");
				clearTimeout(this.timeoutID);
				this.isSticky.emit(true);
			}
		} else {
			target.style.animationDuration = `${this.outTransition}ms`;
			if (target.classList.contains("active")) {
				target.classList.remove("active");
				clearTimeout(this.timeoutID);
				this.timeoutID = setTimeout(() => {
					this.isSticky.emit(false);
				}, this.delay);
			}
		}
	}

	get isBrowser() {
		return this.Platform.isBrowser;
	}

	constructor(private Platform: PlatformService, private el: ElementRef<HTMLElement>) {
		this.el.nativeElement.classList.add("sticky");
		this.onScroll();
	}
}

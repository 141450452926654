import { eChannel, iAmount, iInfoAt, iInfoBy, iName, iProviderError, iUtm } from "../common.interface";
import { iBenefit } from "./benefit.interface";
import { iRequiredDocument } from "./documents.interface";
import { iBusinessData, iContactData, iLaborData, iPersonalData, iRepresentativesData } from "./profile.interface";

export interface iAccount {
	/** id autoincremental del usuario */
	id: number;
	/** uid hash del usuario */
	uid: string;
	/** Metodo de Registro */
	signupType: eAuthType;
	/** Metodo de Ingreso */
	signinType: eAuthType[];
	/** Variables de registro UTM */
	utmCreation: iUtm;
	/** Variables de cuenta UTM */
	utmAccountCreation: iUtm;
	/** Canal de Registro de Cuenta */
	channel: eChannel;
	/** Canal de Creación de Cuenta */
	accountCreationChannel: eChannel;
	/** Fecha de Registro de Cuenta */
	creationAt: number;
	createdAt?: number;
	/** Fecha de Creación de Cuenta */
	accountCreationAt: number;
	/** uid de la Empresa */
	businessUid: string;
	/** uid de Pais */
	countryUid: string;
	/** Tipo de usuario */
	userType: eUserType;
	/** Tipo de cuenta */
	accountType: eAccountType;
	/** Estado de la cuenta */
	status: eAccountStatus;
	/** Razon del estado */
	reasonStatus?: string;
	/** Determina si la cuenta esta bloqueada */
	accountLocked: boolean;
	/** Progreso de la cuenta */
	accountProgressStatus: eAccountProgressStatus[];
	/** Validación de Identidad  */
	identityValidation: iAccountIdentityValidation;
	/** Determina si el usuario es `Persona Fisica` o `Persona Juridica` */
	isLegalEntity: boolean;
	/** Información personal del usuario `Personas Fisicas` */
	personalData?: iPersonalData;
	/** Informacion laboral del usuario `Personas Fisicas` */
	laborData?: iLaborData;
	/** Informacion de la Empresa  `Persona Juridica` */
	businessData?: iBusinessData;
	/** Información de los Representates del la Empresa `Persona Juridica` */
	representativeData?: iRepresentativesData;
	/** Datos de Contacto */
	contactData: iContactData;
	/** Documentos Requeridos */
	requiredDocuments: iRequiredDocument[];
	/** Beneficios */
	benefitList: iBenefit[];
	/** Email del usuario */
	email: string;
	/** Avatar del usuario */
	userAvatar: string;
	/** Monto aprobado para invertir*/
	approved?: iAmount;
	/** Monto disponible para invertir */
	available?: iAmount;
	/** Información del admin que activo la cuenta */
	accountActivedBy: iInfoBy;
	/** Fecha de Cambio de estado a Activo */
	accountActivedAt: number;
	/** Información de a actualización de estado */
	updateStatus: iInfoAt;
	/** Manager de la cuenta */
	admin: iManager;
	/** Referido por */
	referredBy?: iName;
	/** Determina si la cuenta se encuentra bancarizada */
	isBanked: boolean;
}

/** Tipo de Autenticación */
export enum eAuthType {
	/** Ingreso/Registro por Formulario */
	FORM = "FORM",
	/** Ingreso/Registro por Facebook */
	FACEBOOK = "FACEBOOK",
	/** Ingreso/Registro por Google */
	GOOGLE = "GOOGLE",
	/** Ingreso/Registro por Admin */
	ADMIN = "ADMIN",
	/** Ingreso/Registro por API */
	API = "API",
}

/** Manager del usuario */
export interface iManager {
	/** Id numerico del usuario */
	id: number;
	/** uid hash del usuario */
	uid: string;
	/** Nombre del usuario Admin */
	name: string;
	/** Email del usuario Admin */
	email: string;
	/** Whatsapp del Usuario Admin */
	whatsapp: string;
	/** Avatar del usuario Admin */
	userAvatar: string;
	/** Uid de la Empresa */
	businessUid: string;
	/** Nombre de la Empresa */
	businessName: string;
	/** Fecha de Asignación */
	assignationAt: number;
}

/** Tipo de Usuario */
export enum eUserType {
	/** Usuario Comun */
	ACCOUNT = "ACCOUNT",
	/** Ex inversor */
	EX_INVESTOR = "EX_INVESTOR",
	/** Inversor */
	INVESTOR = "INVESTOR",
	/** Usuario Registrado */
	REGISTERED_USER = "REGISTERED_USER",
}

/** Tipo de Cuenta */
export enum eAccountType {
	/** Cuentas Classic */
	CLASSIC = "CLASSIC",
	/** Cuentas Black */
	BLACK = "BLACK",
	/** Cuentas Business */
	BUSINESS = "BUSINESS",
}

/** Estado de la cuenta */
export enum eAccountStatus {
	/** Cuenta Creada */
	INITIAL = "INITIAL",
	/** Cuenta Pendiente de Verificar */
	PENDING = "PENDING",
	/** Cuenta Activa */
	ACTIVE = "ACTIVE",
	/** Cuenta Pausada */
	PAUSED = "PAUSED",
	/** Cuenta Cancelada */
	CANCELED = "CANCELED",
}

/** Total de pasos para activar la cuenta */
export const ACCOUNT_TOTAL_PROGRESS_STATUS = 5;

/** Progreso para activar la cuenta */
export enum eAccountProgressStatus {
	/** El Usuario se registro en el Sitio */
	REGISTERED = "REGISTERED",
	/** El Usario Creo su cuenta */
	CREATED = "CREATED",
	/** El Usuario Valido su email */
	VALID_EMAIL = "VALID_EMAIL",
	/** El Usuario Valido su telefono */
	VALID_PHONE = "VALID_PHONE",
	/** El Usuario Activo su cuenta */
	ACTIVATED = "ACTIVATED",
}

/** Sub-Progreso para activar la cuenta */
export enum eAccountActiveStep {
	/** Validacion de Identidad */
	IDENTITY_VALIDATE = "IDENTITY_VALIDATE",
	/** Validacion  de los Documentos */
	DOCUMENTS_VALIDATE = "DOCUMENTS_VALIDATE",
	/** Validación de los datos personales */
	PERSONAL_DATA = "PERSONAL_DATA",
	/** Validación de los datos laborales */
	LABOR_DATA = "LABOR_DATA",
	/** Validación de los datos de contacto */
	CONTACT_DATA_PHYSICAL = "CONTACT_DATA_PHYSICAL",
	/** Validación de los datos de la empresa */
	BUSINESS_DATA = "BUSINESS_DATA",
	/** Validación de los datos de los representantes */
	REPRESENTATIVES_DATA = "REPRESENTATIVES_DATA",
	/** Validación de los datos de contacto */
	CONTACT_DATA_LEGAL = "CONTACT_DATA_LEGAL",
	/** Documentos Pendientes */
	REQUIRED_DOCUMENTS = "REQUIRED_DOCUMENTS",
	/** Revision Manual */
	REVISION_MANUALLY = "REVISION_MANUALLY",
}

export type AccountActiveStatus = "pending" | "inProgress" | "completed" | "hidden";

/** Interface de Validación de identidad  */
export interface iAccountIdentityValidation {
	/** Estado de la validación de identidad */
	status: eIdentityValidationStatus;
	/** Error del proveedor */
	error?: iProviderError;
	/** Numero de Intentos */
	attempts?: number;
	/** Provedor del servicio */
	provider: "mati";
	/** Version */
	version: string;
	/** Fecha de Ejecución */
	executedAt: number;
}

/** Estado de validación de identidad */
export enum eIdentityValidationStatus {
	/** El usuario aun no inicio la validación de identidad */
	LISTENING = "listening",
	/** El usuario inicio la validación y esta pendiente de ser validado */
	PENDING = "pending",
	/** El usuario ya fue validado */
	VERIFIED = "verified",
	/** El usuario requiere verificación manual */
	REVIEW_NEEDED = "reviewNeeded",
	/** El usuario fue rechazado */
	REJECTED = "rejected",
}

export interface iCustomerBasic {
	/** Uid del cliente */
	uid: string;
	/** Nombre del cliente */
	name: string;
	/** Avatar */
	userAvatar: string;
}

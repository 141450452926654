import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Subscription } from "rxjs";
import { eSiteStatus } from "src/app/interface/data/site.interface";
import { CrmCoreService } from "../crm-core.service";
import { CustomerService } from "../customer.service";
import { RouterPathsService } from "../router-paths.service";
import { SessionService } from "../session.service";

@Injectable({
	providedIn: "root",
})
export class StatusGuard implements CanActivate {
	private sCRMCore: Subscription;

	constructor(private CRMCore: CrmCoreService, private Session: SessionService, private Paths: RouterPathsService, private Customer: CustomerService, private Router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const urlToGo = state.url;
		return new Promise<boolean | UrlTree>((resolve) => {
			this.sCRMCore = this.CRMCore.isLoading.subscribe((isLoading) => {
				if (isLoading) return;

				const siteStatus = this.Session.status;
				switch (siteStatus) {
					case eSiteStatus.BLOCKED:
						if (!urlToGo.includes(this.Paths.GO.AUTH)) {
							if (!this.Customer.isAuth) {
								this.Session.setRedirectToUrlAfterLogin(urlToGo);
								const tree: UrlTree = this.Router.parseUrl(RouterPathsService.AUTH);
								return resolve(tree);
							}
						}
						resolve(true);
						break;
					case eSiteStatus.MAINTENANCE:
					case eSiteStatus.OFFLINE:
						this.Session.setRedirectToUrlAfterLogin("");
						resolve(true);
						break;
					case eSiteStatus.ACTIVE:
					default:
						resolve(true);
						break;
				}

				if (this.sCRMCore) this.sCRMCore.unsubscribe();
			});
		});
	}
}

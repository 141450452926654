import { Directive, TemplateRef, ViewContainerRef } from "@angular/core";
import { PlatformService } from "../../services/platform.service";

@Directive({
	selector: "[inClient]",
})
export class InClientDirective {
	constructor(private ViewContainer: ViewContainerRef, private TemplateRef: TemplateRef<any>, private Platform: PlatformService) {}

	ngOnInit() {
		if (this.Platform.isServer) return this.ViewContainer.clear();
		this.ViewContainer.createEmbeddedView(this.TemplateRef);
	}
}

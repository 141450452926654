import { Injectable } from "@angular/core";
import { eSignatureTypes } from "src/app/interface/data/shares.interface";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class RouterEndpointsService {
	constructor() {}

	/** Determina la version de la API */
	private version: string = "apiv2";
	/** Subscribe el navegador del Usuario al WebSocket */
	public SUBSCRIBE = `${this.version}/subscribe`;

	public SITE = {
		/** Informacion basica del Sitio */
		INFO: `${this.version}/sites/site`,
		/** Monedas */
		CURRENCIES: `${this.version}/sites/currencies`,
		/** Formulario de Contacto */
		CONTACT: `${this.version}/sites/contact`,
		/** Formulario de Canales */
		CHANNELS: `${this.version}/sites/channels`,
		/** Obtiene la información del usuario que refirio al cliente actual */
		REFERRER: (customerUid: string) => `${this.version}/sites/referrer/${customerUid}`,
		/** Obtiene la información de la empresa que refirio al cliente actual */
		BUSINESS: (slug: string) => `${this.version}/sites/business/${slug}`,
		/** Obtiene la información del usuario que refirio al cliente actual */
		USER: (userUid: string) => `${this.version}/sites/user/${userUid}`,
	};

	public SURVEY = {
		QUESTION: (surveyUid: string) => `${this.version}/survey/${surveyUid}`,
	};

	public LOCATION = {
		/** Retorna los Paises */
		COUNTRIES: `${this.version}/country`,
		/** Retorna los Estados */
		STATES: (countryId: string) => `${this.version}/country/states/${countryId}`,
		/** Retorna las Banderas */
		FLAG: (flagName: string) => `${this.version}/country/flag/${flagName}`,
	};

	public FILES = {
		/** Cambia la foto de Perfil del usuario */
		UPLOAD_AVATAR: `${this.version}/upload/avatar`,
		/** Sube un Archivo relacionado a los documentos requeridos */
		UPLOAD_DOCUEMENTS: `${this.version}/upload/documents`,
		/** @test Sube una archivo sobre una inversion */
		UPLOAD_INVESTMENTS: `${this.version}/upload/investments`,
		/** Url de descarga de archivo */
		DOWNLOAD: (urlDownload: string) => `${environment.apiUrl}/${this.version}${urlDownload}`,
		/** Url de decarga de contracto */
		DOCUMENT: `${this.version}/storage/document`,
		/** Elinina un Documento relacionado a los documentos requeridos */
		DELETE_DOCUMENT: (documentUid: string, fileUid: string) => `${this.version}/upload/documents/${documentUid}/${fileUid}`,
		/** @test Elimina un Documento relacionado a una inversion */
		DELETE_INVESTMENTS: `${this.version}/upload/investments`,
	};

	public AUTH = {
		/** Formulario de Ingreso */
		LOGIN: `${this.version}/auth/login/form`,
		/** Ingreso por Google */
		GOOGLE: `${this.version}/auth/login/google`,
		/** Ingreso por Facebook */
		FACEBOOK: `${this.version}/auth/login/facebook`,
		/** Formulario de Registro */
		SIGNUP: `${this.version}/auth/signup`,
		/** Registro por uid */
		SIGNUP_UID: (tempUid: string) => `${this.version}/auth/signup/${tempUid}`,
	};

	public CUSTOMERS = {
		/** Obtiene un Beneficio en base a la inversion */
		ADD_BENEFIT: (projectUid: string) => `${this.version}/customer/benefit/${projectUid}`,
	};

	public INVESTMENTS = {
		/** Obtiene el documento que el usuario tiene que firmar */
		GET_DOCUMENT: (type: eSignatureTypes, uid: string) => `${this.version}/investments/signature/${type}/${uid}`,
		/** Envia el Contrato firmado por el usuario */
		SIGNATURE_DOCUMENT: (type: eSignatureTypes, uid: string) => `${this.version}/investments/signature/${type}/${uid}`,
		/** Envia el Contrato firmado por el usuario */
		SIGNATURE_DOCUMENT_BY_PROJECT: (type: eSignatureTypes, projectUid: string, amount: number, currency: string) => `${this.version}/investments/signature/${type}/${projectUid}/${amount}/${currency}`,
		/** Realiza la inversión */
		MAKE: `${this.version}/investments/make`,
		/** Notifica el pago */
		NOTIFY_PAYMENT: `${this.version}/investments/notify`,
		/** Cancela la inversión Pendiente */
		CANCEL: (uid: string) => `${this.version}/investments/cancel/${uid}`,
		/** Elimina un movimiento */
		DELETE_MOVEMENT: (uid: string) => `${this.version}/investments/movement/${uid}`,
	};

	public BLOG = {
		/** Obtiene las notas del Blog */
		ENTRIES: (page: string) => `${this.version}/sites/blogs/${page}`,
		/** Obtiene datos de ayuda */
		HELPERS: `${this.version}/sites/blogs/helpers`,
		/** Obtiene los detalles de un nota en particular */
		ENTRY: (slug: string) => `${this.version}/sites/blogs/post/${slug}`,
	};

	public PROJECT = `${this.version}/projects`;

	public PROJECT_PATHS = {
		CALCULATORS: `${this.PROJECT}/calculators`,
		CALCULATOR_PROJECT: (slug: string) => `${this.PROJECT}/calculators/${slug}`,
		GET_SHEET: (slug: string) => `${this.PROJECT}/${slug}`,
		GET_SHEET_VERSION: (slug: string) => `${this.PROJECT}/v/${slug}`,
		GET_INVEST: (slug: string) => `${this.PROJECT}/invest/${slug}`,
		SET_INTEREST: (uid: string) => `${this.PROJECT}/interest/${uid}`,
	};

	/** Path relacionados a la cuenta del usuario */
	public ACCOUNT = {
		GET_USER_DATA: `${this.version}/account/userData`,

		PROFILE: `${this.version}/account/profile`,

		AVATAR: `${this.version}/account/profile/userAvatar`,

		SAVE_ON_BOARDING: `${this.version}/account/profile/saveOnBoarding`,

		PERSONAL_DATA: `${this.version}/account/profile/personalData`,

		MARITAL_STATUS: `${this.version}/account/profile/maritalStatus`,

		LABOR_DATA: `${this.version}/account/profile/laborData`,

		CONTACT_DATA: `${this.version}/account/profile/contactData`,

		BUSINESS_DATA: `${this.version}/account/profile/businessData`,

		ADD_REPRESENTATIVE: `${this.version}/account/profile/representatives`,

		REPRESENTATIVES: (reprensentativeUid: string) => `${this.version}/account/profile/representatives/${reprensentativeUid}`,

		CHANGE_EMAIL: `${this.version}/account/profile/email`,

		VALIDATE_CHANGE_EMAIL: `${this.version}/account/profile/validate/email`,

		CHANGE_PHONE: `${this.version}/account/profile/phone`,

		VALIDATE_CHANGE_PHONE: `${this.version}/account/profile/validate/phone`,

		REQUEST_DOCUMENTS: `${this.version}/account/requestDocuments`,

		DELETE_DOCUMENT: (requiredDocumentUid: string, documentUid: string) => `${this.version}/account/requestDocuments/${documentUid}/${requiredDocumentUid}`,

		PREFERENCES: `${this.version}/account/preferences`,

		INVESTMENTS: `${this.version}/account/investments`,

		BANK_ACCOUNT: `${this.version}/account/bank`,

		FIND_BANK_ACCOUNT: `${this.version}/account/bank/find`,

		BANK_ACCOUNT_UID: (bankUid: string) => `${this.version}/account/bank/${bankUid}`,

		BENEFITS: `${this.version}/account/benefit`,

		GENERATE_CUPONSTAR: `${this.version}/account/benefit/cuponstar`,

		NOTIFICATIONS: `${this.version}/account/notifications`,

		VALIDATE: (type: "id" | "taxId" | "email" | "phone") => `${this.version}/account/validate/${type}`,

		CHANGE_PASSWORD: `${this.version}/account/changePassword`,

		RECOVERY_PASSWORD: `${this.version}/auth/recoveryPassword`,

		RESET_PASSWORD: `${this.version}/auth/resetPassword`,

		REFERRALS: `${this.version}/account/referrals`,

		GET_IDENTITY: (customerUid: string) => `${this.version}/account/identity/${customerUid}`,

		SET_ATTEMPT_IDENTITY: (customerUid: string) => `${this.version}/account/identity/${customerUid}`,
	};

	/** Tracking */
	public TRACKING = "accessTraking";
}

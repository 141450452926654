<svg
	version="1.1"
	class="logo"
	[class]="theme"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 400 99.03"
	style="enable-background: new 0 0 400 99.03"
	xml:space="preserve"
>
	<g>
		<g>
			<g>
				<path
					class="logo-0"
					d="M102.87,63.22c-4.66-5.54-18.28-21.76-18.28-21.76c10.59-1.86,16.13-10.59,16.13-19.33
                    c0-9.8-7.13-19.71-20.54-19.71H58.56c0,0-0.4,0-0.66,0.29c-0.26,0.28-0.24,0.61-0.24,0.61s0,0.03,0,0.04l0,0v60.8
                    c0,0.48,0.39,0.87,0.87,0.87h7.4c0.48,0,0.87-0.39,0.87-0.87V16.75c0.02-2.31,0.03-3.72,0.03-3.74c0-0.1,0-0.19,0-0.29
                    c0-0.07,0-0.14,0-0.21c0-0.04,0-0.09,0-0.13c0-0.04,0-0.45,0.26-0.69c0.26-0.28,0.65-0.27,0.65-0.27h12.44
                    c7.5,0,11.21,5.38,11.21,10.68c0,5.38-3.71,10.77-11.21,10.77l-7.34,0.01c0,0-1.49-0.03-2.49,1.5c-0.65,0.98-0.63,2.31-0.09,3.28
                    c0.53,0.86,21.44,26.07,21.58,26.22c1.2,1.2,1.92,1.15,2.83,1.15c1.59,0,4.89,0,7.67,0C103.34,64.8,103.44,63.88,102.87,63.22z"
				/>
				<path
					class="logo-0"
					d="M108.46,11.65c5.57-5.66,13.41-9.23,21.94-9.23c8.62,0,16.37,3.57,22.03,9.23
                    c5.49,5.66,9.06,13.5,9.06,22.12c0,8.62-3.57,16.37-9.06,22.03c-5.66,5.75-13.41,9.23-22.03,9.23c-8.53,0-16.37-3.48-21.94-9.23
                    c-5.49-5.66-9.06-13.41-9.06-22.03c0-8.62,3.57-16.37,9.06-22.03 M145.86,18.37c-4.09-4.09-9.23-6.61-15.24-6.61
                    c-6.01,0-11.46,2.32-15.47,6.41c-4,4.01-6.52,9.38-6.52,15.56s2.53,11.49,6.53,15.5c4,4.09,9.28,6.55,15.28,6.55
                    c6.01,0,11.28-2.41,15.37-6.5c3.92-4.01,6.44-9.22,6.44-15.49S149.77,22.37,145.86,18.37"
				/>
				<path
					class="logo-0"
					d="M292.42,2.42l-7.4,0c-0.48,0-0.87,0.39-0.87,0.87v60.87c0,0.48,0.39,0.87,0.87,0.87h7.4
                    c0.48,0,0.87-0.39,0.87-0.87V3.3C293.29,2.82,292.89,2.42,292.42,2.42z"
				/>
				<path
					class="logo-0"
					d="M250.32,2.43l-21.59,0c-0.48,0-0.87,0.39-0.87,0.87v60.87c0,0.48,0.39,0.87,0.87,0.87l21.59,0
                    c20.49,0,30.79-15.54,30.79-31.2C281.1,18.18,270.81,2.43,250.32,2.43z M237.87,55.72c-0.48,0-0.87-0.39-0.87-0.87V12.62
                    c0-0.48,0.39-0.87,0.87-0.87h12.51c14.49,0,21.77,11,21.77,22.09c0,11.1-7.39,21.87-21.88,21.87L237.87,55.72z"
				/>
				<path
					class="logo-0"
					d="M339.84,2.42h-7.4c-0.48,0-0.87,0.39-0.87,0.87c0,0,0,42.58,0,42.6c0,6.7-6.46,10.09-13.05,10.23
                    c-6.59-0.14-13.05-3.53-13.05-10.23c0-0.02,0-42.6,0-42.6c0-0.48-0.39-0.87-0.87-0.87h-7.4c-0.48,0-0.87,0.39-0.87,0.87
                    l0.01,42.69c0,12.3,11.73,18.82,22.18,19.05c10.45-0.24,22.18-6.75,22.18-19.05l0.01-42.69C340.71,2.82,340.32,2.42,339.84,2.42z
                    "
				/>
				<path
					class="logo-0"
					d="M51.15,45.12c-0.4-0.28-0.95-0.18-1.23,0.21L49,46.58c-0.01,0.01,0,0.02-0.01,0.03
                    c-6.85,10.07-17.1,9.5-18.47,9.5c-6.08-0.16-11.44-2.57-15.36-6.67c-3.82-3.99-6.27-9.33-6.41-15.52v-0.34
                    c0.14-6.19,2.59-11.54,6.41-15.52c3.93-4.11,9.29-6.52,15.36-6.67c1.37,0,11.62-0.57,18.47,9.5c0.01,0.01,0,0.02,0.01,0.03
                    l0.92,1.25c0.28,0.4,0.83,0.49,1.23,0.21l6.12-4.32c0.46-0.31,0.46-0.82,0.21-1.23c0,0-0.95-1.26-0.97-1.28
                    c-8.4-11.8-20.46-13.1-26.01-13.1c-7.7,0-15.74,3.79-21.56,9.39C3.29,17.88,0.14,24.96,0,33.57v0.34
                    c0.14,8.62,3.67,16.27,8.91,21.86c5.44,5.62,13.12,9.26,21.6,9.26c5.55,0,18.1-1.62,26.01-13.08c0.01-0.01,0.97-1.28,0.97-1.28
                    c0.24-0.41,0.24-0.92-0.21-1.23L51.15,45.12z"
				/>
				<path
					class="logo-0"
					d="M226.52,2.43h-6.95c-1.51-0.01-1.72,0.84-1.9,1.47c-0.21,0.77-9.68,33.18-12.46,41.33
                    c-0.22,0.62-0.81,0.6-0.85,0.6c-0.6-0.02-0.79-0.58-0.79-0.58c-0.02-0.05-6.63-19.61-8.47-24.01c-0.67-1.58-2.19-3.19-4.4-3.2
                    c-2.21,0.01-3.74,1.61-4.4,3.2c-1.85,4.4-8.45,23.96-8.47,24.01c0,0-0.19,0.56-0.79,0.58c-0.03,0-0.63,0.01-0.85-0.6
                    c-2.78-8.15-12.25-40.55-12.46-41.33c-0.17-0.63-0.39-1.47-1.9-1.47h-6.95c-0.53,0-1.62,0.14-1.3,1.42
                    c0.12,0.47,19.98,60.54,20.02,60.6c0.13,0.33,0.38,0.59,0.75,0.59h5.28c0.36,0,0.62-0.25,0.75-0.57
                    c0.07-0.06,7.02-19.82,9.36-26.1c0.26-0.68,0.82-0.71,0.96-0.71c0.14,0,0.7,0.03,0.96,0.71c2.34,6.28,9.29,26.04,9.36,26.1
                    c0.13,0.32,0.38,0.57,0.75,0.57h5.28c0.37,0,0.63-0.26,0.75-0.59c0.04-0.06,19.9-60.13,20.02-60.6
                    C228.15,2.57,227.05,2.43,226.52,2.43z"
				/>
				<path
					class="logo-0"
					d="M399.13,2.42h-6.24c-0.81,0.05-1.18,0.38-1.48,0.77c-0.35,0.43-18.57,28.63-18.62,28.67
                    c-0.22,0.27-0.55,0.44-0.92,0.47c-0.37-0.03-0.7-0.2-0.92-0.47c-0.05-0.04-18.27-28.23-18.62-28.67
                    c-0.3-0.39-0.67-0.72-1.48-0.77h-6.24c-0.48,0-0.87,0.39-0.87,0.87v15.58v45.29c0,0.43,0.36,0.87,0.87,0.87c0,0,7.38,0,7.47,0
                    c0.42-0.06,0.79-0.4,0.79-0.84V25.57c0.02-0.76,0.56-1.43,1.36-1.43c0.81,0,1.14,0.68,1.53,1.27
                    c0.24,0.36,12.37,18.95,12.99,19.57c0.79,0.87,1.87,1.34,3.11,1.35c1.24-0.01,2.33-0.49,3.11-1.35
                    c0.63-0.62,12.75-19.21,12.99-19.57c0.39-0.58,0.71-1.27,1.53-1.27c0.79,0,1.34,0.67,1.36,1.43V64.2c0,0.44,0.37,0.78,0.79,0.84
                    c0.09,0,7.47,0,7.47,0c0.51,0,0.87-0.44,0.87-0.87V18.88V3.3C400,2.82,399.61,2.42,399.13,2.42z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					class="logo-1"
					d="M8.5,82.58c-1.67,0-2.99,0.56-3.95,1.67c-0.97,1.11-1.45,2.63-1.45,4.56c0,1.99,0.47,3.52,1.4,4.61
                    c0.93,1.08,2.26,1.62,3.98,1.62c1.06,0,2.27-0.19,3.62-0.57v1.55c-1.05,0.4-2.35,0.59-3.89,0.59c-2.24,0-3.96-0.68-5.18-2.03
                    c-1.21-1.36-1.82-3.29-1.82-5.78c0-1.56,0.29-2.93,0.88-4.11c0.58-1.18,1.43-2.08,2.53-2.72c1.1-0.64,2.4-0.96,3.9-0.96
                    c1.59,0,2.98,0.29,4.17,0.87l-0.75,1.52C10.79,82.85,9.65,82.58,8.5,82.58z"
				/>
				<path
					class="logo-1"
					d="M19.3,90.09v6.31h-1.77V81.22h4.16c1.86,0,3.24,0.36,4.13,1.07c0.89,0.71,1.33,1.79,1.33,3.22
                    c0,2.01-1.02,3.36-3.05,4.07l4.12,6.82h-2.09l-3.68-6.31H19.3z M19.3,88.57h2.42c1.25,0,2.16-0.25,2.74-0.74
                    c0.58-0.49,0.87-1.24,0.87-2.23c0-1-0.3-1.73-0.89-2.17c-0.59-0.44-1.54-0.66-2.85-0.66h-2.3V88.57z"
				/>
				<path
					class="logo-1"
					d="M45.99,88.79c0,2.43-0.61,4.34-1.84,5.73c-1.23,1.39-2.94,2.09-5.12,2.09c-2.24,0-3.96-0.68-5.18-2.05
                    c-1.21-1.37-1.82-3.3-1.82-5.79c0-2.47,0.61-4.39,1.83-5.75c1.22-1.36,2.95-2.04,5.19-2.04c2.18,0,3.88,0.69,5.11,2.08
                    C45.38,84.44,45.99,86.35,45.99,88.79z M33.89,88.79c0,2.06,0.44,3.62,1.31,4.68c0.88,1.06,2.15,1.59,3.82,1.59
                    c1.68,0,2.95-0.53,3.81-1.59c0.86-1.06,1.29-2.62,1.29-4.68c0-2.04-0.43-3.59-1.28-4.65c-0.86-1.06-2.12-1.58-3.8-1.58
                    c-1.68,0-2.96,0.53-3.84,1.59C34.33,85.22,33.89,86.76,33.89,88.79z"
				/>
				<path
					class="logo-1"
					d="M64.75,96.4h-1.74l-3.06-10.17c-0.15-0.45-0.31-1.02-0.49-1.7c-0.18-0.69-0.27-1.1-0.28-1.24
                    c-0.15,0.91-0.39,1.91-0.73,3l-2.97,10.1h-1.74L49.7,81.22h1.87l2.4,9.38c0.33,1.32,0.57,2.51,0.73,3.57
                    c0.19-1.27,0.46-2.51,0.83-3.72l2.72-9.23h1.87l2.86,9.32c0.33,1.07,0.61,2.28,0.84,3.63c0.13-0.98,0.38-2.18,0.75-3.59
                    l2.39-9.36h1.87L64.75,96.4z"
				/>
				<path
					class="logo-1"
					d="M85.44,88.67c0,2.51-0.68,4.42-2.04,5.75s-3.32,1.99-5.87,1.99h-4.21V81.22h4.65c2.36,0,4.2,0.65,5.5,1.96
                    C84.78,84.49,85.44,86.32,85.44,88.67z M83.57,88.73c0-1.98-0.5-3.47-1.49-4.48c-0.99-1-2.47-1.51-4.43-1.51h-2.57v12.13h2.15
                    c2.1,0,3.69-0.52,4.75-1.55C83.04,92.29,83.57,90.76,83.57,88.73z"
				/>
				<path class="logo-1" d="M92.71,96.4h-1.77V81.22h8.46v1.57h-6.7v5.56h6.29v1.57h-6.29V96.4z" />
				<path
					class="logo-1"
					d="M115.52,81.22v9.82c0,1.73-0.52,3.09-1.57,4.08c-1.05,0.99-2.48,1.49-4.31,1.49c-1.83,0-3.24-0.5-4.24-1.5
                    c-1-1-1.5-2.37-1.5-4.11v-9.78h1.76v9.91c0,1.27,0.35,2.24,1.04,2.92c0.69,0.68,1.71,1.02,3.05,1.02c1.28,0,2.27-0.34,2.96-1.02
                    c0.69-0.68,1.04-1.66,1.04-2.93v-9.89H115.52z"
				/>
				<path
					class="logo-1"
					d="M133.52,96.4h-2.01l-8.3-12.74h-0.08c0.11,1.5,0.17,2.87,0.17,4.11v8.63h-1.63V81.22h1.99l8.28,12.69h0.08
                    c-0.01-0.19-0.04-0.79-0.09-1.8c-0.05-1.01-0.07-1.74-0.05-2.18v-8.71h1.65V96.4z"
				/>
				<path
					class="logo-1"
					d="M151.94,88.67c0,2.51-0.68,4.42-2.04,5.75c-1.36,1.33-3.32,1.99-5.87,1.99h-4.21V81.22h4.65
                    c2.36,0,4.2,0.65,5.5,1.96C151.29,84.49,151.94,86.32,151.94,88.67z M150.07,88.73c0-1.98-0.5-3.47-1.49-4.48
                    c-0.99-1-2.47-1.51-4.43-1.51h-2.57v12.13h2.15c2.1,0,3.69-0.52,4.75-1.55C149.54,92.29,150.07,90.76,150.07,88.73z"
				/>
				<path class="logo-1" d="M157.45,96.4V81.22h1.77V96.4H157.45z" />
				<path
					class="logo-1"
					d="M177.37,96.4h-2.01l-8.3-12.74h-0.08c0.11,1.5,0.17,2.87,0.17,4.11v8.63h-1.63V81.22h1.99l8.28,12.69h0.08
                    c-0.01-0.19-0.04-0.79-0.09-1.8c-0.05-1.01-0.07-1.74-0.05-2.18v-8.71h1.65V96.4z"
				/>
				<path
					class="logo-1"
					d="M190.35,88.45h5.16v7.38c-0.8,0.26-1.62,0.45-2.45,0.58c-0.83,0.13-1.79,0.2-2.89,0.2
                    c-2.3,0-4.09-0.68-5.37-2.05c-1.28-1.37-1.92-3.28-1.92-5.75c0-1.58,0.32-2.96,0.95-4.15s1.55-2.09,2.74-2.72
                    c1.19-0.63,2.59-0.94,4.19-0.94c1.62,0,3.13,0.3,4.53,0.89l-0.69,1.56c-1.37-0.58-2.69-0.87-3.96-0.87
                    c-1.85,0-3.29,0.55-4.33,1.65c-1.04,1.1-1.56,2.63-1.56,4.58c0,2.05,0.5,3.6,1.5,4.66c1,1.06,2.47,1.59,4.41,1.59
                    c1.05,0,2.08-0.12,3.08-0.36v-4.67h-3.4V88.45z"
				/>
				<path
					class="logo-1"
					d="M221.05,88.67c0,2.51-0.68,4.42-2.04,5.75c-1.36,1.33-3.32,1.99-5.87,1.99h-4.21V81.22h4.65
                    c2.36,0,4.2,0.65,5.5,1.96C220.4,84.49,221.05,86.32,221.05,88.67z M219.18,88.73c0-1.98-0.5-3.47-1.49-4.48
                    c-0.99-1-2.47-1.51-4.43-1.51h-2.57v12.13h2.15c2.1,0,3.69-0.52,4.75-1.55C218.65,92.29,219.18,90.76,219.18,88.73z"
				/>
				<path class="logo-1" d="M235.03,96.4h-8.46V81.22h8.46v1.57h-6.7v4.89h6.29v1.56h-6.29v5.59h6.7V96.4z" />
				<path class="logo-1" d="M248.17,96.4V81.22h1.77V96.4H248.17z" />
				<path
					class="logo-1"
					d="M268.08,96.4h-2.02l-8.3-12.74h-0.08c0.11,1.5,0.17,2.87,0.17,4.11v8.63h-1.63V81.22h1.99l8.28,12.69h0.08
                    c-0.01-0.19-0.05-0.79-0.09-1.8c-0.05-1.01-0.07-1.74-0.05-2.18v-8.71h1.65V96.4z"
				/>
				<path
					class="logo-1"
					d="M283.06,81.22h1.9l-5.47,15.18h-1.74l-5.44-15.18h1.87l3.49,9.82c0.4,1.13,0.72,2.23,0.96,3.29
                    c0.25-1.12,0.58-2.24,0.98-3.35L283.06,81.22z"
				/>
				<path class="logo-1" d="M297.63,96.4h-8.46V81.22h8.46v1.57h-6.7v4.89h6.29v1.56h-6.29v5.59h6.7V96.4z" />
				<path
					class="logo-1"
					d="M304.89,90.09v6.31h-1.77V81.22h4.16c1.86,0,3.24,0.36,4.13,1.07c0.89,0.71,1.33,1.79,1.33,3.22
                    c0,2.01-1.02,3.36-3.05,4.07l4.12,6.82h-2.09l-3.68-6.31H304.89z M304.89,88.57h2.42c1.25,0,2.16-0.25,2.74-0.74
                    c0.58-0.49,0.87-1.24,0.87-2.23c0-1-0.3-1.73-0.89-2.17c-0.59-0.44-1.54-0.66-2.85-0.66h-2.29V88.57z"
				/>
				<path
					class="logo-1"
					d="M326.97,92.36c0,1.34-0.48,2.38-1.45,3.13c-0.97,0.75-2.28,1.12-3.95,1.12c-1.8,0-3.18-0.23-4.15-0.7v-1.7
                    c0.62,0.26,1.3,0.47,2.04,0.62c0.73,0.15,1.46,0.23,2.18,0.23c1.18,0,2.06-0.22,2.66-0.67c0.6-0.45,0.89-1.07,0.89-1.86
                    c0-0.53-0.11-0.96-0.32-1.29c-0.21-0.34-0.56-0.65-1.06-0.93c-0.5-0.28-1.25-0.6-2.26-0.97c-1.41-0.5-2.42-1.1-3.03-1.8
                    c-0.61-0.69-0.91-1.6-0.91-2.71c0-1.17,0.44-2.1,1.32-2.79c0.88-0.69,2.04-1.04,3.49-1.04c1.51,0,2.9,0.28,4.16,0.83l-0.55,1.54
                    c-1.25-0.53-2.47-0.79-3.65-0.79c-0.93,0-1.67,0.2-2.19,0.6c-0.53,0.4-0.79,0.96-0.79,1.67c0,0.53,0.1,0.96,0.29,1.29
                    c0.19,0.34,0.52,0.64,0.98,0.92c0.46,0.28,1.16,0.59,2.11,0.93c1.59,0.57,2.69,1.18,3.29,1.83
                    C326.67,90.48,326.97,91.32,326.97,92.36z"
				/>
				<path class="logo-1" d="M332.2,96.4V81.22h1.77V96.4H332.2z" />
				<path
					class="logo-1"
					d="M353.43,88.79c0,2.43-0.61,4.34-1.84,5.73c-1.23,1.39-2.94,2.09-5.12,2.09c-2.24,0-3.96-0.68-5.18-2.05
                    c-1.22-1.37-1.82-3.3-1.82-5.79c0-2.47,0.61-4.39,1.83-5.75c1.22-1.36,2.95-2.04,5.19-2.04c2.18,0,3.88,0.69,5.11,2.08
                    C352.82,84.44,353.43,86.35,353.43,88.79z M341.33,88.79c0,2.06,0.44,3.62,1.31,4.68c0.88,1.06,2.15,1.59,3.82,1.59
                    c1.68,0,2.95-0.53,3.81-1.59s1.29-2.62,1.29-4.68c0-2.04-0.43-3.59-1.28-4.65c-0.85-1.06-2.12-1.58-3.8-1.58
                    c-1.68,0-2.96,0.53-3.84,1.59C341.77,85.22,341.33,86.76,341.33,88.79z"
				/>
				<path
					class="logo-1"
					d="M370.8,96.4h-2.02l-8.3-12.74h-0.08c0.11,1.5,0.17,2.87,0.17,4.11v8.63h-1.63V81.22h1.99l8.28,12.69h0.08
                    c-0.01-0.19-0.05-0.79-0.09-1.8c-0.05-1.01-0.07-1.74-0.05-2.18v-8.71h1.65V96.4z"
				/>
				<path class="logo-1" d="M385.57,96.4h-8.46V81.22h8.46v1.57h-6.7v4.89h6.29v1.56h-6.29v5.59h6.7V96.4z" />
				<path
					class="logo-1"
					d="M399.63,92.36c0,1.34-0.48,2.38-1.45,3.13c-0.97,0.75-2.28,1.12-3.95,1.12c-1.8,0-3.18-0.23-4.15-0.7v-1.7
                    c0.62,0.26,1.3,0.47,2.04,0.62c0.73,0.15,1.46,0.23,2.18,0.23c1.18,0,2.06-0.22,2.66-0.67c0.6-0.45,0.89-1.07,0.89-1.86
                    c0-0.53-0.11-0.96-0.32-1.29c-0.21-0.34-0.56-0.65-1.06-0.93c-0.5-0.28-1.25-0.6-2.26-0.97c-1.41-0.5-2.42-1.1-3.03-1.8
                    c-0.61-0.69-0.91-1.6-0.91-2.71c0-1.17,0.44-2.1,1.32-2.79c0.88-0.69,2.04-1.04,3.49-1.04c1.51,0,2.9,0.28,4.16,0.83l-0.55,1.54
                    c-1.25-0.53-2.47-0.79-3.65-0.79c-0.93,0-1.67,0.2-2.19,0.6c-0.53,0.4-0.79,0.96-0.79,1.67c0,0.53,0.1,0.96,0.29,1.29
                    c0.19,0.34,0.52,0.64,0.98,0.92c0.46,0.28,1.16,0.59,2.11,0.93c1.59,0.57,2.69,1.18,3.29,1.83
                    C399.33,90.48,399.63,91.32,399.63,92.36z"
				/>
			</g>
		</g>
	</g>
</svg>

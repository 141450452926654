<div ngbDropdown #dropdown="ngbDropdown" placement="bottom-right">
	<button btn size="sm" theme="pill" [isTransition]="false" ngbDropdownToggle class="user-show" [class.btn-internal]="internalPage || isSticky">
		<span class="mr-2 user-data">{{fullName}}</span>
		<avatar [avatar]="userData.userAvatar" [size]="'xs'"></avatar>
	</button>

	<div ngbDropdownMenu id="user-sub-menu" class="user-submenu animated slideInUp shadow" [class]="{'user-no-created': !userData.accountProgressStatus.includes(AccountProgressStatus.CREATED)}">
		<div class="row no-gutters justify-content-center align-items-stretch">
			<div [class]="userData.accountProgressStatus.includes(AccountProgressStatus.CREATED) ? 'col-6 border-right' : 'col-12'">
				<div class="px-5 py-3 text-center">
					<div class="account-info mb-3">
						<!--User Avatar-->
						<div class="u-circles-wrap circle-container" [ngbTooltip]="tooltip" [animation]="false" [closeDelay]="1">
							<nz-progress [nzPercent]="accountProgressStatusPercent" [nzStrokeColor]="Theme.colors.gradientGreen" [nzShowInfo]="false" nzType="circle" nzStrokeLinecap="square" [nzWidth]="80"></nz-progress>
							<div class="content-centered-y avatar-container">
								<avatar [avatar]="userData.userAvatar" [size]="'lg'" [checked]="userData.status === AccountStatus.ACTIVE"></avatar>
							</div>
						</div>
						<!--User Avatar-->
						<!--Hola Usuario-->
						<span class="text-dark mb-1 user-firstname">{{('hi.user' | translate: {username: firstName})}}</span>
						<!--Hola Usuario-->
					</div>
					<!--Acciones-->
					<div class="account-action">
						<a btn size="sm" class="a-account" ngbDropdownItem [routerLink]="userData.accountProgressStatus.includes(AccountProgressStatus.CREATED) ? Paths.GO.ACCOUNT : Paths.GO.CREATE_ACCOUNT">
							<span>{{userData.accountProgressStatus.includes(AccountProgressStatus.CREATED) ? ('menu.account' | translate) : ('menu.create.account' | translate)}}</span>
							<span class="fas fa-angle-right ml-2"></span>
						</a>
						<button btn size="sm" theme="link" [isTransition]="false" ngbDropdownItem class="text-muted a-logout" (click)="logout()">
							<span>{{'menu.logout' | translate}}</span>
						</button>
					</div>
					<!--Acciones-->
				</div>
			</div>

			<div class="col-lg-6" *ngIf="userData.accountProgressStatus.includes(AccountProgressStatus.CREATED)">
				<!--Submenu Mi Cuenta-->
				<ul class="nav-submenu">
					<ng-container *ngFor="let menu of submenuProfile">
						<li ngbDropdownItem>
							<a *ngIf="(menu.id === 'referred' && isReferrals) || menu.id !== 'referred'" class="nav-link" [routerLink]="menu.path">{{menu.name | translate}}</a>
						</li>
					</ng-container>
				</ul>
				<!--Submenu Mi Cuenta-->
			</div>
		</div>
	</div>
</div>

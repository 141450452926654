import { Component, OnInit } from "@angular/core";

@Component({
	selector: "icon-academy, [icon-academy]",
	templateUrl: "./academy.icon.html",
	styleUrls: ["./academy.icon.scss"],
})
export class AcademyIcon implements OnInit {
	academyGradientId = "academyGradient-" + Math.floor(Math.random() * 1000000);

	constructor() {}

	ngOnInit(): void {}
}

<button
	btn
	[aria-label]="'home.main.store.' + type | translate"
	size="xs"
	theme="dark"
	[isTransition]="store.available"
	[disabled]="!store.available"
	class="btn-wide text-left mb-2 button-store"
	(click)="goStore()"
	[class]="store.available ? 'c-pointer' : 'c-initial'"
>
	<span class="media align-items-center">
		<i class="fab font-size-2 mr-3" [class.fa-google-play]="type === 'google'" [class.fa-apple]="type === 'apple'"></i>
		<span class="media-body">
			<ng-container *ngIf="store.available; else soon">
				<span class="d-block">{{ "home.main.store.available" | translate }}</span>
			</ng-container>
			<ng-template #soon>
				<span class="d-block">{{ "home.main.store.soon" | translate }}</span>
			</ng-template>
			<strong class="font-size-1">{{ "home.main.store." + type | translate }}</strong>
		</span>
	</span>
</button>

<ng-container *ngIf="!data.extra else EXTRA_TEMPLATE">
	<a class="nav-link" [routerLink]="data.path" routerLinkActive="current-link" [class.link-internal]="internalPage || isSticky">{{data.name | translate}}</a>
</ng-container>
<ng-template #EXTRA_TEMPLATE>
	<ng-container *ngIf="data.extra['id'] === 'LOGIN'">
		<a *ngIf="!isAuth" class="btn btn-sm btn-pill ml-3" [class.menu-button-internal]="internalPage || isSticky" [routerLink]="data.path">
			<span>{{data.name | translate}}</span>
		</a>
	</ng-container>
</ng-template>

import { Component, Input, OnInit } from "@angular/core";
import { FLAG } from "src/app/constants";
import { ApiCountriesService } from "src/app/services/api/api-countries.service";

@Component({
	selector: "avatar",
	templateUrl: "./avatar.component.html",
	styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit {
	/** link del avatar */
	@Input() avatar: string;
	/** Define un Imagen Avatar por defecto en caso de que no se encuentre el avatar */
	@Input() userDefault?: string;
	/** Color de Fondo por defecto */
	@Input() bgDefault: string;
	/** Define el texto alternativo de la imagen */
	@Input() alt: string = "Avatar";
	/** Define el pais */
	@Input() country: string;
	/** Define la cantidad de notificaciones */
	@Input() notification: number;
	/** Define si la cuenta esta verificada */
	@Input() checked: boolean;
	/** Define el tamaño del avatar */
	@Input() size: "responsive" | "xl" | "lg" | "md" | "sm" | "xs" = "md";
	/** Define si el avatar tiene sombra */
	@Input() shadow: boolean = false;
	/** Define si el avatar se encuentra centrado o con alineacion hacia la `izquierda` o `arriba` dependiendo su `orientacion` */
	@Input() center: boolean = true;
	/** Bandera de Pais */
	public flag: string;

	constructor(private APICountries: ApiCountriesService) {}

	ngOnInit(): void {
		this.setFlag();
	}

	ngAfterViewInit() {
		this.setFlag();
	}
	/** Configura la url de la bandera del pais */
	public setFlag() {
		if (this.country && this.APICountries.countries) {
			const country = this.APICountries.countries.find((country) => country.alpha2Code.toLowerCase() === this.country.toLowerCase());
			if (country) this.flag = FLAG(country.alpha2Code);
		}
	}
}

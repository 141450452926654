import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule, BrowserTransferStateModule, TransferState } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeEsAr from "@angular/common/locales/es-AR";
import localeEn from "@angular/common/locales/en";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarModule } from "./components/navbar/navbar.module";
import { registerLocaleData } from "@angular/common";
import { CrmCoreService } from "./services/crm-core.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { NzMessageModule } from "ng-zorro-antd/message";
import { LoaderModule } from "./components/loader/loader.module";
import { TranslateBrowserLoader } from "./services/class/translate-loader.browser";
import { StatusModule } from "./components/status/status.module";
import { environment } from "src/environments/environment";
import { FooterModule } from "./components/footer/footer.module";
import { BeginLastModule } from "./components/begin-last/begin-last.module";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { PlatformService } from "./services/platform.service";
import { RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { Config } from "./config";
import { ReCaptchaInterceptor } from "./services/guard/re-captcha.interceptor";
import { ServiceWorkerModule } from "@angular/service-worker";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { provideDatabase, getDatabase } from "@angular/fire/database";
import { getAuth, provideAuth } from "@angular/fire/auth";

registerLocaleData(localeEsAr, "es-AR");
registerLocaleData(localeEn, "en-US");

function loaderTranslate(http: HttpClient, transferState: TransferState) {
	return new TranslateBrowserLoader(http, transferState, "./assets/i18n/", ".json");
}

function loaderCRMCore(CRMCore: CrmCoreService, Platform: PlatformService) {
	return () => CRMCore.load();
	/* if (Platform.isBrowser) return () => CRMCore.loadClient(); */
	/* return () => CRMCore.loadServer(); */
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule/* .withServerTransition({ appId: "serverApp" }) */,
		BrowserAnimationsModule,
		HttpClientModule,
		HttpClientJsonpModule,
		RouterModule,
		AppRoutingModule,
		BrowserTransferStateModule,
		TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: loaderTranslate, deps: [HttpClient, TransferState] } }),
		/* ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production, registrationStrategy: "registerWhenStable:30000" }), */
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideDatabase(() => getDatabase()),
		provideAuth(() => getAuth()),
		LoadingBarHttpClientModule,
		LoadingBarRouterModule,
		NzMessageModule,
		NavbarModule,
		FooterModule,
		BeginLastModule,
		LoaderModule,
		StatusModule,
	],
	providers: [
		CrmCoreService,
		PlatformService,
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: Config.integration.RECAPTCHA_SITE },
		{ provide: HTTP_INTERCEPTORS, useClass: ReCaptchaInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: loaderCRMCore, deps: [CrmCoreService, PlatformService], multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

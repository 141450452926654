import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InClientDirective } from "./in-client.directive";

@NgModule({
	declarations: [InClientDirective],
	imports: [CommonModule],
	exports: [InClientDirective],
})
export class InClientDirectiveModule {}

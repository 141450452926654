import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PlatformService } from "src/app/services/platform.service";

@Component({
	selector: "sidenav",
	templateUrl: "./sidenav.component.html",
	styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
	@Input() position?: "left" | "right" = "left";

	@Input() active: boolean = false;

	@Output() activeChange = new EventEmitter<boolean>();

	constructor(private Platform: PlatformService) {}

	ngOnInit() {
		this.setActive();
	}

	ngOnChanges() {
		this.setActive();
	}

	private setActive() {
		if (!this.Platform.isBrowser) return;
		if (this.active) {
			let winScrollY = window.scrollY;
			document.body.style.overflow = "hidden";
			window.scrollBy(winScrollY, 0);
		} else {
			document.body.style.overflow = "";
		}
	}

	public handleClose() {
		document.body.style.overflow = "";
		this.activeChange.emit(false);
	}

	public handleToggle() {
		if (this.active) {
			this.active = false;
			document.body.style.overflow = "";
		} else {
			this.active = true;
			let winScrollY = window.scrollY;
			document.body.style.overflow = "hidden";
			window.scrollBy(winScrollY, 0);
		}
		this.activeChange.emit(this.active);
	}
}

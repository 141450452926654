<svg [class]="className" [style]="styleName" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 120 120" xml:space="preserve" class="buildings">
	<polyline class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="85.3,109.2 85.3,91.3 103.1,91.3 103.1,109.2 "></polyline>
	<line class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="94.2" y1="91.3" x2="94.2" y2="109.2"></line>
	<g>
		<rect x="85.3" y="36.9" class="buildings-2 fill-none stroke-primary" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="6" height="6"></rect>
		<rect x="97.2" y="36.9" class="buildings-1 fill-none stroke-gray-400" opacity=".5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="6" height="6"></rect>
	</g>
	<g opacity=".5">
		<rect x="85.3" y="49.8" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="6" height="6"></rect>
		<rect x="97.2" y="49.8" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="6" height="6"></rect>
	</g>
	<g opacity=".5">
		<rect x="85.3" y="62.6" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="6" height="6"></rect>
		<rect x="97.2" y="62.6" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="6" height="6"></rect>
	</g>
	<g opacity=".5">
		<rect x="85.3" y="75.4" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="6" height="6"></rect>
		<rect x="97.2" y="75.4" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="6" height="6"></rect>
	</g>
	<g opacity=".5">
		<rect x="17.3" y="69.4" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="5.7" height="11.3"></rect>
		<rect x="28.6" y="69.4" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="5.7" height="11.3"></rect>
	</g>
	<g opacity=".5">
		<rect x="17.3" y="86.7" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="5.7" height="11.3"></rect>
		<rect x="28.6" y="86.7" class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="5.7" height="11.3"></rect>
	</g>
	<g>
		<rect x="17.3" y="52.1" class="buildings-2 fill-none stroke-primary" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="5.7" height="11.3"></rect>
		<rect x="28.6" y="52.1" class="buildings-1 fill-none stroke-gray-400" opacity=".5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="5.7" height="11.3"></rect>
	</g>
	<line class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="71.7" y1="63.8" x2="77.9" y2="67.3"></line>
	<polyline class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="9.5,109.2 9.5,42.8 54.7,21.4 54.7,40.1 "></polyline>
	<polyline class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="77.9,109.2 77.9,4.9 110.5,19.9 110.5,109.2 "></polyline>
	<line class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="101.5" y1="23" x2="104.4" y2="24.3"></line>
	<line class="buildings-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="84.3" y1="15" x2="97.2" y2="20.9"></line>
	<polyline class="buildings-3 fill-primary" points="39.2,109.2 39.2,40.1 71.7,40.1 71.7,109.2 "></polyline>
	<g>
		<line class="buildings-0 fill-none stroke-white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="63.9" y1="50.3" x2="46.9" y2="50.3"></line>
		<line class="buildings-0 fill-none stroke-white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="63.9" y1="60" x2="46.9" y2="60"></line>
		<line class="buildings-0 fill-none stroke-white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="63.9" y1="69.7" x2="46.9" y2="69.7"></line>
		<line class="buildings-0 fill-none stroke-white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="63.9" y1="79.5" x2="46.9" y2="79.5"></line>
		<line class="buildings-0 fill-none stroke-white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="63.9" y1="89.2" x2="46.9" y2="89.2"></line>
		<line class="buildings-0 fill-none stroke-white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="63.9" y1="98.9" x2="46.9" y2="98.9"></line>
	</g>
	<line class="buildings-2 fill-none stroke-primary" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="118.5" y1="109.2" x2="1.5" y2="109.2"></line>
</svg>

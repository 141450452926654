export const validateTaxId = (taxId: string, countryCode: string) => {
	if (!taxId) return false;
	switch (countryCode) {
		case "ar":
			return validateTaxIdAr(taxId);
		default:
			return true;
	}
};

const validateTaxIdAr = (taxId: string) => {
	if (taxId.length !== 11) return false;

	let acumulado = 0;
	let digitos = taxId.split("");
	let digito = parseInt(digitos.pop() as string);
	for (let i = 0; i < digitos.length; i++) {
		acumulado += Number(digitos[9 - i]) * (2 + (i % 6));
	}
	let verif = 11 - (acumulado % 11);
	if (verif === 11) {
		verif = 0;
	} else if (verif === 10) {
		verif = 9;
	}
	return digito === verif;
};

interface ValidateNationalIdOptions {
	nationalId: string;
	taxId: string;
	countryCode: string;
}

export const validateNationalId = ({ nationalId, taxId, countryCode }: ValidateNationalIdOptions) => {
	if (!nationalId) return false;
	switch (countryCode) {
		case "ar":
			return validateNationalIdAr(nationalId, taxId);
		default:
			return true;
	}
};

const validateNationalIdAr = (nationalId: string, taxId: string) => {
	const nationalIdByTaxId = taxId.slice(2, -1);
	return nationalIdByTaxId === nationalId;
};

<div *ngIf="userData" class="sidenav-content right" [ngClass]="{'active': active}">
	<div class="sidenav-content-header" [class]="userData.accountType | lowercase">
		<button aria-label="close user menu" class="btn-sidenav-close" (click)="handleClose()">
			<i class="fas fa-times"></i>
		</button>
		<div class="avatar-view">
			<nz-progress [nzPercent]="accountProgressStatusPercent" [nzStrokeColor]="Theme.colors.gradientGreen" [nzShowInfo]="false" nzType="circle" nzStrokeLinecap="square" [nzWidth]="82"></nz-progress>
			<div class="profile-circle">
				<avatar [avatar]="userData.userAvatar" [checked]="userData.status === 'ACTIVE'" [notification]="notifications"></avatar>
			</div>
		</div>
		<div class="avatar-title">{{('hi.user' | translate: {username: name})}}</div>
		<div class="avatar-subtitle">
			<span class="pr-2">#{{userData.id}}</span>
			<span *ngIf="userData.accountType" class="border-left pl-2 font-weight-semi-bold">{{('account.type.' + userData.accountType) | lowercase | translate}}</span>
		</div>
	</div>
	<div class="sidenav-content-main">
		<ul class="sidenav-list">
			<ng-container *ngFor="let item of items">
				<li class="sidenav-list-item" [class.disabled]="item.extra['typeof'] === 'function' ? $any(item.extra['disabled'])(userData) : item.extra['disabled']" [class.border-top]="item.id === 'logout'">
					<ng-container [ngSwitch]="item.type">
						<ng-container *ngSwitchCase="'button'">
							<button [attr.aria-label]="item.name | translate" class="sidenav-list-link" (click)="handleClick(item.id)">
								<div class="sidenav-list-icon">
									<i *inClient nz-icon [nzType]="item.icon.name" nzTheme="outline"></i>
								</div>
								<div class="sidenav-list-content">
									<span class="sidenav-list-title">{{item.name | translate}}</span>
								</div>
							</button>
						</ng-container>
						<ng-container *ngSwitchCase="'link'">
							<a [routerLink]="item.path" class="sidenav-list-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="handleClose()">
								<div class="sidenav-list-icon">
									<i *inClient nz-icon [nzType]="item.icon.name" nzTheme="outline"></i>
								</div>
								<div class="sidenav-list-content">
									<span class="sidenav-list-title">
										<span *ngIf="item.id === 'notifications' && notifications > 0" class="pulse badge-notification badge badge-sm badge-danger rounded-circle"></span>
										{{item.name | translate}}
									</span>
								</div>
							</a>
						</ng-container>
						<ng-container *ngSwitchCase="'dropdown'">
							<button [attr.aria-label]="item.name | translate" class="sidenav-list-link" (click)="collapse.toggle()">
								<div class="sidenav-list-icon">
									<i *inClient nz-icon [nzType]="item.icon.name" nzTheme="outline"></i>
								</div>
								<div class="sidenav-list-content">
									<span class="sidenav-list-title">{{item.name | translate}}</span>
								</div>
								<i class="fas fa-chevron-down" [class.fa-rotate-180]="!item.collapsed"></i>
							</button>
							<div #collapse="ngbCollapse" [(ngbCollapse)]="item.collapsed">
								<ul class="sidenav-list pt-2">
									<li *ngFor="let subItem of item.submenu" class="sidenav-list-item">
										<a
											[routerLink]="subItem.path"
											class="sidenav-list-link py-2"
											routerLinkActive="active"
											(isActiveChange)="$event ? collapse.toggle(true) : null"
											[routerLinkActiveOptions]="{exact: true}"
											(click)="handleClose()"
										>
											<div *ngIf="subItem.icon" class="sidenav-list-icon">
												<i *inClient nz-icon [nzType]="subItem.icon.name" nzTheme="outline"></i>
											</div>
											<div class="sidenav-list-content">
												<div class="sidenav-list-title text-secondary">{{subItem.name | translate}}</div>
											</div>
										</a>
									</li>
								</ul>
							</div>
						</ng-container>
					</ng-container>
				</li>
			</ng-container>
		</ul>
	</div>
</div>

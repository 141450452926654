import { Injectable } from "@angular/core";
import { Observable, scan, tap } from "rxjs";
import { calculateState } from "src/app/functions/http-event";
import { iReqAddBenefits, iResBenefit } from "src/app/interface/api/account-benefit.interface";
import { iReqChangePassword, iSaveChangePassword } from "src/app/interface/api/account-password.interface";
import { iReqSaveProfile, iSaveBusinessData, iSaveContactData, iSaveLaborData, iSaveMaritalStatus, iSavePersonalData, iSaveRepresentative } from "src/app/interface/api/account-profile.interface";
import { iReqValidEmail, iReqValidEmailCode, iReqValidPhone, iReqValidPhoneCode, iValidateUniqueKey } from "src/app/interface/api/account-validate.interface";
import { iAccountDataBank, iBankAccount } from "src/app/interface/data/bank-account.interface";
import { iBenefit } from "src/app/interface/data/benefit.interface";
import { eAccountProgressStatus, eAuthType, iAccount } from "src/app/interface/data/customer.interface";
import { iDocUploadFile, iRequiredDocument } from "src/app/interface/data/documents.interface";
import { iNotification } from "src/app/interface/data/notification.interface";
import { iPreferences } from "src/app/interface/data/preferences.interface";
import { iBusinessData, iLaborData, iRepresentative } from "src/app/interface/data/profile.interface";
import { iReferral } from "src/app/interface/data/referrals.interface";
import { iShare } from "src/app/interface/data/shares.interface";
import { iUploadState } from "src/app/interface/file.interface";
import { eResponse, iResponse } from "src/app/interface/response.interface";
import { CustomerService } from "../customer.service";
import { RouterEndpointsService } from "./router-endpoints.service";
import { XhrService } from "./xhr.service";
import { iCuponstar } from "src/app/interface/data/cuponstar.interface";

@Injectable({
	providedIn: "root",
})
export class ApiAccountService {
	constructor(private Customer: CustomerService, private Endpoints: RouterEndpointsService, private xhr: XhrService) {}
	/** Resolicita los datos del usuario */
	public getUserData(): Observable<iAccount> {
		return this.xhr.get(this.Endpoints.ACCOUNT.GET_USER_DATA).pipe(tap((res: iAccount) => this.Customer.setUserData(res)));
	}
	/** Guarda el perfil del usuario y pasa a ser una cuenta creada */
	public putSaveOnboardingProfile(body: iReqSaveProfile): Observable<iAccount> {
		return this.xhr.put(this.Endpoints.ACCOUNT.SAVE_ON_BOARDING, body);
	}
	/** Obtiene las notificaciones del usuario */
	public getNotifications(): Observable<iNotification[]> {
		return this.xhr.get(this.Endpoints.ACCOUNT.NOTIFICATIONS);
	}
	/** Actualiza la foto de Perfil */
	public updateAvatar(body: FormData) {
		return this.xhr.put(this.Endpoints.ACCOUNT.AVATAR, body).pipe(
			tap((res) => {
				const userData = this.Customer.data;
				if (!userData) return;
				userData.userAvatar = res.url;
				this.Customer.setUserData(userData);
			})
		);
	}
	/** Verifica que el email sea valido y no este siendo utilizado por otro usuario, luego envia un email al usuario con el codigo de verfificacion */
	public putChangeEmail(body: iReqValidEmail): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.CHANGE_EMAIL, body);
	}
	/** Se valida el codigo enviado por el usuario */
	public putChangeEmailConfirmation(body: iReqValidEmailCode): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.VALIDATE_CHANGE_EMAIL, body).pipe(
			tap(() => {
				const userData = this.Customer.data;
				if (!userData) return;
				if (!userData.accountProgressStatus.includes(eAccountProgressStatus.VALID_EMAIL)) {
					userData.accountProgressStatus = [...userData.accountProgressStatus, eAccountProgressStatus.VALID_EMAIL];
				}
				userData.email = body.email;
				this.Customer.setUserData(userData);
			})
		);
	}
	/** Verifica que el telefono sea valido y no este siendo utilizado por otro usuario, luego envia un mensaje por  medio del metodo elegido con el codigo de verificacion*/
	public putChangeAndValidatePhone(body: iReqValidPhone): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.CHANGE_PHONE, body);
	}
	/** Se valida el codigo enviado por el usuario */
	public putValidateAccountPhoneConfirmation(body: iReqValidPhoneCode): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.VALIDATE_CHANGE_PHONE, body).pipe(
			tap(() => {
				const userData = this.Customer.data;
				if (!userData) return;
				if (!userData.accountProgressStatus.includes(eAccountProgressStatus.VALID_PHONE)) {
					userData.accountProgressStatus = [...userData.accountProgressStatus, eAccountProgressStatus.VALID_PHONE];
				}
				userData.contactData.phone = body.phone;
				userData.contactData.phonePrefix = body.phonePrefix;
				this.Customer.setUserData(userData);
			})
		);
	}
	/** Actualizara los datos de contacto del usuario */
	public UpdateContactData(body: Omit<iSaveContactData, "phone" | "phonePrefix">): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.CONTACT_DATA, body).pipe(
			tap({
				next: (res: iResponse) => {
					if (res.code === eResponse.UPDATE_OK) {
						const userData = this.Customer.data;
						delete body.countryId;
						delete body.provinceId;
						userData.contactData = { ...this.Customer.data.contactData, ...body };
						userData.contactData.isOk = false;
						userData.contactData.verifiedBy = null;
						this.Customer.setUserData(userData);
					}
				},
			})
		);
	}
	/** Agrega un representante */
	public AddRepresentative(body: iSaveRepresentative): Observable<iRepresentative> {
		return this.xhr.post(this.Endpoints.ACCOUNT.ADD_REPRESENTATIVE, body).pipe(
			tap({
				next: (res: iRepresentative) => {
					const userData = this.Customer.data;
					(userData.representativeData.representatives = [...this.Customer.data.representativeData.representatives, res]),
						(userData.representativeData.isOk = false),
						(userData.representativeData.verifiedBy = null),
						this.Customer.setUserData(userData);
				},
			})
		);
	}
	/** Actualizara los datos de los representantes del usuario */
	public UpdateRepresentativeData(reprensentativeUid: string, body: iSaveRepresentative): Observable<iRepresentative> {
		return this.xhr.put(this.Endpoints.ACCOUNT.REPRESENTATIVES(reprensentativeUid), body).pipe(
			tap({
				next: (res: iRepresentative) => {
					const userData = this.Customer.data;
					const representatives: iRepresentative[] = [];
					userData.representativeData.representatives.forEach((representative) => {
						if (representative.uid === reprensentativeUid) representative = res;
						representatives.push(representative);
					});
					userData.representativeData.representatives = representatives;
					userData.representativeData.isOk = false;
					userData.representativeData.verifiedBy = null;
					this.Customer.setUserData(userData);
				},
			})
		);
	}
	/** Elimina al Reprensentante */
	public DeleteRepresentative(reprensentativeUid: string): Observable<iResponse> {
		return this.xhr.delete(this.Endpoints.ACCOUNT.REPRESENTATIVES(reprensentativeUid)).pipe(
			tap((res: iResponse) => {
				const userData = this.Customer.data;
				(userData.representativeData.representatives = userData.representativeData.representatives.filter((rep) => rep.uid !== reprensentativeUid)), (userData.representativeData.isOk = false);
				userData.representativeData.verifiedBy = null;
				this.Customer.setUserData(userData);
			})
		);
	}
	/** Actualizara los detos de la Empresa del usuario*/
	public UpdateBusinessData(body: iSaveBusinessData): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.BUSINESS_DATA, body).pipe(
			tap({
				next: (res: iResponse) => {
					if (res.code === eResponse.UPDATE_OK) {
						const userData = this.Customer.data;
						(userData.businessData = { ...(body as iBusinessData) }), (userData.businessData.isOk = false);
						userData.businessData.verifiedBy = null;
						this.Customer.setUserData(userData);
					}
				},
			})
		);
	}
	/** Actualizara los datos laborales del usuario */
	public UpdateLaborData(body: iSaveLaborData): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.LABOR_DATA, body).pipe(
			tap({
				next: (res: iResponse) => {
					if (res.code === eResponse.UPDATE_OK) {
						const userData = this.Customer.data;
						userData.laborData = { ...(body as iLaborData) };
						userData.laborData.isOk = false;
						userData.laborData.verifiedBy = null;
						this.Customer.setUserData(userData);
					}
				},
			})
		);
	}
	/** Actualizara los datos personales del usuario */
	public UpdatePersonalData(body: Omit<iSavePersonalData, "maritalStatus" | "">): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.PERSONAL_DATA, body).pipe(
			tap({
				next: (res: iResponse) => {
					if (res.code === eResponse.UPDATE_OK) {
						const userData = this.Customer.data;
						delete body.nationalityId;
						userData.personalData.firstName = body.firstName;
						userData.personalData.lastName = body.lastName;
						userData.personalData.taxId = body.taxId;
						userData.personalData.id = body.id;
						userData.personalData.birthDay = body.birthDay as number;
						userData.personalData.gender = body.gender;
						userData.personalData.nationality = body.nationality;
						userData.personalData.pep = body.pep;
						userData.personalData.pepDescription = body.pep ? body.pepDescription : null;
					}
				},
			})
		);
	}
	/** Actualiza los datos del estado civil del usuario */
	public UpdateMaritalStatus(body: iSaveMaritalStatus): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.MARITAL_STATUS, body).pipe(
			tap((res: iResponse) => {
				if (res.code === eResponse.UPDATE_OK) {
					const userData = this.Customer.data;
					(userData.personalData.maritalStatus = body.maritalStatus),
						(userData.personalData.hasCouple = body.hasCouple),
						(userData.personalData.coupleId = body.hasCouple ? body.coupleId : null),
						(userData.personalData.coupleName = body.hasCouple ? body.coupleName : null),
						this.Customer.setUserData(userData);
				}
			})
		);
	}
	/** Obtiene las inversiones realizadas por el usuario */
	public getInvestments(): Observable<iShare[]> {
		return this.xhr.get(this.Endpoints.ACCOUNT.INVESTMENTS);
	}
	/** Obtiene los documentos requeridos */
	public getRequiredDocuments(): Observable<iRequiredDocument[]> {
		return this.xhr.get(this.Endpoints.ACCOUNT.REQUEST_DOCUMENTS).pipe(
			tap((requiredDocuments: iRequiredDocument[]) => {
				const userData = this.Customer.data;
				userData.requiredDocuments = requiredDocuments;
				this.Customer.setUserData(userData);
			})
		);
	}
	/** Sube los archivos de la solicitud */
	public uploadDocument(body: FormData) {
		return this.xhr.post(this.Endpoints.ACCOUNT.REQUEST_DOCUMENTS, body, { reportProgress: true, observe: "events" }).pipe(
			scan(calculateState, { state: "PENDING", progress: 0 }),
			tap((state: iUploadState<iDocUploadFile[]>) => {
				if (state.state === "DONE") {
					const doc = state.response;
					const documentUid = body.get("documentUid").toString();
					const userData = this.Customer.data;
					for (const requiredDocument of userData.requiredDocuments) {
						if (requiredDocument.uid !== documentUid) continue;
						requiredDocument.uploadedFiles = [...requiredDocument.uploadedFiles, ...doc];
					}
					this.Customer.setUserData(userData);
				}
			})
		);
	}
	public deleteDocument(requiredDocumentUid: string, documentUid: string) {
		return this.xhr.delete(this.Endpoints.ACCOUNT.DELETE_DOCUMENT(requiredDocumentUid, documentUid)).pipe(
			tap((res: iResponse) => {
				const userData = this.Customer.data;
				for (const requiredDocument of userData.requiredDocuments) {
					if (requiredDocument.uid !== requiredDocumentUid) continue;
					requiredDocument.uploadedFiles = requiredDocument.uploadedFiles.filter((doc) => doc.uid !== documentUid);
				}
				this.Customer.setUserData(userData);
			})
		);
	}
	/** Recuperar la Contraseña */
	public recoveryPassword(email: string): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.RECOVERY_PASSWORD, { email });
	}
	/** Cambiar la Contraseña para un usuario deslogeado */
	resetPassword(body: iReqChangePassword): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.RESET_PASSWORD, body);
	}
	/** Cambia la contraseña al usuario logeado */
	public ChangePassword(body: iSaveChangePassword): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.CHANGE_PASSWORD, body).pipe(
			tap({
				next: (res: iResponse) => {
					if (res.code === eResponse.UPDATE_OK) {
						const userData = this.Customer.data;
						const isSigninForm = userData.signinType.includes(eAuthType.FORM);
						if (!isSigninForm) userData.signinType = [...userData.signinType, eAuthType.FORM];
						this.Customer.setUserData(userData);
					}
				},
			})
		);
	}

	/** Obtiene los datos bancarios del usuario */
	public getDataBank(): Observable<iBankAccount[]> {
		return this.xhr.get(this.Endpoints.ACCOUNT.BANK_ACCOUNT);
	}
	/** Obtiene del BIND los datos bancarios del usuario en base a su alias o CVU y la moneda seleccionada.  */
	public findDataBank(body: { accountId: string; currency: string }): Observable<iAccountDataBank> {
		return this.xhr.post(this.Endpoints.ACCOUNT.FIND_BANK_ACCOUNT, body);
	}
	/** Guarda los datos bancarios del usuario obtenidos por el BIND en la base de datos. */
	public saveDataBank(body: iAccountDataBank): Observable<iBankAccount> {
		return this.xhr.post(this.Endpoints.ACCOUNT.BANK_ACCOUNT, body);
	}
	/** Fija como principal datos */
	public pinUp(bankUid: string): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.BANK_ACCOUNT_UID(bankUid));
	}
	/** Elimina el Dato bancario del usuario */
	public removeDataBank(bankUid: string): Observable<iResponse> {
		return this.xhr.delete(this.Endpoints.ACCOUNT.BANK_ACCOUNT_UID(bankUid));
	}

	/** Obtiene un Objeto de Preferencias */
	getPreference(): Observable<iPreferences> {
		return this.xhr.get(this.Endpoints.ACCOUNT.PREFERENCES);
	}
	/** Modifica las Preferencias */
	putPreference(body: iPreferences): Observable<iResponse> {
		//return this.Mock.Preferences.savePreference(body)
		return this.xhr.put(this.Endpoints.ACCOUNT.PREFERENCES, body);
	}
	/** Agrega el Beneficio al Usuario */
	addBenefits(body: iReqAddBenefits): Observable<iResBenefit> {
		return this.xhr.post(this.Endpoints.ACCOUNT.BENEFITS, body);
	}
	/** Obtiene los Beneficios del Usuario */
	getBenefits(): Observable<{ isEnabledCuponstar: boolean; cuponstar: iCuponstar | null; benefits: iBenefit[] }> {
		return this.xhr.get(this.Endpoints.ACCOUNT.BENEFITS);
	}
	/** Genera el codigo de Cuponstar */
	generateCode(): Observable<iCuponstar> {
		return this.xhr.get(this.Endpoints.ACCOUNT.GENERATE_CUPONSTAR);
	}
	/** Obtiene los referidos del cliente */
	getReferrals(): Observable<iReferral[]> {
		return this.xhr.get(this.Endpoints.ACCOUNT.REFERRALS);
	}
	/** Valida el ID Fiscal y Nacional del usuario */
	validate(type: "taxId" | "id" | "phone", body: iValidateUniqueKey): Observable<iResponse> {
		return this.xhr.post(this.Endpoints.ACCOUNT.VALIDATE(type), body);
	}

	public getIdentity(customerUid: string): Observable<iResponse> {
		return this.xhr.get(this.Endpoints.ACCOUNT.GET_IDENTITY(customerUid));
	}

	public setAttemptIdentity(customerUid: string): Observable<iResponse> {
		return this.xhr.put(this.Endpoints.ACCOUNT.SET_ATTEMPT_IDENTITY(customerUid));
	}
}

import { Component, OnInit } from "@angular/core";
import { Config } from "src/app/config";

@Component({
	selector: "social-media",
	templateUrl: "./social-media.component.html",
	styleUrls: ["./social-media.component.scss"],
})
export class SocialMediaComponent implements OnInit {
	public socialMedia = Config.socialMedia;

	constructor() {}

	ngOnInit(): void {}
}

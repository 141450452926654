import { Component, OnInit } from "@angular/core";

@Component({
	selector: "icon-search-page",
	templateUrl: "./search-page.icon.html",
	styleUrls: ["./search-page.icon.scss"],
})
export class SearchPageIcon implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

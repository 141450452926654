export interface iNotification {
	/** ID Autoincremental */
	id: number;
	/** Uid de la notificación */
	uid: string;
	/** Tipo de notificación */
	type: eNotificationType;
	/** Subtipo de la notificación */
	subType: eNotificationSubType;
	/** Titulo de la notificación */
	title: string;
	/** Descripción de la notificación */
	description: string;
	/** Imagen de la notificación */
	img: string;
	/** Sitio asociado */
	siteUid: string;
	/** Determina si el usuario leyo la notificación */
	isRead: boolean;
	/** url de la notificación */
	path: string;
	/** Determina si la url es interna o externa */
	isInternal: boolean;
	/** Fecha de publicación */
	postAt: number;
	/** Creado por */
	createdBy: string;
	/** Fecha de creación */
	createdAt: number;
	/** Fecha de Actualización */
	updatedAt: number;
}

export enum eNotificationType {
	/** Mensaje acerca del Perfil */
	PROFILE = "profile",
	/** Mensaje acerca de Inversiones */
	INVESTMENTS = "investments",
	/** Mensaje acerca de Beneficios */
	BENEFITS = "benefits",
	/** Mensaje acerca de Referidos */
	REFERRALS = "referrals",
}

export enum eNotificationSubType {
	ACCOUNT_REGISTERED = "ACCOUNT_REGISTERED",
	PASSWORD_CHANGED = "PASSWORD_CHANGED",
	ACCOUNT_CREATED = "ACCOUNT_CREATED",
	IDENTITY_VALIDATED = "IDENTITY_VALIDATED",
	PERSONAL_DATA_VALIDATED = "PERSONAL_DATA_VALIDATED",
	CONTACT_DATA_VALIDATED = "CONTACT_DATA_VALIDATED",
	BUSINESS_CONTACT_DATA_VALIDATED = "BUSINESS_CONTACT_DATA_VALIDATED",
	LABOR_DATA_VALIDATED = "LABOR_DATA_VALIDATED",
	BUSINESS_DATA_VALIDATED = "BUSINESS_DATA_VALIDATED",
	REPRESENTATIVE_DATA_VALIDATED = "REPRESENTATIVE_DATA_VALIDATED",
	DOCUMENT_CONFIRMED = "DOCUMENT_CONFIRMED",
	DOCUMENT_REJECTED = "DOCUMENT_REJECTED",
	NEW_REQUEST_DOCUMENT = "NEW_REQUEST_DOCUMENT",
	EMAIL_VALIDATED = "EMAIL_VALIDATED",
	PHONE_VALIDATED = "PHONE_VALIDATED",
	ACCOUNT_ACTIVATED = "ACCOUNT_ACTIVATED",
	ACCOUNT_PAUSED = "ACCOUNT_PAUSED",
	ACCOUNT_REACTIVATED = "ACCOUNT_REACTIVATED",
	BANK_ACCOUNT_ADDED = "BANK_ACCOUNT_ADDED",
	CUSTOMER_CAPITAL_CHANGED = "CUSTOMER_CAPITAL_CHANGED",
	NEW_INVESTMENT_CREATED = "NEW_INVESTMENT_CREATED",
	INVESTMENT_CANCELLED = "INVESTMENT_CANCELLED",
	INVESTMENT_CONFIRMED = "INVESTMENT_CONFIRMED",
	INVESTMENT_MOVEMENT_CONFIRMED = "INVESTMENT_MOVEMENT_CONFIRMED",
	INVESTMENT_EXCHANGE_MOVEMENT_CONFIRMED = "INVESTMENT_EXCHANGE_MOVEMENT_CONFIRMED",
	INVESTMENT_MOVEMENT_DELETED = "INVESTMENT_MOVEMENT_DELETED",
	SHARE_MOVEMENT_CONFIRMED = "SHARE_MOVEMENT_CONFIRMED",
	INCOME_MOVEMENT_CONFIRMED = "INCOME_MOVEMENT_CONFIRMED",
	RELOCATION_MOVEMENT_CONFIRMED = "RELOCATION_MOVEMENT_CONFIRMED",
	RESCISSION_MOVEMENT_CONFIRMED = "RESCISSION_MOVEMENT_CONFIRMED",
	NEW_INVESTMENT_DOCUMENT = "NEW_INVESTMENT_DOCUMENT",
	NEW_BENEFIT_ADDED = "NEW_BENEFIT_ADDED",
	REFERRAL_PAYED = "REFERRAL_PAYED",
}

export type NotificationKeys = { [K in eNotificationSubType]: string };

import { iAmount } from "../common.interface";
import { IdentityValidation } from "./identity-validation.interface";
import { iParameters } from "./parameters.interface";
import { iProxy } from "./proxy.interface";

/** Datos del Sitio */
export interface iInitWebSite {
	/** UID del Sitio */
	uid: string;
	/** Obtiene el nombre de la Empresa */
	name: string;
	/** Dominio del Sitio */
	domain: string;
	/** Codigo de Pais del Sitio */
	countryCode: string;
	/** Obtiene el estado del Sitio */
	status: eSiteStatus;
	/** Obtiene el anuncio banner principal */
	banner: iSiteBanner;
	/** Información de cuenta */
	account: iSiteAccount;
	/** Información de inversiones */
	investments: iSiteInvestments;
	/** Programa de Referidos */
	referral: iSiteReferral;
	/** Notificaciones */
	notifications: iSiteNotifications;
	/** Lenguajes habilitados para el sitio */
	languages: string[];
	/** Obtiene Parametros estadisticos del Sitio */
	stats: iSiteStats;
	/** Operaciónes de Cambio */
	currencies: iCurrency[];
	/** Proxy del Sitio */
	proxy: iProxy;
	/** Los paramentros del Sitio  */
	parameters: iParameters;
	/** Cuponstar configuration */
	cuponstar: boolean;
}
/** Estado actual del Sitio */
export enum eSiteStatus {
	/** Sitio activo */
	ACTIVE = "active",
	/** Sitio en Mantenimiento */
	MAINTENANCE = "maintenance",
	/** Sitio bloqueado */
	BLOCKED = "blocked",
	/** Sitio offline */
	OFFLINE = "offline",
}
/** Banners del Sitio */
export interface iSiteBanner {
	[lang: string]: iBanner;
}
export interface iBanner {
	/** Etiqueta del banner */
	tag: string;
	/** descripcion corta del banner */
	alertDescription: string;
	/** Fondo de la etiqueta */
	background: string;
	/** Determina si el anuncio esta activo */
	isActive: boolean;
	/** Determina si la url es interna o no */
	isInternal: boolean;
	/** Url del anuncio */
	url: string;
}
/** Información de las cuentas del sitio */
export interface iSiteAccount {
	/** Obtiene las monedas disponibles en el sitio */
	selectedCurrencies: string[];
	/** Obtiene los datos de validación de identidad */
	identityValidation: IdentityValidation;
	/** Determina si esta habilitado para crear personas fisicas */
	isPhysicalEnabled: boolean;
	/** Determina si esta habilitado para crear personas juridicas */
	isLegalEnabled: boolean;
	/** Determina si se necesita validar los datos personales */
	isPersonalDataValidation: boolean;
	/** Determina si se necesita validar los datos de laborales */
	isLaborDataValidation: boolean;
	/** Determina si se necesita validar los datos de contacto de persona fisica */
	isContactPhysicalValidation: boolean;
	/** Determina si se necesita validar los datos de la empresa */
	isBusinessDataValidation: boolean;
	/** Determina si se necesita validar los datos de los representantes */
	isRepresentativeDataValidation: boolean;
	/** Determina si se necesita validar los datos de contacto de persona juridica */
	isContactLegalValidation: boolean;
}
/** Información de inversiones del Sitio */
export interface iSiteInvestments {
	/** Monto minimo de Inversión */
	minInvestment: iAmount;
	/** Vencimiento de las inversiones expresada en dias */
	expiration: number;
}
export interface iSiteReferral {
	/** Monto del programa */
	amount: number;
	/** Moneda del programa */
	currency: string;
	/** Determina si esta habilitado el programa de referidos */
	enabled: boolean;
}

export interface iSiteNotifications {
	/** Determina si la opción de notificaciones esta activa */
	isEnabled: boolean;
	/** Determina si la opción de notificación por email esta activa */
	isEmailEnabled: boolean;
	/** Determina si la opción de notificación por whatapp esta activa */
	isWhatsappEnabled: boolean;
}

/** Datos estadisticos del Sitio */
export interface iSiteStats {
	/** Obtiene el monto invertido hasta la fecha */
	totalAmountInvested: number;
	/** Obtiene la cantidad de proyectos fondeados hasta la fecha */
	totalFundedAssets: number;
	/** Obtiene el total de inversiones realizadas */
	totalInvestments: number;
	/** Obtiene el total de inversores */
	totalInvestors: number;
}
/** Cambio de Moneda */
export interface iCurrency {
	/** Uid de la moneda */
	uid: string;
	/** Titulo descriptivo */
	title: string;
	/** `AlphaCode2` de la moneda  */
	name: string;
	/** Tipo de Cambio para la moneda */
	exchange: { [currency: string]: number };
	/** Cantidad de decimales de la moneda */
	decimal: number;
}

import { Injectable } from "@angular/core";
import { Config } from "../config";
import { iLang } from "../interface/config.interface";
import { SessionService } from "./session.service";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "./storage.service";
import { PlatformService } from "./platform.service";

@Injectable({
	providedIn: "root",
})
export class LanguageService {
	/** Arreglo de lenguages */
	public langs = Config.langs;
	/** Lenguage seleccionado */
	public lang: iLang = this.langs.find((lang) => lang.isDefault) as iLang;

	constructor(private Platform: PlatformService, private Session: SessionService, private Translate: TranslateService, private Storage: StorageService) {}

	/** Configura el lenguage */
	public setLanguage() {
		const { site } = this.Session;

		if (site && site.languages) {
			const { languages } = site;
			const [defaultLang] = languages;
			this.langs = this.langs.filter((lang) => {
				lang.isDefault = lang.id === defaultLang;
				if (languages.includes(lang.id)) return true;
				return false;
			});
		}

		if (this.Platform.isBrowser) return this.setLanguageByBrowser();
		if (this.Platform.isServer) this.setDefaultLang();
	}
	/** Obtiene el lenguage del navegador y lo configura si esta disponible */
	public setLanguageByBrowser() {
		let locale = this.Storage.getLang();
		if (locale) return this.setLang(locale);

		if (navigator.languages) {
			let lang = this.langs.find((lang) => navigator.languages.includes(lang.id));
			if (lang) return this.setLang(lang.locale);
		}

		this.setDefaultLang();
	}
	/** Setea el idioma por defecto */
	public setDefaultLang() {
		this.lang = this.langs.find((lang) => lang.isDefault) as iLang;
		this.setLang(this.lang.locale);
	}
	/** Cambia el idioma al seleccionado */
	public setLang(locale: string) {
		this.lang = this.langs.find((lang) => lang.locale === locale) as iLang;
		if (!this.lang) {
			console.warn("Language not found");
			return this.setDefaultLang();
		}
		this.Translate.addLangs([this.lang.locale]);
		this.Translate.setDefaultLang(this.lang.locale);
		if (this.Platform.isBrowser) this.Storage.setLang(this.lang.locale);
	}

	/** Emite un callback cuando hay un cambio de idioma */
	public changeLang(locale: string) {
		this.Translate.use(locale).subscribe((res) => {
			this.setLang(locale);
		});
	}
}

import { Component, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { iLinkProps } from "src/app/interface/components/navbar.interface";
import { iAccount } from "src/app/interface/data/customer.interface";
import { CustomerService } from "src/app/services/customer.service";

@Component({
	selector: "nav-button",
	templateUrl: "./button.navbar.html",
	styleUrls: ["./button.navbar.scss"],
})
export class ButtonNavbar implements OnDestroy {
	/** Datos de links de tipo Button */
	@Input() data: iLinkProps;
	/** Si el navbar esta en modo `sticky`*/
	@Input() isSticky: boolean = false;
	/** Si el pagina esta en modo `internal` */
	@Input() internalPage: boolean = false;
	/** Determina si el menu del usuario es visible */
	public showUserMenu: boolean = false;
	/** Datos del usuario */
	public userData: iAccount;
	/** Obtiene el nombre completo */
	public fullName: string;
	/** Obtiene las notificaciones del usuario */
	get notifications() {
		return this.Customer.notifications;
	}
	/** Subscription */
	private sCustomer: Subscription;

	constructor(private Customer: CustomerService) {
		this.sCustomer = this.Customer.getCustomer().subscribe((customer) => {
			this.userData = customer;
			if (!this.userData) return;

			if (this.userData.isLegalEntity) this.fullName = this.userData.businessData.businessName;
			if (!this.userData.isLegalEntity) this.fullName = `${this.userData.personalData.firstName} ${this.userData.personalData.lastName}`;
		});
	}

	ngOnDestroy(): void {
		if (this.sCustomer) this.sCustomer.unsubscribe();
	}

	/** Evento de activacion de submenu del usuario */
	public toogleUserMenu() {
		this.showUserMenu = !this.showUserMenu;
	}
	/** Callback para desactivar el submenu del usuario */
	public hideUserMenu(hide: boolean) {
		this.showUserMenu = false;
	}
}

import { HttpClient } from "@angular/common/http";
import { makeStateKey, StateKey, TransferState } from "@angular/platform-browser";
import { TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Observable } from "rxjs";

export class TranslateBrowserLoader implements TranslateLoader {
	constructor(private Http: HttpClient, private TransferState: TransferState, private prefix: string = "i18n", private suffix: string = ".json") {}

	public getTranslation(lang: string): Observable<any> {
		const key: StateKey<number> = makeStateKey<number>("transfer-translate-" + lang);
		const data = this.TransferState.get(key, null);

		if (data) {
			return new Observable((observer) => {
				observer.next(data);
				observer.complete();
			});
		}

		return new TranslateHttpLoader(this.Http, this.prefix, this.suffix).getTranslation(lang);
	}
}

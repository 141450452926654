import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

import { INTERNAL_PAGE_DEFAULT } from "../constants";
import { iInternalPage } from "../interface/common.interface";
import { iBreadcrum } from "../interface/components/breadcrums.interface";
import { eSiteStatus, iInitWebSite } from "../interface/data/site.interface";
import { CookiesService } from "./cookies.service";
import { PlatformService } from "./platform.service";

@Injectable({
	providedIn: "root",
})
export class SessionService {
	/** Datos del Sitio */
	public site!: iInitWebSite;
	/** Estado del Sitio */
	public status!: eSiteStatus;
	/** Determina si se esta accediendo a un 404 */
	private willEnterNotFoundPage: boolean = false;
	/** Determina un Valor inicial de redireccion luego del login del usuario */
	private redirectToUrlAfterLogin: string = "";
	/** Determina si el sitio esta buscando un resultado por medio de un resolver */
	private $isSearching = new BehaviorSubject<boolean>(false);
	/** Determina el estilo de pagina. */
	private internalPage = new BehaviorSubject<iInternalPage>(INTERNAL_PAGE_DEFAULT);
	/** Observa si el breadcrum a la espera de cambios */
	public breadcrum = new BehaviorSubject<iBreadcrum[] | null>(null);
	/** Recuerda el email en la pagina de login para poder ser utilizada en el restablecimiento de contraseña */
	public rememberEmail: string = "";
	/** Contiene el token de reCaptcha */
	public reCaptchaToken: string = "";

	constructor(private Cookie: CookiesService, private Platform: PlatformService) {}

	/** Obtiene los UTMS de la cookie */
	getUTMData() {
		let utms = {
			utmCampaign: this.Cookie.get("utm_campaign") || null,
			utmMedium: this.Cookie.get("utm_medium") || null,
			utmSource: this.Cookie.get("utm_source") || null,
		};
		Object.keys(utms).forEach((k) => {
			if (utms[k] === null) delete utms[k];
		});
		return utms;
	}
	/** Obtiene el hostname del sitio */
	getHost() {
		let host = environment.host;
		return host;
	}
	isSearching = this.$isSearching.asObservable();
	/** Establece el valor de isSerching */
	setIsSearching = (val: boolean) => this.$isSearching.next(val);
	/** Obtiene el estado de la pestaña */
	getInternalPage = () => this.internalPage.asObservable();
	/** Determina el estado de la pestaña  */
	setInternalPage = (data: iInternalPage) => this.internalPage.next(data);
	/** Determina el Sitio en base a /site */
	setSiteInfo(response: Partial<iInitWebSite> = {}) {
		this.site = response as iInitWebSite;
		this.status = response.status || eSiteStatus.OFFLINE;
	}
	/** Obtiene si el Sitio esta Activo */
	isSiteActive = () => this.status === eSiteStatus.ACTIVE;
	/** Obtiene si el Sitio esta offline */
	isSiteOffline = () => this.status === eSiteStatus.OFFLINE;
	/** Obtiene si el Sitio esta en Mantenimiento */
	isSiteMaintenance = () => this.status === eSiteStatus.MAINTENANCE;
	/** Obtiene si el Sitio esta Bloqueado */
	isSiteBlocked = () => this.status === eSiteStatus.BLOCKED;
	/** Determina si el usuario esta navegando en una url valida */
	setWillEnterNotFoundPage(val: boolean) {
		this.willEnterNotFoundPage = val;
	}
	/** Pregunta si el usuario esta navegando sobre una url valida */
	isWillEnterNotFoundPage() {
		return this.willEnterNotFoundPage;
	}
	/** Determina la URL donde el usuario quizo navegar sin estar logeado */
	setRedirectToUrlAfterLogin(url: string) {
		this.redirectToUrlAfterLogin = url;
	}
	/** Obtiene la ultima URL donde el usuario quizo navegar sin estar logeado */
	getRedirectToUrlAfterLogin() {
		return this.redirectToUrlAfterLogin;
	}
	/** Configura el breadcrum dependiendo la pantalla fijada en el Sitio */
	setBreadcrum(breadcrum: iBreadcrum[]) {
		this.breadcrum.next(breadcrum);
	}
	/** Exchange Rate */
	exchangeRate(amount: number, currencyFrom: string, currencyTo: string) {
		const { currencies } = this.site;
		if (currencyFrom === currencyTo) return amount;
		const from = currencies.find((currency) => currency.uid === currencyFrom);
		const to = currencies.find((currency) => currency.uid === currencyTo);
		if (from && to) {
			const rate = from.exchange[currencyTo];
			if (rate) return Number((amount * rate).toFixed(to.decimal || 2));
		}
		return amount;
	}
	/** Get Decimal Currency */
	getDecimalCurrency(currency: string) {
		const { currencies } = this.site;
		const currencyData = currencies.find((c) => c.uid === currency);
		if (currencyData) return currencyData.decimal;
		return 2;
	}
}

<svg class="benefits-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
	<path
		class="benefits-icon-1"
		d="M62.48,84.44h0A7.72,7.72,0,0,1,48.9,81.22h0a7.77,7.77,0,0,0-9.06-6h0a7.74,7.74,0,0,1-8.38-11.19h0a7.7,7.7,0,0,0-3.15-10.4h0a7.68,7.68,0,0,1,.77-13.89h0a7.65,7.65,0,0,0,4.25-10h0a7.67,7.67,0,0,1,9.58-10.1h0a7.71,7.71,0,0,0,9.69-4.88h0a7.67,7.67,0,0,1,13.8-1.64h0A7.72,7.72,0,0,0,77,15.65h0a7.73,7.73,0,0,1,11.67,7.58h0a7.65,7.65,0,0,0,6.47,8.7h0a7.68,7.68,0,0,1,4,13.32h0a7.68,7.68,0,0,0-.62,10.85h0A7.75,7.75,0,0,1,93,68.94h0a7.7,7.7,0,0,0-7.41,7.93h0A7.74,7.74,0,0,1,73.1,83.18h0A7.48,7.48,0,0,0,62.48,84.44Z"
	/>
	<path
		class="benefits-icon-2"
		d="M53.08,79.4h0A7.71,7.71,0,0,1,39.85,75h0a7.79,7.79,0,0,0-8.51-6.75h0A7.74,7.74,0,0,1,24,56.34h0A7.7,7.7,0,0,0,21.78,45.7h0a7.68,7.68,0,0,1,2-13.76h0a7.66,7.66,0,0,0,5.12-9.56h0a7.67,7.67,0,0,1,10.43-9.21h0a7.71,7.71,0,0,0,10.09-4h0a7.67,7.67,0,0,1,13.9-.41h0a7.69,7.69,0,0,0,10.3,3.4h0a7.73,7.73,0,0,1,11,8.58h0A7.65,7.65,0,0,0,90.25,30h0a7.68,7.68,0,0,1,2.8,13.62h0a7.68,7.68,0,0,0-1.59,10.75h0a7.75,7.75,0,0,1-6.64,12.3h0a7.71,7.71,0,0,0-8.09,7.24h0a7.73,7.73,0,0,1-13,5.18h0A7.49,7.49,0,0,0,53.08,79.4Z"
	/>
	<polyline class="benefits-icon-3" points="79.5 90.95 91.2 112.86 100.9 98.36 118.3 98.45 102.9 69.45" />
	<polyline class="benefits-icon-3" points="40.5 90.95 28.8 112.86 19.1 98.36 1.7 98.45 17.1 69.45" />
	<path
		class="benefits-icon-4"
		d="M58.37,60.14H58A10.24,10.24,0,0,1,47.63,49.92l0-2.48,5.53,0v2.47A4.72,4.72,0,0,0,58,54.61h.38a4.76,4.76,0,1,0-.06-9.52h-.39a10.29,10.29,0,0,1-.13-20.57h.38A10.25,10.25,0,0,1,68.48,34.73l0,2.48-5.52,0,0-2.47A4.72,4.72,0,0,0,58.16,30h-.38a4.77,4.77,0,0,0,.07,9.53h.38a10.29,10.29,0,0,1,.14,20.58Z"
	/>
	<rect class="benefits-icon-4" x="55.88" y="22.15" width="4.35" height="40.36" transform="matrix(1, -0.01, 0.01, 1, -0.28, 0.39)" />
</svg>

import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, finalize, Observable, of, zip } from "rxjs";
import { Config } from "../config";
import { SITE_ERROR_STATE, SITE_STATE_KEY } from "../constants";
import { eSiteStatus, iInitWebSite } from "../interface/data/site.interface";
import { ApiAccountService } from "./api/api-account.service";
import { ApiCountriesService } from "./api/api-countries.service";
import { CustomerService } from "./customer.service";
import { DbRealtimeService } from "./db-realtime.service";
import { LanguageService } from "./language.service";
import { PlatformService } from "./platform.service";
import { SessionService } from "./session.service";
import { environment } from "src/environments/environment";
import { ApiSitesService } from "./api/api-sites.service";

@Injectable({
	providedIn: "root",
})
export class CrmCoreService {
	/** Determina el estado de la peticion */
	public isLoading = new BehaviorSubject<boolean>(false);
	/** Almacena todos las peticiones iniciales del Sitio */
	private allInitLoader: Observable<any>[] = [];

	constructor(
		private Platform: PlatformService,
		private APIAccount: ApiAccountService,
		private APISites: ApiSitesService,
		private Language: LanguageService,
		private Customer: CustomerService,
		private DBRealtime: DbRealtimeService,
		private Session: SessionService,
		private Countries: ApiCountriesService
	) {}

	checkVersion() {
		if (this.Platform.isServer) return this.Platform.setState<string>("version", this.Platform.version);
		let version = this.Platform.getState<string>("version", null);
		if (!version) version = environment.version;
		let localVersion = localStorage.getItem("version");

		if (version !== localVersion) {
			localStorage.setItem("version", version);

			if ("caches" in window) {
				caches.keys().then((names) => {
					// Delete all the caches
					names.forEach((name) => {
						caches.delete(name);
					});
				});
			}

			if ("serviceWorker" in navigator) {
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					for (let registration of registrations) {
						registration.unregister();
					}
				});
			}

			window.location.reload();
		}
	}

	public load() {
		this.checkVersion();
		this.isLoading.next(true);
		this.Customer.loadUser();
		this.DBRealtime.initSession();
		this.Language.setLanguageByBrowser();

		this.CountriesInfo();
		this.StatesInfo(Config.countryCode);
		this.getCurrencies();
		if (this.Customer.isAuth) this.getUserData();

		this.SiteInfo().subscribe(() => {
			this.Language.setLanguage();
			zip(this.allInitLoader)
				.pipe(finalize(() => this.isLoading.next(false)))
				.subscribe((res) => {});
		});
	}
	/** Inicializa el sitio del lado del servidor */
	public loadServer() {
		return new Promise((resolve, reject) => {
			this.checkVersion();
			this.Language.setDefaultLang();

			this.SiteInfo().subscribe(() => {
				this.Language.setLanguage();
				resolve(true);
			});
		});
	}
	/** Inicializa el sitio del lado del cliente */
	public loadClient() {
		this.checkVersion();
		this.isLoading.next(true);

		const site = this.Platform.getState<iInitWebSite>(SITE_STATE_KEY, null);
		if (site) this.Session.setSiteInfo(site);
		const siteError = this.Platform.getState(SITE_ERROR_STATE, null);
		if (siteError) console.error("ERROR: Set Site", siteError);

		if (!site && !siteError) return this.load();

		this.Language.setLanguage();
		this.DBRealtime.initSession();
		this.Customer.loadUser();

		this.CountriesInfo();
		this.StatesInfo(Config.countryCode);
		this.getCurrencies();
		if (this.Customer.isAuth) this.getUserData();

		zip(this.allInitLoader)
			.pipe(finalize(() => this.isLoading.next(false)))
			.subscribe();
	}
	/** Inicia la Solicitud del Sitio */
	private SiteInfo() {
		return this.APISites.getInfo().pipe(
			catchError((err) => {
				console.error("ERROR: Set Site", err);

				return of({ status: eSiteStatus.OFFLINE });
			})
		);
	}
	/** Inicia la Solicitud de Monedas */
	private getCurrencies() {
		const currencies = this.APISites.getCurrencies().pipe(
			catchError((err) => {
				console.error("ERROR: Set Currencies", err);
				return of([]);
			})
		);
		this.allInitLoader.push(currencies);
	}
	/** Inicial la solicitud del usuario */
	private getUserData() {
		const account = this.APIAccount.getUserData().pipe(
			catchError((err) => {
				console.error("ERROR: Get User Data", err);
				this.Customer.logout();
				return of(err);
			})
		);
		this.allInitLoader.push(account);
	}
	/** Inicia la Solicitud Paises */
	private CountriesInfo() {
		const countries = this.Countries.getCountries().pipe(
			catchError((err) => {
				console.error("ERROR: Set Countries", err);
				return of([]);
			})
		);
		this.allInitLoader.push(countries);
	}
	/** Inicia la Solicitud de Provincias/Estados */
	private StatesInfo(countryCode: string) {
		const states = this.Countries.getStates(countryCode, true).pipe(
			catchError((err) => {
				console.error("ERROR: Set States", err);
				return of([]);
			})
		);
		this.allInitLoader.push(states);
	}
}

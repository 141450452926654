import { makeStateKey } from "@angular/platform-browser";
import { SupportManAsset } from "../components/assets/support-man/support-man.asset";
import { BenefitsIcon } from "../components/icons/benefits/benefits.icon";
import { BuildingIcon } from "../components/icons/building/building.icon";
import { separatorDec, separatorMil } from "../functions/separator";
import { eMaritalStatus, iInternalPage } from "../interface/common.interface";
import { InputmaskOptions } from "../interface/components/input-mask.interface";
import { iLinkProps } from "../interface/components/navbar.interface";
import { iArticle } from "../interface/data/blog.interface";
import { iCountry, iStates } from "../interface/data/countries.interface";
import { NotificationKeys } from "../interface/data/notification.interface";
import { iInitWebSite } from "../interface/data/site.interface";
import { RouterPathsService } from "../services/router-paths.service";
import { eUserType, iAccount } from "../interface/data/customer.interface";

export const DEFAULT_AVATAR = "assets/img/user-default.webp";

export const LOGO = "assets/svg/logo-blue.svg";

export const SITE_STATE_KEY = makeStateKey<Partial<iInitWebSite>>("site");

export const SITE_ERROR_STATE = makeStateKey<any>("site-error");

export const COUNTRIES_STATE_KEY = makeStateKey<iCountry[]>("countries");

export const COUNTRIES_ERROR_STATE = makeStateKey<any>("countries-error");

export const STATES_STATE_KEY = makeStateKey<iStates[]>("states");

export const STATES_ERROR_STATE = makeStateKey<any>("states-error");

export const ARTICLE_STATE_KEY = makeStateKey<iArticle>("article");

export const FLAG = (flag: string, squared: boolean = true) => (squared ? `assets/flags/4x3/${flag.toLowerCase()}.svg` : `assets/flags/1x1/${flag.toLowerCase()}.svg`);

const Paths = new RouterPathsService();

export const SUBMENU_ACCOUNT: iLinkProps[] = [
	{ type: "link", path: Paths.GO.ACCOUNT_PROFILE, name: "account.header.breadcrumb.profile", id: "profile" },
	{ type: "link", path: Paths.GO.ACCOUNT_INVESTMENTS, name: "account.header.breadcrumb.investments", id: "investments" },
	{ type: "link", path: Paths.GO.ACCOUNT_REFERRED, name: "account.header.breadcrumb.referred", id: "referred" },
	{ type: "link", path: Paths.GO.ACCOUNT_BENEFITS, name: "account.header.breadcrumb.benefits", id: "benefits" },
];

export const NAVBAR: iLinkProps[] = [
	{ type: "link", path: Paths.GO.PROJECTS, name: "menu.investments" },
	{ type: "link", path: Paths.GO.HOW_IT_WORKS, name: "menu.howItWorks" },
	{
		type: "dropdown",
		name: "menu.company",
		submenu: [
			{ type: "link", path: Paths.GO.ABOUT, name: "menu.about", subname: "menu.about.description", icon: { type: "none", component: BuildingIcon, position: "left" } },
			{ type: "link", path: Paths.GO.BENEFITS, name: "menu.benefits", subname: "menu.benefits.description", icon: { type: "none", component: BenefitsIcon, position: "left" } },
			/* {
				type: "link",
				path: Paths.GO.ACADEMY,
				name: "menu.academy",
				subname: "menu.academy.description",
				icon: { type: "none", component: SupportManAsset, position: "right" },
				extra: { id: "ACADEMY", className: "academy", button: "menu.academy.button" },
			}, */
			{ type: "link", path: Paths.GO.CONTACT, name: "menu.contact", subname: "menu.contact.description", extra: { id: "FOOTER", className: "footer-left" } },
			{ type: "link", path: Paths.GO.FAQ, name: "menu.faq.abbreviation", subname: "menu.faq.description", extra: { id: "FOOTER", className: "footer-right" } },
		],
	},
	{ type: "link", path: Paths.GO.BLOG, name: "menu.blog" },
	{ type: "link", path: Paths.GO.AUTH, name: "menu.login", extra: { id: "LOGIN", userOnline: false } },
	{ type: "button", extra: { id: "NOTIFICATIONS" } },
	{ type: "button", extra: { id: "USERONLINE", userOnline: true } },
];

export const SIDENAV_LEFT = [
	{ path: Paths.GO.PROJECTS, type: "link", title: "menu.investments", icon: "fund-view" },
	{ path: Paths.GO.HOW_IT_WORKS, type: "link", title: "menu.howItWorks", icon: "appstore-add" },
	{ path: Paths.GO.BLOG, type: "link", title: "menu.blog", icon: "read" },
	{ path: Paths.GO.ABOUT, type: "link", title: "menu.about", icon: "shop" },
	{ path: Paths.GO.BENEFITS, type: "link", title: "menu.benefits", icon: "tags" },
	{ path: Paths.GO.CONTACT, type: "link", title: "menu.contact", icon: "contacts" },
	{ path: Paths.GO.FAQ, type: "link", title: "menu.faq", icon: "question-circle" },
];

export const SIDENAV_RIGHT: iLinkProps[] = [
	{ id: "account", path: Paths.GO.ACCOUNT, type: "link", name: "account.header.breadcrumb.account", extra: { typeof: "boolean", disabled: false }, icon: { type: "ng-zorro", name: "dashboard" } },
	{
		id: "profile",
		type: "dropdown",
		name: "account.header.breadcrumb.profile",
		icon: { type: "ng-zorro", name: "user" },
		extra: {
			typeof: "function",
			disabled: (user: iAccount) => (user && user.userType === eUserType.REGISTERED_USER ? true : false),
		},
		submenu: [
			{ id: "profile", name: "account.header.breadcrumb.profile.edit", path: Paths.GO.ACCOUNT_PROFILE, type: "link" },
			{ id: "documents", name: "account.header.breadcrumb.profile.documents", path: Paths.GO.ACCOUNT_PROFILE_DOCUMENTS, type: "link" },
			{ id: "bank-account", name: "account.header.breadcrumb.profile.dataBank", path: Paths.GO.ACCOUNT_PROFILE_DATABANK, type: "link" },
			{ id: "security", name: "account.header.breadcrumb.profile.security", path: Paths.GO.ACCOUNT_PROFILE_SECURITY, type: "link" },
			{ id: "preferences", name: "account.header.breadcrumb.profile.preferences", path: Paths.GO.ACCOUNT_PROFILE_PREFERENCES, type: "link" },
		],
		collapsed: true,
	},
	{
		id: "investment",
		path: Paths.GO.ACCOUNT_INVESTMENTS,
		type: "link",
		name: "account.header.breadcrumb.investments",
		extra: {
			typeof: "function",
			disabled: (user: iAccount) => (user && user.userType === eUserType.REGISTERED_USER ? true : false),
		},
		icon: { type: "ng-zorro", name: "project" },
	},
	{
		id: "notifications",
		path: Paths.GO.ACCOUNT_NOTIFICATIONS,
		type: "link",
		name: "account.header.breadcrumb.notifications",
		extra: {
			typeof: "function",
			disabled: (user: iAccount) => (user && user.userType === eUserType.REGISTERED_USER ? true : false),
		},
		icon: { type: "ng-zorro", name: "notification" },
	},
	{
		id: "referred",
		path: Paths.GO.ACCOUNT_REFERRED,
		type: "link",
		name: "account.header.breadcrumb.referred",
		extra: {
			typeof: "function",
			disabled: (user: iAccount) => (user && user.userType === eUserType.REGISTERED_USER ? true : false),
		},
		icon: { type: "ng-zorro", name: "usergroup-add" },
	},
	{
		id: "payments",
		path: Paths.GO.ACCOUNT_BENEFITS,
		type: "link",
		name: "account.header.breadcrumb.benefits",
		extra: {
			typeof: "function",
			disabled: (user: iAccount) => (user && user.userType === eUserType.REGISTERED_USER ? true : false),
		},
		icon: { type: "ng-zorro", name: "tags" },
	},
	{
		id: "logout",
		type: "button",
		name: "menu.logout",
		extra: {
			typeof: "boolean",
			disabled: false,
		},
		icon: { type: "ng-zorro", name: "logout" },
	},
];

export const FOOTER_LINK_ACCESS: iLinkProps[] = [
	{ path: Paths.GO.PROJECTS, type: "link", name: "menu.investments" },
	{ path: Paths.GO.HOW_IT_WORKS, type: "link", name: "menu.howItWorks" },
	{ path: Paths.GO.BLOG, type: "link", name: "menu.blog" },
];

export const FOOTER_LINK_ABOUT: iLinkProps[] = [
	{ path: Paths.GO.ABOUT, type: "link", name: "menu.about" },
	{ path: Paths.GO.BENEFITS, type: "link", name: "menu.benefits" },
	{ path: Paths.GO.FAQ, type: "link", name: "menu.faq" },
	{ path: Paths.GO.CONTACT, type: "link", name: "menu.contact" },
];

export const INTERNAL_PAGE_DEFAULT: iInternalPage = {
	navIsWhite: false,
	renderNavbar: true,
	renderFooter: true,
	renderBegin: true,
	beginFill: false,
	requireAuth: false,
	requireCreated: false,
	rejectAuth: false,
	seo: null,
	breadcrums: null,
};

export const QUESTIONS = [
	{ value: true, label: "true" },
	{ value: false, label: "false" },
];

export const MARITAL_STATUS_HAS_COUPLE: eMaritalStatus[] = [eMaritalStatus.MARRIED, eMaritalStatus.UNION];

export const NotificationTitle: NotificationKeys = {
	ACCOUNT_REGISTERED: "notification.account.registered.title",
	PASSWORD_CHANGED: "notification.password.changed.title",
	ACCOUNT_CREATED: "notification.account.created.title",
	IDENTITY_VALIDATED: "notification.identity.validated.title",
	PERSONAL_DATA_VALIDATED: "notification.personal.data.validated.title",
	CONTACT_DATA_VALIDATED: "notification.contact.data.validated.title",
	BUSINESS_CONTACT_DATA_VALIDATED: "notification.business.contact.data.validated.title",
	LABOR_DATA_VALIDATED: "notification.labor.data.validated.title",
	BUSINESS_DATA_VALIDATED: "notification.business.data.validated.title",
	REPRESENTATIVE_DATA_VALIDATED: "notification.representative.data.validated.title",
	DOCUMENT_CONFIRMED: "notification.document.confirmed.title",
	DOCUMENT_REJECTED: "notification.document.rejected.title",
	NEW_REQUEST_DOCUMENT: "notification.new.request.document.title",
	EMAIL_VALIDATED: "notification.email.validated.title",
	PHONE_VALIDATED: "notification.phone.validated.title",
	ACCOUNT_ACTIVATED: "notification.account.activated.title",
	ACCOUNT_PAUSED: "notification.account.paused.title",
	ACCOUNT_REACTIVATED: "notification.account.reactivated.title",
	BANK_ACCOUNT_ADDED: "notification.bank.account.added.title",
	CUSTOMER_CAPITAL_CHANGED: "notification.customer.capital.changed.title",
	NEW_INVESTMENT_CREATED: "notification.new.investment.created.title",
	INVESTMENT_CANCELLED: "notification.investment.cancelled.title",
	INVESTMENT_CONFIRMED: "notification.investment.confirmed.title",
	INVESTMENT_MOVEMENT_CONFIRMED: "notification.investment.movement.confirmed.title",
	INVESTMENT_EXCHANGE_MOVEMENT_CONFIRMED: "notification.investment.exchange.movement.confirmed.title",
	INVESTMENT_MOVEMENT_DELETED: "notification.investment.movement.deleted.title",
	SHARE_MOVEMENT_CONFIRMED: "notification.share.movement.confirmed.title",
	INCOME_MOVEMENT_CONFIRMED: "notification.income.movement.confirmed.title",
	RELOCATION_MOVEMENT_CONFIRMED: "notification.relocation.movement.confirmed.title",
	RESCISSION_MOVEMENT_CONFIRMED: "notification.rescission.movement.confirmed.title",
	NEW_INVESTMENT_DOCUMENT: "notification.new.investment.document.title",
	NEW_BENEFIT_ADDED: "notification.new.benefit.added.title",
	REFERRAL_PAYED: "notification.referral.payed.title",
};

export const NotificationDescription: NotificationKeys = {
	ACCOUNT_REGISTERED: "notification.account.registered.description",
	PASSWORD_CHANGED: "notification.password.changed.description",
	ACCOUNT_CREATED: "notification.account.created.description",
	IDENTITY_VALIDATED: "notification.identity.validated.description",
	PERSONAL_DATA_VALIDATED: "notification.personal.data.validated.description",
	CONTACT_DATA_VALIDATED: "notification.contact.data.validated.description",
	BUSINESS_CONTACT_DATA_VALIDATED: "notification.business.contact.data.validated.description",
	LABOR_DATA_VALIDATED: "notification.labor.data.validated.description",
	BUSINESS_DATA_VALIDATED: "notification.business.data.validated.description",
	REPRESENTATIVE_DATA_VALIDATED: "notification.representative.data.validated.description",
	DOCUMENT_CONFIRMED: "notification.document.confirmed.description",
	DOCUMENT_REJECTED: "notification.document.rejected.description",
	NEW_REQUEST_DOCUMENT: "notification.new.request.document.description",
	EMAIL_VALIDATED: "notification.email.validated.description",
	PHONE_VALIDATED: "notification.phone.validated.description",
	ACCOUNT_ACTIVATED: "notification.account.activated.description",
	ACCOUNT_PAUSED: "notification.account.paused.description",
	ACCOUNT_REACTIVATED: "notification.account.reactivated.description",
	BANK_ACCOUNT_ADDED: "notification.bank.account.added.description",
	CUSTOMER_CAPITAL_CHANGED: "notification.customer.capital.changed.description",
	NEW_INVESTMENT_CREATED: "notification.new.investment.created.description",
	INVESTMENT_CANCELLED: "notification.investment.cancelled.description",
	INVESTMENT_CONFIRMED: "notification.investment.confirmed.description",
	INVESTMENT_MOVEMENT_CONFIRMED: "notification.investment.movement.confirmed.description",
	INVESTMENT_EXCHANGE_MOVEMENT_CONFIRMED: "notification.investment.exchange.movement.confirmed.description",
	INVESTMENT_MOVEMENT_DELETED: "notification.investment.movement.deleted.description",
	SHARE_MOVEMENT_CONFIRMED: "notification.share.movement.confirmed.description",
	INCOME_MOVEMENT_CONFIRMED: "notification.income.movement.confirmed.description",
	RELOCATION_MOVEMENT_CONFIRMED: "notification.relocation.movement.confirmed.description",
	RESCISSION_MOVEMENT_CONFIRMED: "notification.rescission.movement.confirmed.description",
	NEW_INVESTMENT_DOCUMENT: "notification.new.investment.document.description",
	NEW_BENEFIT_ADDED: "notification.new.benefit.added.description",
	REFERRAL_PAYED: "notification.referral.payed.description",
};

export const NotificationImg: NotificationKeys = {
	ACCOUNT_REGISTERED: "notification-icon.svg",
	PASSWORD_CHANGED: "lock-icon.svg",
	ACCOUNT_CREATED: "account-icon.svg",
	IDENTITY_VALIDATED: "identity-icon.svg",
	PERSONAL_DATA_VALIDATED: "shield-check-icon.svg",
	CONTACT_DATA_VALIDATED: "shield-check-icon.svg",
	BUSINESS_CONTACT_DATA_VALIDATED: "shield-check-icon.svg",
	LABOR_DATA_VALIDATED: "shield-check-icon.svg",
	BUSINESS_DATA_VALIDATED: "shield-check-icon.svg",
	REPRESENTATIVE_DATA_VALIDATED: "shield-check-icon.svg",
	DOCUMENT_CONFIRMED: "document-confirmed-icon.svg",
	DOCUMENT_REJECTED: "document-rejected-icon.svg",
	NEW_REQUEST_DOCUMENT: "new-document-icon.svg",
	EMAIL_VALIDATED: "envelope-icon.svg",
	PHONE_VALIDATED: "chat-check-icon.svg",
	ACCOUNT_ACTIVATED: "rocket-icon.svg",
	ACCOUNT_PAUSED: "pause-icon.svg",
	ACCOUNT_REACTIVATED: "check-icon.svg",
	BANK_ACCOUNT_ADDED: "bank-icon.svg",
	CUSTOMER_CAPITAL_CHANGED: "lightning-icon.svg",
	NEW_INVESTMENT_CREATED: "donut-icon.svg",
	INVESTMENT_CANCELLED: "times-icon.svg",
	INVESTMENT_CONFIRMED: "bar-chart-icon.svg",
	INVESTMENT_MOVEMENT_CONFIRMED: "heck-icon.svg",
	INVESTMENT_EXCHANGE_MOVEMENT_CONFIRMED: "check-icon.svg",
	INVESTMENT_MOVEMENT_DELETED: "times-icon.svg",
	SHARE_MOVEMENT_CONFIRMED: "check-icon.svg",
	INCOME_MOVEMENT_CONFIRMED: "check-icon.svg",
	RELOCATION_MOVEMENT_CONFIRMED: "check-icon.svg",
	RESCISSION_MOVEMENT_CONFIRMED: "check-icon.svg",
	NEW_INVESTMENT_DOCUMENT: "new-document-icon.svg",
	NEW_BENEFIT_ADDED: "cupon-icon.svg",
	REFERRAL_PAYED: "money-icon.svg",
};

export const NotificationPath = {
	ACCOUNT_REGISTERED: Paths.GO.ACCOUNT,
	PASSWORD_CHANGED: Paths.GO.CONTACT,
	ACCOUNT_CREATED: Paths.GO.ACCOUNT_PROFILE,
	IDENTITY_VALIDATED: Paths.GO.ACCOUNT,
	PERSONAL_DATA_VALIDATED: Paths.GO.ACCOUNT_PROFILE,
	CONTACT_DATA_VALIDATED: Paths.GO.ACCOUNT_PROFILE,
	LABOR_DATA_VALIDATED: Paths.GO.ACCOUNT_PROFILE,
	BUSINESS_DATA_VALIDATED: Paths.GO.ACCOUNT_PROFILE,
	REPRESENTATIVE_DATA_VALIDATED: Paths.GO.ACCOUNT_PROFILE,
	DOCUMENT_CONFIRMED: Paths.GO.ACCOUNT_PROFILE_DOCUMENTS,
	DOCUMENT_REJECTED: Paths.GO.ACCOUNT_PROFILE_DOCUMENTS,
	NEW_REQUEST_DOCUMENT: Paths.GO.ACCOUNT_PROFILE_DOCUMENTS,
	EMAIL_VALIDATED: Paths.GO.ACCOUNT_PROFILE,
	PHONE_VALIDATED: Paths.GO.ACCOUNT_PROFILE,
	ACCOUNT_ACTIVATED: Paths.GO.ACCOUNT,
	ACCOUNT_PAUSED: Paths.GO.ACCOUNT,
	ACCOUNT_REACTIVATED: Paths.GO.ACCOUNT,
	BANK_ACCOUNT_ADDED: Paths.GO.ACCOUNT_PROFILE_DATABANK,
	CUSTOMER_CAPITAL_CHANGED: Paths.GO.ACCOUNT,
	NEW_INVESTMENT_CREATED: Paths.GO.ACCOUNT_INVESTMENTS,
	INVESTMENT_CANCELLED: Paths.GO.ACCOUNT_INVESTMENTS,
	INVESTMENT_CONFIRMED: Paths.GO.ACCOUNT_INVESTMENTS,
	INVESTMENT_MOVEMENT_CONFIRMED: Paths.GO.ACCOUNT_INVESTMENTS,
	INVESTMENT_EXCHANGE_MOVEMENT_CONFIRMED: Paths.GO.ACCOUNT_INVESTMENTS,
	INVESTMENT_MOVEMENT_DELETED: Paths.GO.ACCOUNT_INVESTMENTS,
	SHARE_MOVEMENT_CONFIRMED: Paths.GO.ACCOUNT_INVESTMENTS,
	INCOME_MOVEMENT_CONFIRMED: Paths.GO.ACCOUNT_INVESTMENTS,
	RELOCATION_MOVEMENT_CONFIRMED: Paths.GO.ACCOUNT_INVESTMENTS,
	RESCISSION_MOVEMENT_CONFIRMED: Paths.GO.ACCOUNT_INVESTMENTS,
	NEW_INVESTMENT_DOCUMENT: Paths.GO.ACCOUNT_INVESTMENTS,
	NEW_BENEFIT_ADDED: Paths.GO.ACCOUNT_BENEFITS,
	REFERRAL_PAYED: Paths.GO.ACCOUNT_REFERRED,
};

export const maskOnlyNumber: InputmaskOptions = {
	mask: "0*",
	allowNegativeNumbers: false,
};

export const maskDateTime = (): InputmaskOptions => {
	return {
		mask: "d0/M0/0000",
		dropSpecialCharacters: false,
	};
};

export const maskFormatNumber = (locale: string): InputmaskOptions => {
	return {
		mask: "separator",
		thousandSeparator: separatorMil(locale),
		decimalMarker: separatorDec(locale) as any,
		dropSpecialCharacters: true,
	};
};

export const maskPattern = (pattern: string): InputmaskOptions => {
	return {
		mask: "P*",
		patterns: {
			P: {
				pattern: /[0-9]/,
			},
		},
	};
};

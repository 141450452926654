import { iBreadcrum } from "./components/breadcrums.interface";
import { iAccount } from "./data/customer.interface";
import { iInitWebSite } from "./data/site.interface";
import { TranslateService } from "@ngx-translate/core";
import { eMovementSubType } from "./data/shares.interface";
import { HttpHeaders, HttpParams } from "@angular/common/http";

export interface iInternalPage {
	/** Determina si el nav se mostrara con fondo Blanco */
	navIsWhite: boolean;
	/** Determina si se muestra el nav */
	renderNavbar: boolean;
	/** Determina si se muestra el footer */
	renderFooter: boolean;
	/** Determina si se muestra el begin last promo */
	renderBegin: boolean;
	/** Fill Bigin */
	beginFill: number | boolean;
	/** Determina si la ruta require estar logeado */
	requireAuth: boolean;
	/** Determina si la ruta require estar logeado */
	requireCreated: boolean;
	/** Determina si la ruta sera rechazada cuando el usuario este logeado */
	rejectAuth: boolean;
	/** Seo de cada uno de las pestañas */
	seo: iInternalSeo;
	/** Breadcrums */
	breadcrums: iBreadcrum[];
	/** Tipo de vista del proyecto */
	type?: "sheet" | "invest";
}

export interface iInternalSeo {
	/** Estable el titulo de la pestaña */
	title?: string;
	/** Establece la descripcion de la pestaña */
	description?: string;
	/** Establece las keywords de la pestaña */
	keywords?: string;
	/** Establece la imagen SEO de la pestaña */
	image?: string;
	/** Establece la configuración de los robots de los navegadores */
	robots?: string;
}

export interface iCookieOptions {
	expires?: number;
	maxAge?: number;
	path?: string;
	domain?: string;
	secure?: string;
	sameSite?: "none" | "strict" | "lax";
}

export type CookieKey = "referrer" | "referrerBusiness" | "referrerUser" | "utm_campaign" | "utm_medium" | "utm_source" | "user-token";

export interface iAuthStatus {
	link: string;
	status: "createdAccount" | "activatedAccount" | "account" | "login";
}

export interface iUid {
	/** Uid del Elemento */
	uid?: string;
	/** id del Elemento */
	id?: number;
}
/** Canal de Navegación */
export enum eChannel {
	/** Desde el Admin del sitio */
	ADMIN = "ADMIN",
	/** Desde el Backend */
	API = "API",
	/** Desde una App Mobile */
	MOBILE = "MOBILE",
	/** Desde un Navegador de Ordenador */
	WEB = "WEB",
	/** Desde un Navegador Mobil */
	WEB_MOBILE = "WEB_MOBILE",
}

/** Claves del `LocalStorage` */
export enum eStorageKeys {
	/** Guarda los Datos del Usuario */
	USER_DATA = "user-data",
	/** Guarda el token del usuario */
	TOKEN = "user-token",
	/** Guarda la fecha de caducidad del token */
	TOKEN_EXPIRATION = "user-token-expiration",
	/** Guarda las notificaciones */
	NOTIFICATION = "user-notification",
	/** Guarda el lenguage seteado por el usuario o obtenido por defecto */
	LANG = "lang",
	/** Guarda el instanceid del sitio en caso de estar en localhost */
	SITE_KEY = "site-key",
	/** Guarda el Paso del Creacion de cuenta del usuario */
	CURRENT_STEP = "current-step",
	/** Guarda el tipo de usuario */
	TYPE_PERSON = "type-person",
	/** Guarda los datos de la empresa */
	STEP_LEGAL_BUSINESS = "legal-business",
	/** Guarda los datos de los representantes de la empresa */
	STEP_LEGAL_REPRESENTATIVES = "legal-representatives",
	/** Guarda los datos de contacto del usuario */
	STEP_CONTACT = "step-contact",
	/** Guarda los datos de personales del usuario */
	STEP_PHYSICAL_PERSONAL = "physical-personal",
	/** Guarda los datos laborales del usuario */
	STEP_PHYSICAL_LABOR = "physical-labor",
}

/** Claves de Cambios en el `localStorage` */
export enum eStorageChange {
	/** Cambios en los datos del usuario */
	CHANGE_USER = "CHANGE_USER",
	/** Cambios en los datos del Sitio */
	CHANGE_SITE = "CHANGE_SITE",
	/** Cambio en el lenguage */
	CHANGE_LANG = "CHANGE_LANG",
	/** Cambios Generales */
	CHANGE_ALL = "CHANGE_ALL",
	/** Cambios de Notificaciones */
	CHANGE_NOTIFICATION = "CHANGE_NOTIFICATION",
}

export interface iGralParams {
	channel?: eChannel;
	utmCampaign?: string;
	utmMedium?: string;
	utmSource?: string;
	utmContent?: string;
}

export interface iForm<T> {
	status: boolean;
	value: T;
}

export interface iKeys<T> {
	[key: string]: T;
}

export interface iAmount {
	amount: number;
	currency: string;
}

export interface iName {
	uid: string;
	name: string;
}

export interface iValue {
	uid: string;
	value: string;
}

export interface iInfoBy {
	uid: string;
	name: string;
	businessUid: string;
	businessName: string;
}

export interface iInfoAt extends iInfoBy {
	updatedAt: number;
	createdAt: number;
}

export interface iProviderError {
	errorAt: number;
	errorMessage: string;
}

/** Creacion por medio publicitario */
export interface iUtm {
	/** Campaña */
	utmCampaign: string;
	/** Medio */
	utmMedium: string;
	/** Buscador */
	utmSource: string;
}
/** Sexo */
export enum eGender {
	/** Hombre */
	MALE = "MALE",
	/** Mujer */
	FEMALE = "FEMALE",
	/** No Binario */
	NO_BINARY = "NO_BINARY",
	/** Otros */
	OTHERS = "OTHERS",
}
/** Estado Civil */
export enum eMaritalStatus {
	/** Soltero/a */
	SINGLE = "SINGLE",
	/** Casado/a */
	MARRIED = "MARRIED",
	/** Viudo/a */
	WID = "WID",
	/** Divorciado/a */
	DIVORCED = "DIVORCED",
	/** Union Convivencial */
	UNION = "UNION",
}

export interface iVariables {
	key: string;
	get?: string | string[] | iConditionVariable | iConditionVariable[];
	value?: any;
	default?: any;
	transform?: (value: any, options?: iTransformOptions) => any;
	translate?: iTranslateVariable;
}

export interface iTransformOptions {
	locale: string;
	lang: string;
	site: iInitWebSite;
	customer: iAccount;
	Translate: TranslateService;
}

export interface iConditionVariable {
	condition: { key: string; value: any };
	value: string | string[];
}

export interface iTranslateVariable {
	prefix?: string;
	suffix?: string;
}

export interface iChartDoughnut {
	label: string;
	color?: string;
	investment: iAmount;
}

export interface iIncomeDate {
	year: number;
	amounts: number[];
}

export interface iIncomeCurrency {
	[currency: string]: iIncomeType[];
}

export interface iIncomeType {
	type: eMovementSubType;
	amount: number;
}

export interface HttpOptions {
	headers?: HttpHeaders | { [header: string]: string | string[] };
	observe?: "body" | "events" | "response";
	params?: HttpParams | { [param: string]: string | string[] };
	reportProgress?: boolean;
	responseType?: "arraybuffer" | "blob" | "json" | "text";
	withCredentials?: boolean;
}

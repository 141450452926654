import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonStoreComponent } from "./button-store.component";
import { TranslateModule } from "@ngx-translate/core";
import { BtnDirectiveModule } from "src/app/directives/btn/btn.directive.module";

@NgModule({
	declarations: [ButtonStoreComponent],
	imports: [CommonModule, BtnDirectiveModule, TranslateModule],
	exports: [ButtonStoreComponent],
})
export class ButtonStoreModule {}

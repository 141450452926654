import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./footer.component";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { ButtonStoreModule } from "../button-store/button-store.module";
import { LogoIconModule } from "../icons/logo/logo.module";
import { SocialMediaModule } from "../social-media/social-media.module";
import { InClientDirectiveModule } from "src/app/directives/in-client/in-client.module";

@NgModule({
	declarations: [FooterComponent],
	imports: [CommonModule, TranslateModule, RouterModule, SocialMediaModule, ButtonStoreModule, InClientDirectiveModule, LogoIconModule],
	exports: [FooterComponent],
})
export class FooterModule {}

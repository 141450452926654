<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 120 120" class="search-page" xml:space="preserve">
	<circle class="search-page-0 fill-gray-400" opacity=".5" cx="72" cy="49.3" r="31.4" />
	<line class="search-page-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="87.2" y1="88.7" x2="77.9" y2="79.5" />
	<circle class="search-page-2 fill-primary" cx="58.5" cy="54.7" r="31.4" />
	<path
		class="search-page-3 fill-none stroke-white"
		stroke-width="3"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-miterlimit="10"
		d="M80.3,41.6c2.5,4.2,3.7,9.1,3.5,13.9c-0.2,6.2-2.7,12.3-7.5,17c-9.9,9.9-26,9.9-36,0c-9.9-9.9-9.9-26,0-36    c9.9-9.9,26-9.9,36,0"
	/>
	<line class="search-page-1 fill-none stroke-gray-400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="86" y1="77.6" x2="92.2" y2="83.8" />
	<path
		class="search-page-1 fill-none stroke-gray-400"
		stroke-width="3"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-miterlimit="10"
		d="M115.9,112.5L115.9,112.5c-2.6,2.6-6.9,2.6-9.5,0L84.9,91.1l9.5-9.5l21.5,21.5    C118.5,105.6,118.5,109.9,115.9,112.5z"
	/>
	<path class="search-page-3 fill-none stroke-white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M54.5,37.9c0,0-10.6,0.6-12.6,11.4" />
	<path
		class="search-page-1 fill-none stroke-gray-400"
		stroke-width="3"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-miterlimit="10"
		d="M36.6,83H7c-2.6,0-4.8-2.1-4.8-4.8V10.8c0-2.6,2.1-4.8,4.8-4.8h103.2c2.6,0,4.8,2.1,4.8,4.8v67.4    c0,2.6-2.1,4.8-4.8,4.8h-8.2"
	/>
	<ellipse class="search-page-1 fill-none stroke-gray-400" opacity=".5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="35.2" cy="16" rx="3.1" ry="3.1" />
	<circle class="search-page-1 fill-none stroke-gray-400" opacity=".5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="23.6" cy="16" r="3.1" />
	<circle class="search-page-4 fill-none stroke-primary" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="12" cy="16" r="3.1" />
</svg>

<div class="offline-container">
	<div class="container">
		<div class="logo d-flex justify-content-center">
			<icon-warning></icon-warning>
		</div>
		<div class="text-center">
			<h2 class="font-weight-normal mb-1">
				<span>{{"status.offline.header" | translate}}</span>
			</h2>
			<p>{{"status.offline.body" | translate}}</p>
			<a [href]="mailSupport" class="btn btn-primary transition-3d-hover">
				<i class="fas fa-envelope"></i>
				<span>{{'status.offline.sendMessage' | translate}}</span>
			</a>
		</div>
	</div>
</div>

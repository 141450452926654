import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LogoIcon } from "./logo.icon";

@NgModule({
	declarations: [LogoIcon],
	imports: [CommonModule],
	exports: [LogoIcon],
})
export class LogoIconModule {}

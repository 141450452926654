import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BeginLastComponent } from "./begin-last.component";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { WaveBeginLastModule } from "../wave-begin-last/wave-begin-last.module";
import { SafePipeModule } from "src/app/pipes/safe/safe.module";

@NgModule({
	declarations: [BeginLastComponent],
	imports: [CommonModule, TranslateModule, SafePipeModule, RouterModule, WaveBeginLastModule],
	exports: [BeginLastComponent],
})
export class BeginLastModule {}

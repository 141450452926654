import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { CustomerService } from "./customer.service";
import { iDBRealtime } from "../interface/data/realtime.interface";
import { Database, DatabaseReference, objectVal, ref, remove, set } from "@angular/fire/database";
import { PlatformService } from "./platform.service";

@Injectable({
	providedIn: "root",
})
export class DbRealtimeService {
	/** Contiene la referencia a la base de datos */
	private Doc: DatabaseReference;
	/** Contiene el uid de la base de datos */
	private uid: string;
	/** Almacena los datos en tiempo real */
	private db = new BehaviorSubject<iDBRealtime>(null);
	/** Almacena la subscripcion a la base de datos */
	private sDoc: Subscription;

	constructor(private Database: Database, private Customer: CustomerService, private Platform: PlatformService) {}
	/** Modifica una clave de la base de datos */
	setKey(key: string, value: any) {
		const update = {
			...this.db.getValue(),
			[key]: value,
		};
		set(this.Doc, update);
	}
	/** Elimina la base de datos */
	destroySesion() {
		if (!this.Doc) return;
		this.db.next(null);
		remove(this.Doc);
		if (this.sDoc) this.sDoc.unsubscribe();
		this.uid = null;
	}
	/** Crea y se mantiene a la escucha de cambios en la base de datos. Tambien lo destruye si el usuario se deslogea */
	initSession() {
		if (this.Platform.isServer) return;
		this.Customer.getCustomer().subscribe((customer) => {
			if (!customer) return this.destroySesion();
			if (this.uid === customer.uid) return;
			this.uid = customer.uid;

			this.Doc = ref(this.Database, `/${customer.uid}`);

			this.sDoc = objectVal<iDBRealtime>(this.Doc).subscribe({
				next: (data) => this.db.next(data),
				error: (err) => this.db.error(err),
			});
		});
	}
	/** Retorna un Observable con los cambios a la base de datos */
	public getDB = () => this.db.asObservable();
}

import { Component, Input, OnInit } from "@angular/core";
import { FLAG } from "src/app/constants";
import { iLang, iLangs } from "src/app/interface/config.interface";
import { ApiCountriesService } from "src/app/services/api/api-countries.service";
import { LanguageService } from "src/app/services/language.service";

@Component({
	selector: "set-language",
	templateUrl: "./set-language.navbar.html",
	styleUrls: ["./set-language.navbar.scss"],
})
export class SetLanguageNavbar implements OnInit {
	/** Determina si el navbar esta en modo `internal` */
	@Input() internalPage: boolean = false;
	/** Obtiene un Array con los lenguages configurados en el sitio */
	public languages: iLangs;
	/** Determina el lenguage seleccionado */
	get currentLang() {
		return this.Language.lang;
	}

	constructor(private APICountries: ApiCountriesService, private Language: LanguageService) {}

	ngOnInit() {
		this.languages = this.Language.langs;
	}

	/** Obtiene el icono del lenguage */
	public getIcon(id: string) {
		const country = this.APICountries.countries.find((c) => c.alpha2Code.toLowerCase() === id.toLowerCase());
		if (country) return FLAG(country.alpha2Code);
		return "";
	}
	/** Evento de cambio de lenguage */
	public updateLanguage(lang: iLang) {
		if (lang.id !== this.currentLang.id) {
			this.Language.changeLang(lang.locale);
		}
	}
}

import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { DeviceDetectorService } from "ngx-device-detector";
import { catchError, Observable, throwError } from "rxjs";
import { HttpOptions } from "src/app/interface/common.interface";
import { eResponse, iResponse } from "src/app/interface/response.interface";
import { environment } from "src/environments/environment";
import { CustomerService } from "../customer.service";
import { LanguageService } from "../language.service";
import { PlatformService } from "../platform.service";
import { SessionService } from "../session.service";
import { StorageService } from "../storage.service";
import { CookiesService } from "../cookies.service";

@Injectable({
	providedIn: "root",
})
export class XhrService {
	constructor(
		private HTTP: HttpClient,
		private Platform: PlatformService,
		private Session: SessionService,
		private Customer: CustomerService,
		private Language: LanguageService,
		private Cookies: CookiesService,
		private Storage: StorageService,
		private Message: NzMessageService,
		private Device: DeviceDetectorService,
		private Translate: TranslateService
	) {}

	/** Peticion http por metodo `GET` */
	get(url: string, options?: HttpOptions): Observable<any> {
		return this.HTTP.get(this.setUrl(url), this.setOptions(url, options)).pipe(catchError((eRes) => throwError(() => this.handleError(eRes))));
	}
	/** Peticion http por metodo `POST` */
	post(url: string, body: any, options?: HttpOptions): Observable<any> {
		return this.HTTP.post(this.setUrl(url), body, this.setOptions(url, options)).pipe(catchError((eRes) => throwError(() => this.handleError(eRes))));
	}
	/** Peticion http por metodo `PUT` */
	put(url: string, body?: any, options?: HttpOptions): Observable<any> {
		return this.HTTP.put(this.setUrl(url), body, this.setOptions(url, options)).pipe(catchError((eRes) => throwError(() => this.handleError(eRes))));
	}
	/** Peticion http por metodo `DELETE` */
	delete(url: string, options?: HttpOptions): Observable<any> {
		return this.HTTP.delete(this.setUrl(url), this.setOptions(url, options)).pipe(catchError((eRes) => throwError(() => this.handleError(eRes))));
	}
	/** Controlador de errores*/
	private handleError(eRes: HttpErrorResponse) {
		const error = eRes.error as iResponse;
		if (this.Platform.isServer) return error;

		if (error && (error.code || error.error)) {
			const eRes = error.error || error.code;
			switch (eRes) {
				case eResponse.SECURITY_ERROR_TOKEN_ERROR:
				case eResponse.SECURITY_ERROR_TOKEN_EXPIRED:
				case eResponse.CUSTOMER_NOT_FOUND:
					if (this.Customer.isAuth) this.Customer.logout();
					break;
				case eResponse.SECURITY_ERROR_HAS_NO_PERMISSION:
				case eResponse.SECURITY_ERROR_INVALID_INSTANCEID:
				case eResponse.GENERAL_SERVER_ERROR:
				case eResponse.INVALID_REQUEST_PARAMETERS:
					this.Message.error(this.Translate.instant(eRes));
					break;
			}
		}
		return error;
	}
	/** Establece el header dependiendo de las opciones disponibles */
	private setOptions(url: string, options: HttpOptions = {}): any {
		let isPrivate = !url.startsWith("http");

		if (isPrivate) {
			let headers = new HttpHeaders({
				instanceid: this.Session.getHost(),
				lang: this.Language.lang?.id || "es",
			});

			let token = this.Storage.getToken() || this.Cookies.get("user-token");
			if (token) headers = headers.append("authorization", token);

			let device = this.Device.getDeviceInfo();
			if (device && this.Platform.isBrowser) headers = headers.append("device", JSON.stringify(device));

			if (options.headers) {
				Object.keys(options.headers).forEach((key) => {
					headers = headers.append(key, options.headers[key]);
				});
			}

			options = { ...options, headers };
		}

		return options;
	}
	/** Establece si el endpoint es de un tercero o propio del sitio */
	private setUrl(url: string): string {
		let isPrivate = !url.startsWith("http");
		if (isPrivate) {
			if (!url.startsWith("/")) url = "/" + url;
			return environment.apiUrl + url;
		}
		return url;
	}
}

<ng-container *ngIf="isBrowser; else SERVER">
	<ngx-loading-bar color="#00c853" height="4px" [includeSpinner]="false"></ngx-loading-bar>
	<loader *ngIf="isLoading; else APP" [fullscreen]="true"></loader>
</ng-container>

<ng-template #SERVER>
	<loader [fullscreen]="true"></loader>

	<div [hidden]="true">
		<ng-container [ngTemplateOutlet]="APP"></ng-container>
	</div>
</ng-template>

<ng-template #APP>
	<ng-container *ngIf="status === eSiteStatus.ACTIVE || status === eSiteStatus.BLOCKED">
		<navbar *ngIf="isNavbarVisible"></navbar>

		<router-outlet></router-outlet>

		<begin-last *ngIf="isBeginVisible"></begin-last>
		<cp-footer *ngIf="isFooterVisible"></cp-footer>
	</ng-container>

	<status-offline *ngIf="status === eSiteStatus.OFFLINE"></status-offline>

	<status-maintenance *ngIf="status === eSiteStatus.MAINTENANCE"></status-maintenance>
</ng-template>

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { eStorageChange, eStorageKeys } from "../interface/common.interface";
import { iAccount } from "../interface/data/customer.interface";
import { TTypePerson } from "../interface/data/profile.interface";
import { PlatformService } from "./platform.service";

@Injectable({
	providedIn: "root",
})
export class StorageService {
	public changeStorage = new BehaviorSubject<eStorageChange | null>(null);

	constructor(private Platform: PlatformService) {}

	/** Obtiene la clave del sitio en modo desarrollo desde el `localStorage` con la clave `site-key`*/
	getHost = () => (this.Platform.isBrowser ? localStorage.getItem(eStorageKeys.SITE_KEY) : null);
	/** Obtiene el lenguage en formato `locale` desde el `localStorage` con la clave `lang`*/
	getLang = () => (this.Platform.isBrowser ? localStorage.getItem(eStorageKeys.LANG) : null);
	/** Obtiene los datos del usuario guardados en el `localStorage` con la clave `user-data`*/
	getUserData() {
		if (!this.Platform.isBrowser) return null;
		const storedJson = localStorage.getItem(eStorageKeys.USER_DATA);
		if (storedJson !== null && (JSON.parse(storedJson) as iAccount)) {
			const stored: iAccount = JSON.parse(storedJson);
			return stored;
		}
		return null;
	}
	/** Obtiene las notificaciones del usuario guardados en el `localStorage` con la clave `user-notification`*/
	getNotifications() {
		if (!this.Platform.isBrowser) return null;
		const stored = localStorage.getItem(eStorageKeys.NOTIFICATION);
		if (stored !== null && !isNaN(Number(stored))) return stored === "true" ? true : false;
		return null;
	}
	/** Obtiene el token del usuario guardado en el `localStorage` con la clave `user-token`*/
	getToken = () => (this.Platform.isBrowser ? localStorage.getItem(eStorageKeys.TOKEN) : null);
	/** Obtiene el tiempo en el que expira el token guardado en el `localStorage con la clave `user-token-expiration`*/
	getTokenExpiration() {
		if (!this.Platform.isBrowser) return null;
		const stored = localStorage.getItem(eStorageKeys.TOKEN_EXPIRATION);
		if (stored !== null) return Number(stored);
		return null;
	}
	/** Obtiene los pasos guardados en el `localStorage` mediante  su clave de step */
	getAccountCreationStep<T>(step: eStorageKeys) {
		if (!this.Platform.isBrowser) return null;
		let stored = localStorage.getItem(step);
		if (stored !== null && JSON.parse(stored)) return JSON.parse(stored) as T;
		return null;
	}
	/** Obtiene el tipo de persona en el `localStorage` con la clave `type-person`*/
	getTypePerson = (): TTypePerson | null => (this.Platform.isBrowser ? (localStorage.getItem(eStorageKeys.TYPE_PERSON) as TTypePerson) : null);
	/** Obtiene el ultimo paso realizado por el usuario en el `localStorage` con la clave `current-step` */
	getCurrentStep = () => (this.Platform.isBrowser ? Number(localStorage.getItem(eStorageKeys.CURRENT_STEP)) : null);
	/** Establece el valor de uno de los pasos de registro */
	setAccountCreationStep<T>(key: eStorageKeys, value: T) {
		if (!this.Platform.isBrowser) return;
		localStorage.setItem(key, JSON.stringify(value));
		this.changeStorage.next(eStorageChange.CHANGE_USER);
	}
	/** Establece el valor del tipo de persona en el `localStorage` con la clave `type-person` */
	setTypePerson = (value: TTypePerson) => localStorage.setItem(eStorageKeys.TYPE_PERSON, value);
	/** Almacena el paso Actual */
	setCurrentStep = (value: number) => localStorage.setItem(eStorageKeys.CURRENT_STEP, String(value));
	/** Establece la clave del sitio en el `localStorage` con la clave `site-key`*/
	setHost(hostname: string) {
		localStorage.setItem(eStorageKeys.SITE_KEY, hostname);
		this.changeStorage.next(eStorageChange.CHANGE_SITE);
	}
	/** Establece el lenguage en el `localStorage` con la clave `lang` */
	setLang(lang: string) {
		localStorage.setItem(eStorageKeys.LANG, lang);
		this.changeStorage.next(eStorageChange.CHANGE_LANG);
	}
	/** Establece el token del usuario en el `localStorage` con la clave `user-token` */
	saveToken(token: string) {
		localStorage.setItem(eStorageKeys.TOKEN, token);
		this.changeStorage.next(eStorageChange.CHANGE_USER);
	}
	/** Establece el fecha de expiracion en formato `timestamp` en el `localStorage` con la clave `user-token-expiration` */
	saveTokenExpiration(tokenExpiration: number) {
		localStorage.setItem(eStorageKeys.TOKEN_EXPIRATION, String(tokenExpiration));
		this.changeStorage.next(eStorageChange.CHANGE_USER);
	}
	/** Establece si el usuario las notificaciones del usuario en el `localStorage` con la clave `user-notification` */
	saveNotifications(notifications: boolean) {
		localStorage.setItem(eStorageKeys.NOTIFICATION, String(notifications));
		this.changeStorage.next(eStorageChange.CHANGE_USER);
	}
	/** Establece los datos del usuario en el `localStorage` con la clave `user-data` */
	saveUserData(userData: iAccount) {
		localStorage.setItem(eStorageKeys.USER_DATA, JSON.stringify(userData));
		this.changeStorage.next(eStorageChange.CHANGE_USER);
	}
	removeUser() {
		if (!this.Platform.isBrowser) return;
		localStorage.removeItem(eStorageKeys.USER_DATA);
		localStorage.removeItem(eStorageKeys.TOKEN);
		localStorage.removeItem(eStorageKeys.TOKEN_EXPIRATION);
		this.removeCreationAccountSteps();
		this.removeTypePerson();
	}

	/** Elimina los pasos de creacion de cuenta del localStorage */
	removeCreationAccountSteps() {
		if (!this.Platform.isBrowser) return;
		localStorage.removeItem(eStorageKeys.STEP_LEGAL_BUSINESS);
		localStorage.removeItem(eStorageKeys.STEP_LEGAL_REPRESENTATIVES);
		localStorage.removeItem(eStorageKeys.STEP_CONTACT);
		localStorage.removeItem(eStorageKeys.STEP_PHYSICAL_PERSONAL);
		localStorage.removeItem(eStorageKeys.STEP_PHYSICAL_LABOR);
	}
	/** Elimina el tipo de persona en el localStorage */
	removeTypePerson() {
		if (!this.Platform.isBrowser) return;
		localStorage.removeItem(eStorageKeys.TYPE_PERSON);
	}
	/** Elimina el paso actual del localStorage */
	removeCurrentStep() {
		if (!this.Platform.isBrowser) return;
		localStorage.removeItem(eStorageKeys.CURRENT_STEP);
	}
	/** Limpia el `localStorage`*/
	clear() {
		if (!this.Platform.isBrowser) return;
		localStorage.clear();
		this.changeStorage.next(eStorageChange.CHANGE_ALL);
	}
}

import { eIdentityValidationStatus } from "./customer.interface";

/** Contiene la información en tiempo real */
export interface iDBRealtime {
	/** Contiene el nuevo estado de identidad del usuario */
	UPDATE_MATI_STATUS?: eIdentityValidationStatus;
	/** Contiene la cantidad de notificaciones pendientes del usuario */
	UPDATE_NOTIFICATIONS?: number;
	/** Determina si el frontend debe resolicitar los datos del usuario */
	UPDATE_USER_DATA?: boolean;
}
/** Claves de conexiones en tiempo real */
export enum eDBRealtime {
	/** Permite establecer el nuevo estado de `identityValidation` */
	UPDATE_MATI_STATUS = "UPDATE_MATI_STATUS",
	/** Permite establecer la cantidad notificaciones pendientes  */
	UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS",
	/** Permite actualizar los datos del cliente en caso de haber cambios */
	UPDATE_USER_DATA = "UPDATE_USER_DATA",
}

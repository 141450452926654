import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class ThemeService {
	colors = {
		primary: "#007bd2",
		secondary: "#77838f",
		success: "#00c9a7",
		info: "#00dffc",
		warning: "#ffc107",
		danger: "#de4437",
		light: "#f8f9fa",
		dark: "#1e2022",
		gradientGreen: { "0%": "#00c854", "100%": "#7bc800" },
	};

	constructor() {}
}

<header id="header" onSticky [top]="offsetMenu" (isSticky)="handleIsSticky($event)">
	<nav class="navbar">
		<!--Logo Blanco-->
		<ng-container *ngIf="!navIsWhite && !isSticky">
			<a class="nav-logo d-none d-lg-block" [routerLink]="Paths.GO.HOME">
				<span class="sr-only">{{ SiteName }}</span>
				<icon-logo *inClient theme="white"></icon-logo>
			</a>
		</ng-container>
		<!--Logo Blanco-->
		<!--Sidenav Left-->
		<div class="sidenav-action d-block d-lg-none">
			<button aria-label="site menu" (click)="sidenavLeft.active = !sidenavLeft.active" class="sidenav-trigger">
				<i *inClient nz-icon [nzType]="'menu'" [nzTheme]="'outline'"></i>
			</button>
			<sidenav [(active)]="sidenavLeft.active" [position]="sidenavLeft.position"></sidenav>
		</div>
		<!--Sidenav Left-->
		<!--Logo Azul-->
		<a class="nav-logo" [class.d-lg-none]="!navIsWhite && !isSticky" [routerLink]="Paths.GO.HOME">
			<span class="sr-only">{{ SiteName }}</span>
			<icon-logo></icon-logo>
		</a>
		<!--Logo Azul-->
		<!--Sidenav Right-->
		<div class="sidenav-action d-block d-lg-none">
			<a *ngIf="userData === null" class="sidenav-trigger" [routerLink]="Paths.GO.AUTH">
				<span class="sr-only">{{ "menu.login" | translate }}</span>
				<i *inClient nz-icon [nzType]="'user'" [nzTheme]="'outline'"></i>
			</a>

			<button aria-label="user menu" *ngIf="userData" (mouseup)="sidenavRight.active = !sidenavRight.active" class="sidenav-trigger">
				<div class="avatar-view">
					<nz-progress [nzPercent]="accountProgressStatusPercent" [nzStrokeColor]="Theme.colors.gradientGreen" [nzShowInfo]="false" nzType="circle" nzStrokeLinecap="square" [nzWidth]="38"></nz-progress>
					<div class="profile-circle">
						<avatar [size]="'xs'" [avatar]="userData.userAvatar"></avatar>
					</div>
				</div>
			</button>

			<sidenav [(active)]="sidenavRight.active" [position]="sidenavRight.position"></sidenav>
		</div>
		<!--Sidenav Right-->

		<div class="navbar-right" [class]="{ 'pt-0': isSticky }">
			<!--Nav Top-->
			<div class="flags ml-auto" *ngIf="!isSticky">
				<div class="d-none d-sm-inline-block ml-sm-auto">
					<ul class="list-inline mb-0">
						<callus [internalPage]="navIsWhite" [border]="langs.length <= 1 && !!userData"></callus>
						<ng-container *ngIf="!userData">
							<li class="list-inline-item register" [class.internal]="navIsWhite" [class.no-lang]="langs.length <= 1">
								<a class="list-item" [routerLink]="Paths.GO.AUTH_REGISTER">
									<i class="fas fa-user-circle mr-2"></i>
									<span>{{ "menu.register" | translate }}</span>
								</a>
							</li>
						</ng-container>
						<set-language [internalPage]="navIsWhite" *ngIf="langs.length > 1"></set-language>
					</ul>
				</div>
			</div>
			<!--Nav Top-->
			<!--Nav Bottom-->
			<ul class="navbar-nav">
				<ng-container *ngFor="let item of navbarData; let i = index">
					<ng-container [ngSwitch]="item.type">
						<li class="nav-item">
							<nav-link [isSticky]="isSticky" [internalPage]="navIsWhite" [data]="item" *ngSwitchCase="'link'"></nav-link>
							<nav-dropdown [isSticky]="isSticky" [internalPage]="navIsWhite" [data]="item" *ngSwitchCase="'dropdown'"></nav-dropdown>
							<nav-button [isSticky]="isSticky" [internalPage]="navIsWhite" [data]="item" *ngSwitchCase="'button'"></nav-button>
						</li>
					</ng-container>
				</ng-container>
			</ul>
			<!--Nav Bottom-->
		</div>
	</nav>
</header>

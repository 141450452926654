import { Component, Input, OnInit } from "@angular/core";
import { Config } from "src/app/config";
import { StoreButton } from "src/app/interface/components/button-store.interface";
import { iStore } from "src/app/interface/config.interface";

@Component({
	selector: "button-store",
	templateUrl: "./button-store.component.html",
	styleUrls: ["./button-store.component.scss"],
})
export class ButtonStoreComponent implements OnInit {
	/** Tipo de button `google` o `apple` */
	@Input() type: StoreButton;
	/** Store */
	public store: iStore;

	constructor() {}

	ngOnInit(): void {
		this.store = Config.stores.find((store) => store.id === this.type);
	}

	public goStore() {
		if (!this.store.available) return;
		window.open(this.store.url, "_blank");
	}
}

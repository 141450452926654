import { Injectable } from "@angular/core";
import { map, tap } from "rxjs";
import { iResCountries, iResState } from "src/app/interface/api/countries.interface";
import { iCountry, iPhoneCode, iStates } from "src/app/interface/data/countries.interface";
import { RouterEndpointsService } from "./router-endpoints.service";
import { XhrService } from "./xhr.service";

@Injectable({
	providedIn: "root",
})
export class ApiCountriesService {
	/** Paises */
	public countries: iCountry[] = [];
	/** Estados/Provincias de un Pais */
	public states: iStates[] = [];
	/** Prefijos de telefono */
	public phoneCodes: iPhoneCode[] = [];

	constructor(private XHR: XhrService, private Endpoints: RouterEndpointsService) {}

	public getCountries() {
		return this.XHR.get(this.Endpoints.LOCATION.COUNTRIES).pipe(
			tap({
				next: (res: iResCountries) => {
					if (!res.ok) return;
					this.setCountries(res.countries);
				},
			})
		);
	}

	public setCountries(resCountries: iCountry[]) {
		const countries = resCountries
			.map((country) => {
				return {
					...country,
					mobileCode: country.alpha2Code === "AR" ? `+${country.callingCode}9` : `+${country.callingCode}`,
				};
			})
			.sort((a, b) => a.name.localeCompare(b.name));
		this.countries = countries;
	}

	public getStates(countryCode: string, changeStates: boolean = false) {
		return this.XHR.get(this.Endpoints.LOCATION.STATES(countryCode)).pipe(
			map((res: iResState) => {
				return {
					...res,
					states: res.states.sort((a, b) => a.name.localeCompare(b.name)),
				};
			}),
			tap({
				next: (res: iResState) => {
					if (!res.ok) return;
					if (changeStates) this.setStates(res.states);
				},
			})
		);
	}

	public setStates(states: iStates[]) {
		this.states = states;
	}
}

<div ngbDropdown #myDrop="ngbDropdown" placement="bottom-left">
	<button [attr.aria-label]="data.name | translate" [id]="data.name" ngbDropdownToggle class="nav-link" [class]="{'link-internal': internalPage || isSticky}">{{data.name | translate}}</button>

	<div ngbDropdownMenu [attr.aria-labelledby]="data.name">
		<ng-container *ngFor="let submenu of data.submenu; let i = index">
			<ng-container *ngIf="!submenu.extra else extraTemplate">
				<hr class="mx-4 my-0" *ngIf="i > 0" />
				<a ngbDropdownItem class="dropdown-submenu-link" [routerLink]="submenu.path">
					<div class="media-icon">
						<ng-container [ngComponentOutlet]="submenu.icon.component"></ng-container>
					</div>
					<div class="media-body">
						<span>{{submenu.name | translate}}</span>
						<small>{{submenu.subname | translate}}</small>
					</div>
				</a>
			</ng-container>
			<ng-template #extraTemplate>
				<ng-container [ngSwitch]="submenu.extra['id']">
					<ng-container *ngSwitchCase="'ACADEMY'">
						<article [class]="submenu.extra['className']" class="dropdown-submenu-link">
							<div class="media-body">
								<icon-academy *inClient></icon-academy>
								<small class="mb-4">{{submenu.subname | translate}}</small>
								<a ngbDropdownItem class="btn transition-3d-hover disabled btn-primary" [routerLink]="submenu.path">{{$any(submenu.extra['button']) | translate}}</a>
								<div class="position-absolute bottom-0 right-0 w-sm-35">
									<img src="assets/svg/support-man.svg" [alt]="'submenu.subname' | translate" loading="lazy" />
									<!-- <ng-container [ngComponentOutlet]="submenu.icon.component"></ng-container> -->
								</div>
							</div>
						</article>
					</ng-container>
					<ng-container *ngSwitchCase="'FOOTER'">
						<div class="footer">
							<div [class]="submenu.extra['className']">
								<small>{{submenu.subname | translate}}</small>
								<a ngbDropdownItem [routerLink]="submenu.path">{{submenu.name | translate}}</a>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-template>
		</ng-container>
	</div>
</div>

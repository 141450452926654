import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SidenavComponent } from "./sidenav.component";
import { LeftSidenav } from "./left/left.sidenav";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { NzIconModule } from "ng-zorro-antd/icon";
import { RightSidenav } from "./right/right.sidenav";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { AvatarModule } from "../avatar/avatar.module";
import { NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
import { InClientDirectiveModule } from "src/app/directives/in-client/in-client.module";
import { SafePipeModule } from "src/app/pipes/safe/safe.module";

@NgModule({
	declarations: [SidenavComponent, LeftSidenav, RightSidenav],
	imports: [CommonModule, TranslateModule, SafePipeModule, InClientDirectiveModule, RouterModule, NzIconModule, NzProgressModule, NgbCollapseModule, AvatarModule],
	exports: [SidenavComponent],
})
export class SidenavModule {}

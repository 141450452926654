import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { iUploadState } from "../interface/file.interface";

export function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
	return event.type === HttpEventType.Response;
}

export function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
	return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
}

export function calculateState(upload: iUploadState, event: HttpEvent<unknown>): iUploadState {
	if (isHttpProgressEvent(event)) {
		return {
			progress: event.total ? Math.round((100 * event.loaded) / event.total) : upload.progress,
			state: "IN_PROGRESS",
		};
	}
	if (isHttpResponse(event)) {
		return {
			progress: 100,
			state: event.status === 200 ? "DONE" : "FAILED",
			response: event.body,
		};
	}
	return upload;
}

<svg class="academy" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 636.29 140.57">
	<defs>
		<linearGradient [id]="academyGradientId" x1="-52.99" y1="-66.52" x2="295.09" y2="356.02" gradientUnits="userSpaceOnUse">
			<stop offset="0" stop-color="#1d88d8" />
			<stop offset="0.36" stop-color="#1e91ea" />
			<stop offset="0.86" stop-color="#209cff" />
			<stop offset="0.89" stop-color="#20a2ff" />
			<stop offset="0.93" stop-color="#20b2ff" />
			<stop offset="0.98" stop-color="#20ceff" />
			<stop offset="0.99" stop-color="#20d6ff" />
		</linearGradient>
	</defs>
	<path
		class="academy-0"
		d="M213.43,112.19c-8.48,8.33-17.41,11.6-28.27,11.6-21.28,0-39-12.79-39-38.83s17.7-38.84,39-38.84c10.41,0,18.45,3,26.48,10.87L200.19,69a22.29,22.29,0,0,0-14.73-5.81c-12.2,0-21.13,8.93-21.13,21.73,0,14,9.52,21.42,20.83,21.42,5.8,0,11.6-1.63,16.07-6.1Z"
		transform="translate(-21.86 -11.64)"
	/>
	<path
		class="academy-0"
		d="M272.2,48.21h17.41v73.35H272.5l-.9-10.71c-4.16,8.63-15.62,12.79-23.8,12.94-21.73.15-37.8-13.24-37.8-39,0-25.29,16.82-38.54,38.24-38.39,9.82,0,19.2,4.61,23.36,11.9Zm-44,36.6c0,14,9.67,22.32,21.72,22.32,28.57,0,28.57-44.49,0-44.49C237.83,62.64,228.16,70.82,228.16,84.81Z"
		transform="translate(-21.86 -11.64)"
	/>
	<path
		class="academy-0"
		d="M373.67,17.56v104h-17l-1.19-10.12c-5.66,8.78-14.73,11.76-23.66,11.76-21.58,0-37.79-14.29-37.79-38.39,0-25.29,15.92-38.39,37.34-38.39,7.74,0,19.79,4.17,24.11,11.76V17.56ZM312.22,84.81c0,12.8,9.22,21.57,20.83,21.57,11.46,0,21.28-8.33,21.28-21.57a20.93,20.93,0,0,0-21.28-21.43C321.44,63.38,312.22,71.57,312.22,84.81Z"
		transform="translate(-21.86 -11.64)"
	/>
	<path
		class="academy-0"
		d="M399.11,91.36C400.3,100.43,408.18,107,421,107c6.69,0,15.47-2.53,19.64-6.84l11.6,11.45c-7.73,8-20.38,11.9-31.54,11.9-25.29,0-40.32-15.62-40.32-39.13,0-22.31,15.17-38.39,39-38.39,24.55,0,39.88,15.18,37.05,45.39Zm39.87-15c-1.19-9.52-8.63-14.28-19-14.28-9.82,0-17.86,4.76-20.54,14.28Z"
		transform="translate(-21.86 -11.64)"
	/>
	<path
		class="academy-0"
		d="M510.25,121.56V82.28c0-9.67-5.06-18.45-15-18.45S479.6,72.61,479.6,82.28v39.28H461.45V48.06h16.81L479.6,57c3.87-7.44,12.35-10.12,19.34-10.12,8.78,0,17.56,3.57,21.72,13.68,6.55-10.41,15-13.39,24.56-13.39,20.83,0,31.09,12.8,31.09,34.82v39.58H558.16V82c0-9.67-4-17.85-13.84-17.85S528.4,72.61,528.4,82.28v39.28Z"
		transform="translate(-21.86 -11.64)"
	/>
	<path class="academy-0" d="M658.14,48.21l-44.78,104H593.42l13.69-31.84L577.8,48.21h21.12L610.08,79.9l7,20.68,7.59-20.24,13.39-32.13Z" transform="translate(-21.86 -11.64)" />
	<path
		class="academy-1"
		d="M56.29,109.93c-10.74,16.72-29.13,13.5-34.43,13.42C30.16,104,49.77,54.4,51.17,53c3.82-4,8.71-6.52,15.43-4.19a13.15,13.15,0,0,1,7.67,17.37C71.31,73.39,61.17,98.54,56.29,109.93Zm48-83.26c-5.86-11-13-14.53-21.35-15-9,0-14.11-1.34-27.19,29.59C57,39.53,64,36,70.67,38.27c3.45,1.2,11.3,4.25,16.73,18.2,3.21,7.29,19.87,46.4,22.71,53.49,6.12,11.24,18.29,13.26,30.37,13.42.4,0,3.52,0,4.06,0C134.2,96.3,107.81,33.3,104.28,26.67Z"
		transform="translate(-21.86 -11.64)"
		[style]="'fill: url(#' + academyGradientId + ')'"
	/>
</svg>

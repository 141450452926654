<figure class="wave-begin-last-promo">
	<svg x="0px" y="0px" viewBox="0 0 1920 140" preserveAspectRatio="none" class="wave-blp-svg">
		<path class="wave-blp-0 fill-primary" opacity=".3" d="M1607,81.8c0,0-274.6,48.2-560.1,19.9c-494.8-49.2-725.1,2.7-938.5,6.6C-203,114-339.2,85.6-339.2,85.6V9.4l1946.2,0V81.8z"></path>
		<path class="wave-blp-0 fill-primary" opacity=".3" d="M2033.1,80.8c0,0-274.6,48.2-560.1,19.9c-494.8-49.2-725.1,2.7-938.5,6.6C223.1,113,86.9,84.6,86.9,84.6V8.5h1946.2V80.8z"></path>
		<path class="wave-blp-1 fill-white" opacity="0" d="M1526.9,5.6c0,0-309,96.5-698.5,96.5C406.8,102.1,201.4,7,201.4,7V1.8l1332,0L1526.9,5.6z"></path>
		<path
			[class]="{ 'fill-blog': beginLastFill == 1, 'fill-white': beginLastFill == 0, 'fill-whiteblue': beginLastFill == 2, 'fill-bg-light': beginLastFill == 3 }"
			d="M2026.7,55.1c0,0-358.4,63.9-770,39c-523.7-31.8-712.6-8.6-938.5,6.6C-21.5,123.7-347.7,49-347.7,49l-8.5-51.3l2380.8,4.1L2026.7,55.1z"
		></path>
	</svg>
</figure>

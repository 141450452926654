import { DOCUMENT, isPlatformBrowser, isPlatformServer, isPlatformWorkerApp, isPlatformWorkerUi } from "@angular/common";
import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { makeStateKey, StateKey, TransferState } from "@angular/platform-browser";
import { REQUEST, RESPONSE } from "@nguniversal/express-engine/tokens";
import { Request, Response } from "express";

@Injectable({
	providedIn: "root",
})
export class PlatformService {
	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(DOCUMENT) private document: Document,
		@Optional() @Inject(REQUEST) private request: Request,
		@Optional() @Inject(RESPONSE) private response: Response,
		@Optional() @Inject("VERSION") public version: string,
		private Transfer: TransferState
	) {}

	get Document() {
		return this.document;
	}

	get Request() {
		return this.request;
	}

	get Response() {
		return this.response;
	}

	get isBrowser() {
		return isPlatformBrowser(this.platformId);
	}

	get isServer() {
		return isPlatformServer(this.platformId);
	}

	get isWorkerApp() {
		return isPlatformWorkerApp(this.platformId);
	}

	get isWorkerUi() {
		return isPlatformWorkerUi(this.platformId);
	}

	setState<T = any>(key: string, value: T) {
		if (!this.isServer) return;
		const stateKey = makeStateKey<T>(key);
		this.Transfer.set(stateKey, value);
	}

	getState<T = any>(key: string, defaultValue = null): T {
		const stateKey = makeStateKey<T>(key);
		return this.Transfer.get(stateKey, defaultValue);
	}
}

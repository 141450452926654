import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { SUBMENU_ACCOUNT } from "src/app/constants";
import { eAccountProgressStatus, eAccountStatus, iAccount } from "src/app/interface/data/customer.interface";
import { CustomerService } from "src/app/services/customer.service";
import { RouterPathsService } from "src/app/services/router-paths.service";
import { SessionService } from "src/app/services/session.service";
import { ThemeService } from "src/app/services/theme.service";

@Component({
	selector: "submenu-user",
	templateUrl: "./user.submenu.html",
	styleUrls: ["./user.submenu.scss"],
})
export class UserSubmenu implements AfterViewInit, OnDestroy {
	@Input() internalPage: boolean;
	/** Determina si la navegación se encuentra desplazada */
	@Input() isSticky: boolean;
	/** Determina si el submenu es visible */
	@Input() showUserMenu: boolean;
	/** Posibles estados de progreso de la cuenta */
	public AccountProgressStatus = eAccountProgressStatus;
	/** Posibles estados de la cuenta */
	public AccountStatus = eAccountStatus;
	/** Submenu del perfil activo */
	public submenuProfile = SUBMENU_ACCOUNT;
	/** tooltip de progreso de la cuenta */
	public tooltip: string;
	/** Datos del Usuario */
	public userData: iAccount;
	/** Nombre del usuario */
	public firstName: string;
	/** Nombre completo del usuario */
	public fullName: string;
	/** Porcentaje del estado del progreso de la cuenta*/
	public accountProgressStatusPercent: number;
	/** Determina si el programa de referidos esta activo */
	public isReferrals: boolean = false;
	/** Subscription Customer */
	private sCustomer: Subscription;
	/** Notificaciones Pendientes del Usuario */
	get notifications() {
		return this.Customer.notifications;
	}

	constructor(public Paths: RouterPathsService, public Theme: ThemeService, private Session: SessionService, private Router: Router, private Customer: CustomerService, private Translate: TranslateService) {
		this.setCustomer();
		this.setParams();
	}

	ngAfterViewInit() {}

	ngOnDestroy() {
		if (this.sCustomer) this.sCustomer.unsubscribe();
	}

	private setParams() {
		const { referral } = this.Session.site;
		this.isReferrals = referral?.enabled || false;
	}

	private setCustomer() {
		this.sCustomer = this.Customer.getCustomer().subscribe((customer) => {
			this.userData = customer;
			if (!this.userData) return;
			this.accountProgressStatusPercent = this.Customer.getProgressStatusPercent();
			this.tooltip = this.Translate.instant("progress.account.tooltip", { percent: this.accountProgressStatusPercent });
			if (this.userData.isLegalEntity) {
				this.fullName = this.userData.businessData.businessName;
				this.firstName = this.userData.businessData.businessName;
			}
			if (!this.userData.isLegalEntity) {
				this.fullName = `${this.userData.personalData.firstName} ${this.userData.personalData.lastName}`;
				this.firstName = this.userData.personalData.firstName;
			}
		});
	}

	/** Deslogea al usuario y lo envia a HOME */
	public logout() {
		this.Customer.logout();
		this.Router.navigateByUrl(this.Paths.GO.HOME);
	}
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AcademyIcon } from "./academy.icon";

@NgModule({
	declarations: [AcademyIcon],
	imports: [CommonModule],
	exports: [AcademyIcon],
})
export class AcademyIconModule {}

import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Config } from "src/app/config";
import { whatsappEndpoint } from "src/app/functions/whatsapp";

@Component({
	selector: "status-maintenance",
	templateUrl: "./maintenance.status.html",
	styleUrls: ["./maintenance.status.scss"],
})
export class MaintenanceStatus implements OnInit {
	public mailTo: string;
	public callUsPhone: string;
	public phoneLink: string;
	public whatsapp: string;
	public whatsappLink: string;
	public year = new Date().getFullYear();

	constructor(private Translate: TranslateService) {}

	ngOnInit(): void {
		const contact = Config.contact;
		this.callUsPhone = contact.phone;
		this.phoneLink = `tel:${this.getNumberLink(this.callUsPhone)}`;
		this.whatsapp = `${contact.prefix} ${contact.whatsapp}`;
		this.whatsappLink = whatsappEndpoint(this.getNumberLink(this.whatsapp), this.Translate.instant("whatsapp.message"));
	}

	getNumberLink(num: string) {
		return num.replace(/-/g, "");
	}
}

import { Component, Input, OnInit } from "@angular/core";
import { iLinkProps } from "src/app/interface/components/navbar.interface";

@Component({
	selector: "nav-dropdown",
	templateUrl: "./dropdown.navbar.html",
	styleUrls: ["./dropdown.navbar.scss"],
})
export class DropdownNavbar implements OnInit {
	@Input() data: iLinkProps;

	@Input() internalPage: boolean = false;

	@Input() isSticky: boolean = false;

	constructor() {}

	ngOnInit() {}
}

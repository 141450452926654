import { Injectable } from "@angular/core";
import { CookieKey, iCookieOptions } from "../interface/common.interface";
import { PlatformService } from "./platform.service";

@Injectable({
	providedIn: "root",
})
export class CookiesService {
	constructor(private Platform: PlatformService) {}

	public get(name: CookieKey) {
		let cookies = "";
		if (this.Platform.isBrowser) cookies = document.cookie || "";
		if (this.Platform.isServer) cookies = this.Platform.Request.headers.cookie || "";

		let value;
		cookies.split("; ").forEach((cookie) => {
			let [cKey, cValue] = cookie.split("=");
			if (cKey === name) value = cValue;
		});

		return value;
	}

	public set(key: CookieKey, value: string, options?: iCookieOptions) {
		let cookie = `${key}=${value}`;
		if (options) {
			cookie += `; path=${options.path || "/"}`;
			if (options.expires !== undefined) {
				let date = new Date();
				date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000);
				cookie += `; expires=${date.toUTCString()}`;
			}
			if (options.maxAge) cookie += `; max-age=${options.maxAge}`;
			if (options.domain) cookie += `; domain=${options.domain}`;
			if (options.secure) cookie += `; secure=${options.secure}`;
			if (options.sameSite) cookie += `; samesite=${options.sameSite}`;
		}
		if (this.Platform.isBrowser) document.cookie = cookie;
		if (this.Platform.isServer) this.Platform.Response.setHeader("Set-Cookie", cookie);
	}

	public remove(key: string) {
		if (this.Platform.isBrowser) document.cookie = `${key}=false;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		if (this.Platform.isServer) this.Platform.Response.setHeader("Set-Cookie", `${key}=false;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`);
	}
}

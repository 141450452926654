import { NgModule } from "@angular/core";
import { NoPreloading, RouterModule, Routes } from "@angular/router";
import { StatusGuard } from "./services/guard/status.guard";
import { RouterPathsService } from "./services/router-paths.service";

const routes: Routes = [
	{
		path: RouterPathsService.HOME,
		canActivate: [StatusGuard],
		pathMatch: "full",
		loadChildren: () => import("./screens/home/home.module").then((m) => m.HomeModule),
	},
	{
		path: RouterPathsService.ABOUT,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/about/about.module").then((m) => m.AboutModule),
	},
	{
		path: RouterPathsService.HOW_IT_WORKS,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/how-it-works/how-it-works.module").then((m) => m.HowItWorksModule),
	},
	{
		path: RouterPathsService.BENEFITS,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/benefits/benefits.module").then((m) => m.BenefitsModule),
	},
	{
		path: RouterPathsService.BLOG,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/blog/blog.module").then((m) => m.BlogModule),
	},
	{
		path: RouterPathsService.FAQ,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/faq/faq.module").then((m) => m.FaqModule),
	},
	{
		path: RouterPathsService.CHANNELS,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/channels/channels.module").then((m) => m.ChannelsModule),
	},
	{
		path: RouterPathsService.CONTACT,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/contact/contact.module").then((m) => m.ContactModule),
	},
	{
		path: RouterPathsService.PRIVACY_POLICIES,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/privacy/privacy.module").then((m) => m.PrivacyModule),
	},
	{
		path: RouterPathsService.TERMS_AND_CONDITIONS,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/terms-and-conditions/terms-and-conditions.module").then((m) => m.TermsAndConditionsModule),
	},
	{
		path: RouterPathsService.PROJECTS,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/projects/projects.module").then((m) => m.ProjectsModule),
	},
	{
		path: RouterPathsService.SIGNATURE_DOCUMENT,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/signature-document/signature-document.module").then((m) => m.SignatureDocumentModule),
	},
	{
		path: RouterPathsService.AUTH,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: RouterPathsService.REFERRER,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/referrer/referrer.module").then((m) => m.ReferrerModule),
	},
	{
		path: RouterPathsService.REFERRER_COMPANY,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/business/business.module").then((m) => m.BusinessModule),
	},
	{
		path: RouterPathsService.REFERRER_USER,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/user/user.module").then((m) => m.UserModule),
	},
	{
		path: RouterPathsService.REFERRALS_TERMS_AND_CONDITIONS,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/referrals-terms-and-conditions/referrals-terms-and-conditions.module").then((m) => m.ReferralsTermsAndConditionsModule),
	},
	{
		path: RouterPathsService.ACCOUNT,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/account/account.module").then((m) => m.AccountModule),
	},
	{
		path: RouterPathsService.CREATE_ACCOUNT,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/create-account/create-account.module").then((m) => m.CreateAccountModule),
	},
	{
		path: RouterPathsService.VALIDATE_IDENTITY,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/identity-validation/identity-validation.module").then((m) => m.IdentityValidationModule),
	},
	{
		path: RouterPathsService.SURVEY,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/survey/survey.module").then((m) => m.SurveyModule),
	},
	{
		path: RouterPathsService.CALCULATOR,
		canActivate: [StatusGuard],
		loadChildren: () => import("./screens/calculator/calculator.module").then((m) => m.CalculatorModule),
	},
	{
		path: "**",
		loadChildren: () => import("./screens/not-found/not-found.module").then((m) => m.NotFoundModule),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: "enabled",
			preloadingStrategy: NoPreloading,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
	selector: "button[btn], a[btn]",
})
export class BtnDirective {
	@Input() size: "xs" | "sm" | "md" | "lg";

	@Input() theme: string = "primary";

	@Input("aria-label") ariaLabel: string;

	@Input() set isLoading(value: boolean) {
		if (value) {
			this.Render.addClass(this.button, "disabled");
			const loading = this.Render.createElement("div");
			this.Render.addClass(loading, "spinner-border");
			this.Render.addClass(loading, "mr-3");
			this.Render.setAttribute(loading, "role", "status");
			this.Render.insertBefore(this.button, loading, this.button.firstChild);
		} else {
			if (this.button && this.button.childNodes.length > 0) {
				this.button.childNodes.forEach((child: HTMLElement) => {
					if (child instanceof Element && child.classList.contains("spinner-border")) this.Render.removeChild(this.button, child);
				});
				this.Render.removeClass(this.button, "disabled");
			}
		}
	}

	@Input() isTransition: boolean = true;

	@Input() pulse: boolean = false;

	@Input() set fullWidth(value: string | boolean) {
		if (value === "" || value === true) {
			this.Render.addClass(this.button, "btn-block");
		}
	}

	get button() {
		return this.el.nativeElement;
	}

	constructor(private Render: Renderer2, private el: ElementRef<HTMLButtonElement>) {}

	ngOnInit(): void {
		this.setBtn();
	}

	private setBtn() {
		this.Render.addClass(this.button, "btn");
		this.Render.addClass(this.button, `btn-${this.theme}`);
		if (this.size) this.Render.addClass(this.button, `btn-${this.size}`);
		if (this.fullWidth) this.Render.addClass(this.button, "btn-block");
		if (this.pulse) this.Render.addClass(this.button, "btn-pulse");
		if (this.isTransition && this.theme !== "link") this.Render.addClass(this.button, "transition-3d-hover");
		setTimeout(() => {
			this.Render.setAttribute(this.button, "aria-label", this.ariaLabel || this.button.textContent);
		});
	}
}

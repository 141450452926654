import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OnStickyDirective } from "./on-sticky.directive";

@NgModule({
	declarations: [OnStickyDirective],
	imports: [CommonModule],
	exports: [OnStickyDirective],
})
export class OnStickyDirectiveModule {}

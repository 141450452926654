<svg
	class="warning"
	[class]="className"
	[style]="styleName"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 120 120"
	style="enable-background: new 0 0 120 120"
	xml:space="preserve"
>
	<path class="icon-61-0 fill-gray-400" opacity=".4" d="M23.1,98.8l40.3-69.9c2.8-4.9,9.8-4.9,12.7,0l40.3,69.9c2.8,4.9-0.7,11-6.3,11H29.4 C23.8,109.7,20.3,103.6,23.1,98.8z" />
	<path
		class="icon-61-1 fill-white stroke-gray-400"
		stroke-width="3"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-miterlimit="10"
		d="M5.1,87.3l40.3-69.9c2.8-4.9,9.8-4.9,12.7,0l40.3,69.9c2.8,4.9-0.7,11-6.3,11H11.4C5.8,98.3,2.3,92.2,5.1,87.3z"
	/>
	<path class="icon-61-2 fill-primary" d="M51.7,38.9L51.7,38.9c-3.4,0-6,2.9-5.6,6.3l3.6,28.7c0.3,2.4,3.8,2.4,4.1,0l3.6-28.7 C57.7,42,55.2,38.9,51.7,38.9L51.7,38.9z" />
	<circle class="icon-61-2 fill-primary" cx="51.7" cy="81.6" r="2.4" />
</svg>

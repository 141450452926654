import { Component, Input } from "@angular/core";
import { iLinkProps } from "src/app/interface/components/navbar.interface";
import { CustomerService } from "src/app/services/customer.service";

@Component({
	selector: "nav-link",
	templateUrl: "./link.navbar.html",
	styleUrls: ["./link.navbar.scss"],
})
export class LinkNavbar {
	/** Datos de links de tipo Link */
	@Input() data: iLinkProps;
	/** Determina si el navbar esta en modo `internal` */
	@Input() internalPage: boolean = false;
	/** Determina si el navbar esta en modo `sticky`*/
	@Input() isSticky: boolean = false;
	/** Determina si el usuario esta autenticado */
	get isAuth() {
		return this.Customer.isAuth;
	}

	constructor(private Customer: CustomerService) {}
}

<div class="sidenav-content left" [ngClass]="{'active': active}">
	<div class="sidenav-content-header">
		<button aria-label="close menu" class="btn-sidenav-close" (mouseup)="handleClose()">
			<i class="fas fa-times"></i>
		</button>
		<div class="title" [innerHTML]="'menu.sidenav.header.legend' | translate | safe"></div>
	</div>

	<ul class="sidenav-content-main">
		<li *ngFor="let item of items" class="list-sidenav">
			<a [routerLink]="item.path" class="list-sidenav-link" routerLinkActive="active" (click)="handleClose()">
				<div class="list-sidenav-icon">
					<i *inClient nz-icon [nzType]="item.icon" [nzTheme]="'outline'"></i>
				</div>
				<div class="list-sidenav-content">
					<div class="list-sidenav-title">{{item.title | translate}}</div>
				</div>
			</a>
		</li>
	</ul>
</div>

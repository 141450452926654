import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "icon-logo",
	templateUrl: "./logo.icon.html",
	styleUrls: ["./logo.icon.scss"],
})
export class LogoIcon implements OnInit {
	@Input() theme: "white" | "custom" = "custom";

	constructor() {}

	ngOnInit(): void {}
}

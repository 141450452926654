import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SIDENAV_LEFT } from "src/app/constants";

@Component({
	selector: "sidenav-left",
	templateUrl: "./left.sidenav.html",
	styleUrls: ["./left.sidenav.scss"],
})
export class LeftSidenav implements OnInit {
	@Input() active: boolean;

	@Output() closeActive = new EventEmitter();

	public items = SIDENAV_LEFT;

	constructor() {}

	ngOnInit(): void {}

	public handleClose() {
		this.active = false;
		this.closeActive.emit();
	}
}

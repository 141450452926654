<div class="maintenance-container">
	<header>
		<div class="header-container">
			<icon-logo></icon-logo>
			<div class="info-number">
				<a [href]="phoneLink | safe: 5" target="_blank" rel="noopener noreferrer" class="number-link">
					<i class="fas fa-phone mr-2"></i>
					<span>{{callUsPhone}}</span>
				</a>
				<div class="separator"></div>
				<a [href]="whatsappLink | safe: 5" target="_blank" rel="noopener noreferrer" class="number-link">
					<i class="fab fa-whatsapp mr-2"></i>
					<span>{{whatsapp}}</span>
				</a>
			</div>
		</div>
	</header>

	<main class="container">
		<asset-building></asset-building>

		<div class="text-center">
			<h2 class="font-weight-normal mb-2">{{"status.maintenance.header" | translate}}</h2>
			<p class="mb-5">{{"status.maintenance.body" | translate}}</p>
		</div>

		<div class="store-container">
			<button-store class="mx-1" type="apple"></button-store>
			<button-store class="mx-1" type="google"></button-store>
		</div>
	</main>

	<footer>
		<div class="footer-container">
			<p class="copyright mb-0">{{"footer.copyright" | translate: { year } }}</p>
			<social-media></social-media>
		</div>
	</footer>
</div>

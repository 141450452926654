import { iContact, iLangs, iLocation, iMedias, iPartners, iSitesCountries, iSocialsMedia, iStores } from "../interface/config.interface";
import { validateNationalId, validateTaxId } from "./functions";
import INTEGRATIONS from "./integrations";

export class Config {
	/** Codigo de Pais */
	static countryCode: string = "ar";
	/** Dominio de prueba */
	static host: string = "crowdium.net.ar";
	/** Keys de integraciones del sitio */
	static integration = INTEGRATIONS;
	/** Los lenguages que se van a utilizar en el sitio */
	static langs: iLangs = [
		{ id: "es", locale: "es-AR", flag: "es", isDefault: true, formatDate: { short: "dd/MM/yyyy HH:mm", shortDate: "dd/MM/yyyy" } },
		{ id: "en", locale: "en-US", flag: "us" },
	];
	/** Email de Soporte */
	static support: string = "soporte@crowdium.com";
	/** Ubicacion de las oficinas de la region del sitio */
	static location: iLocation = {
		address: "Quito 2618, Piso 1",
		city: "Beccar",
		postalCode: "1609",
		province: "Buenos Aires",
		country: "Argentina",
		countryCode: "ar",
		map: {
			lat: -34.476367,
			lng: -58.564645,
			markerLat: -34.4752553,
			markerLng: -58.573712,
		},
	};
	/** Datos de contacto */
	static contact: iContact = {
		email: "hola@crowdium.com.ar",
		phone: "0800-220-2769",
		whatsapp: "11 2240-4746",
		prefix: "+54",
	};
	/** Redes Sociales */
	static socialMedia: iSocialsMedia = [
		{ name: "fa-facebook-f", legend: "Facebook de Crowdium Argentina", url: "https://www.facebook.com/CrowdiumARS" },
		{ name: "fa-instagram", legend: "Instagram de Crowdium Argentina", url: "https://www.instagram.com/crowdiumars" },
		{ name: "fa-twitter", legend: "Twitter de Crowdium Argentina", url: "https://twitter.com/crowdiumARS" },
		{ name: "fa-linkedin-in", legend: "Linkedin de Crowdium Argentina", url: "https://ar.linkedin.com/company/crowdium" },
		{ name: "fa-youtube", legend: "Youtube de Crowdium Argentina", url: "https://www.youtube.com/@crowdium" },
		/* { name: 'fa-tiktok', legend: "Tiktok de Crowdium Argentina", url: "https://www.tiktok.com/@crowdium" } */
	];
	/** App Store */
	static stores: iStores = [
		{ id: "google", url: "", available: false },
		{ id: "apple", url: "", available: false },
	];
	/** Sitios de otras regiones */
	static countries: iSitesCountries = [
		{ id: "ar", url: "https://crowdium.com.ar/", disabled: false },
		{ id: "mx", url: "https://crowdium.com.mx/", disabled: true },
		{ id: "cl", url: "https://crowdium.com.cl/", disabled: true },
		{ id: "uy", url: "https://crowdium.com.uy/", disabled: true },
	];
	/** Videos */
	static media: iMedias = [
		{ id: "home.video", type: "video", description: "home.main.video.description", url: "https://www.youtube.com/embed/EFBkKhu7FvY?autoplay=1", active: true },
		{ id: "howItWork.video", type: "video", description: "", url: "https://www.youtube.com/embed/EFBkKhu7FvY", active: true },
		{ id: "channel.video", type: "video", description: "", url: "https://www.youtube.com/embed/EFBkKhu7FvY", active: true },
		{ id: "cuponstar.video", type: "iframe", description: "", url: "https://www.youtube.com/embed/kSqZzX9bgqw?autoplay=1", active: false },
	];
	/** Socios */
	static partners: iPartners = [
		{ name: "Tizado", src: "../assets/img/partners/tizado.webp" },
		{ name: "La City", src: "../assets/img/partners/lacity.webp" },
		{ name: "Bullrich", src: "../assets/img/partners/bullrich_palermo.webp" },
		{ name: "Interwin", src: "../assets/img/partners/interwin.webp" },
		{ name: "Covello", src: "../assets/img/partners/covello.webp" },
		{ name: "Izrastzoff", src: "../assets/img/partners/izrastzoff.webp" },
	];
	/** Fecha de Creación de la Empresa */
	static birthdayBusiness = new Date(2015, 9, 31);
	/** Funciones de la configuración */
	static functions = {
		validateTaxId,
		validateNationalId,
	};
}

import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
	name: "safe",
})
export class SafePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: string, type?: SecurityContext): any {
		switch (type) {
			case SecurityContext.RESOURCE_URL:
				return this.sanitizer.bypassSecurityTrustResourceUrl(value);
			case SecurityContext.SCRIPT:
				return this.sanitizer.bypassSecurityTrustScript(value);
			case SecurityContext.STYLE:
				return this.sanitizer.bypassSecurityTrustStyle(value);
			case SecurityContext.URL:
				return this.sanitizer.bypassSecurityTrustUrl(value);
			case SecurityContext.HTML:
			default:
				return this.sanitizer.bypassSecurityTrustHtml(value);
		}
	}
}

import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { SIDENAV_RIGHT } from "src/app/constants";
import { iAccount } from "src/app/interface/data/customer.interface";
import { CustomerService } from "src/app/services/customer.service";
import { RouterPathsService } from "src/app/services/router-paths.service";
import { ThemeService } from "src/app/services/theme.service";

@Component({
	selector: "sidenav-right",
	templateUrl: "./right.sidenav.html",
	styleUrls: ["./right.sidenav.scss"],
})
export class RightSidenav implements OnDestroy {
	@Input() active: boolean;

	@Output() closeActive = new EventEmitter();

	public userData: iAccount;

	public name: string;

	public accountProgressStatusPercent: number;

	public notifications: number = 0;

	public items = SIDENAV_RIGHT;

	private sCustomer: Subscription;

	constructor(public Paths: RouterPathsService, public Theme: ThemeService, private Customer: CustomerService) {
		this.sCustomer = this.Customer.getCustomer().subscribe((customer) => {
			this.userData = customer;
			if (!this.userData) return;

			this.name = this.userData.isLegalEntity ? this.userData.businessData.businessName : this.userData.personalData.firstName;
			this.accountProgressStatusPercent = this.Customer.getProgressStatusPercent();
			this.notifications = this.Customer.notifications;
		});
	}

	ngOnDestroy(): void {
		if (this.sCustomer) this.sCustomer.unsubscribe();
	}

	public handleClick(id: string) {
		switch (id) {
			case "logout":
				this.Customer.logout();
				this.handleClose();
				break;
		}
	}

	public handleClose() {
		this.active = false;
		this.closeActive.emit();
	}
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchPageIcon } from "./search-page.icon";

@NgModule({
	declarations: [SearchPageIcon],
	imports: [CommonModule],
	exports: [SearchPageIcon],
})
export class SearchPageIconModule {}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WaveBeginLastComponent } from "./wave-begin-last.component";

@NgModule({
	declarations: [WaveBeginLastComponent],
	imports: [CommonModule],
	exports: [WaveBeginLastComponent],
})
export class WaveBeginLastModule {}

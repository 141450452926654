import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WarningIcon } from "./warning.icon";

@NgModule({
	declarations: [WarningIcon],
	imports: [CommonModule],
	exports: [WarningIcon],
})
export class WarningIconModule {}

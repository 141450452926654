import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class RouterPathsService {
	/** `Crowdium Academy` -> /academia */
	static ACADEMY = "academia";
	/** `Acerca de Crowdium` -> /acerca-de-crowdium */
	static ABOUT = "acerca-de-crowdium";
	/** `Mi cuenta` -> /mi-cuenta */
	static ACCOUNT = "mi-cuenta";
	/** `Mis Inversiones` -> /mi-cuenta/mis-inversiones */
	static ACCOUNT_INVESTMENTS = "mis-inversiones";
	/** `Mis Referidos` -> /mi-cuenta/mis-referidos */
	static ACCOUNT_REFERRALS = "mis-referidos";
	/** `Mis Notificaciones` -> /mi-cuenta/mis-notificaciones */
	static ACCOUNT_NOTIFICATIONS = "mis-notificaciones";
	/** `Mis Beneficios` -> /mi-cuenta/mis-beneficios */
	static ACCOUNT_BENEFITS = "mis-beneficios";
	/** `Mi Perfil` -> /mi-cuenta/perfil */
	static ACCOUNT_PROFILE = "mi-perfil";
	/** `Documentos` -> /mi-cuenta/perfil/documentos */
	static ACCOUNT_PROFILE_DOCUMENTS = "documentos";
	/** `Datos Bancarios` -> /mi-cuenta/perfil/datos-bancarios */
	static ACCOUNT_PROFILE_DATABANK = "datos-bancarios";
	/** `Seguridad` -> /mi-cuenta/perfil/seguridad */
	static ACCOUNT_PROFILE_SECURITY = "seguridad";
	/** `Preferencias` -> /mi-cuenta/perfil/preferencias */
	static ACCOUNT_PROFILE_PREFERENCES = "preferencias";
	/** `Validar identidad con Mati` -> /mi-cuenta/validar-identidad */
	static VALIDATE_IDENTITY = "validar-identidad";
	/** `Benficios` -> /nuestros-beneficios */
	static BENEFITS = "nuestros-beneficios";
	/** `Blog` -> /blog */
	static BLOG = "noticias";
	/** `Post del blog` -> /blog/:slug */
	static BLOG_SLUG = ":slug";
	/** `Canales` -> /canales */
	static CHANNELS = "canales";
	/** `Contacto` -> /contacto */
	static CONTACT = "contacto";
	/** `Contrato de Adhesión` */
	static SIGNATURE_DOCUMENT = "firmar-documento";
	/** `Creacion de Cuenta` -> /crear-cuenta */
	static CREATE_ACCOUNT = "crear-cuenta";
	/** `Preguntas Frecuentes` -> /faq */
	static FAQ = "faq";
	/** `Home` -> / */
	static HOME = "";
	/** `Como funciona Crowdium` -> /como-funciona */
	static HOW_IT_WORKS = "como-funciona";
	/** `Login` -> /login */
	static AUTH = "login";
	/** `SignUp` -> /login/registro */
	static AUTH_REGISTER = "registro";
	/** `Recuperar Contraseña` -> /login/recuperar-clave */
	static AUTH_RECOVERY_PASSWORD = "recuperar-clave";
	/** `Reiniciar Contraseña` -> /login/reiniciar-contraseña */
	static AUTH_RESET_PASSWORD = "reiniciar-clave";
	/** `Politicas de Privacidad` -> /politicas-de-privacidad */
	static PRIVACY_POLICIES = "politicas-de-privacidad";
	/** `Inversiones` -> /inversiones */
	static PROJECTS = "proyectos-de-inversion";
	/** `Proyecto de Inversion` -> /inversiones/:proyecto */
	static PROJECTS_SLUG = ":project";
	/** `Proyecto de Inversion` -> /inversiones/v/:proyecto */
	static PROJECTS_SLUG_V = "v/:project";
	/** `Encuestas` -> /votacion/:uid */
	static SURVEY = "votacion";
	/** `Invertir en un proyecto` -> /inversiones/invertir/:proyecto */
	static PROJECTS_TO_INVEST = "invertir/:project";
	/** `Terminos y Condiciones` -> /terminos-y-condiciones */
	static TERMS_AND_CONDITIONS = "terminos-y-condiciones";
	/** `Referido por` -> /c */
	static REFERRER = "c";
	/** `Terminos y Condiciones de Referidos` */
	static REFERRALS_TERMS_AND_CONDITIONS = "referidos/terminos-y-condiciones";
	/** Empresa Referido */
	static REFERRER_COMPANY = "b";
	/** Usuario Referido */
	static REFERRER_USER = "u";
	/** `Calculadora` -> /calculadora */
	static CALCULATOR = "calculadora";
	/** `Calculadora del Proyecto` -> /calculadora/:project */
	static CALCULATOR_PROJECT = "calculadora/:project";

	public GO = {
		/** Crowdium Academy */
		ACADEMY: "/academia",
		/** Acerca de Crowdium */
		ABOUT: "/acerca-de-crowdium",
		/** Mi Cuenta  */
		ACCOUNT: "/mi-cuenta",
		/** Mis Inversiones */
		ACCOUNT_INVESTMENTS: "/mi-cuenta/mis-inversiones",
		/** Mis Referidos */
		ACCOUNT_REFERRED: "/mi-cuenta/mis-referidos",
		/** Mis Notificaciones */
		ACCOUNT_NOTIFICATIONS: "/mi-cuenta/mis-notificaciones",
		/** Mis Beneficios */
		ACCOUNT_BENEFITS: "/mi-cuenta/mis-beneficios",
		/** Mi Perfil */
		ACCOUNT_PROFILE: "/mi-cuenta/mi-perfil",
		/** Documentos */
		ACCOUNT_PROFILE_DOCUMENTS: "/mi-cuenta/mi-perfil/documentos",
		/** Datos Bancarios */
		ACCOUNT_PROFILE_DATABANK: "/mi-cuenta/mi-perfil/datos-bancarios",
		/** Seguridad */
		ACCOUNT_PROFILE_SECURITY: "/mi-cuenta/mi-perfil/seguridad",
		/** Preferencias */
		ACCOUNT_PROFILE_PREFERENCES: "/mi-cuenta/mi-perfil/preferencias",
		/** Validar Identidad */
		VALIDATE_IDENTITY: (hash: string) => `/validar-identidad/${hash}`,
		/** Beneficios */
		BENEFITS: "/nuestros-beneficios",
		/** Blog */
		BLOG: "/noticias",
		/** Post de blog */
		BLOG_SLUG: (slug: string) => `/noticias/${slug}`,
		/** Canales */
		CHANNELS: "/canales",
		/** Contacto */
		CONTACT: "/contacto",
		/** Contrato de Adhesión */
		SIGNATURE_DOCUMENT: (type: string, uid: string) => `/firmar-documento/${type}/${uid}`,
		/** Creacion de Cuenta */
		CREATE_ACCOUNT: "/crear-cuenta",
		/** Preguntas Frecuentes */
		FAQ: "/faq",
		/** Banderas */
		FLAGS: (flag: string, squared: boolean = true) => (squared ? `assets/flags/4x3/${flag.toLowerCase()}.svg` : `assets/flags/1x1/${flag.toLowerCase()}.svg`),
		/** Home */
		HOME: "/",
		/** Como Funciona */
		HOW_IT_WORKS: "/como-funciona",
		/** Login */
		AUTH: "/login",
		/** SignUp */
		AUTH_REGISTER: "/login/registro",
		/** Recuperar Contraseña */
		AUTH_RECOVERY_PASSWORD: "/login/recuperar-clave",
		/** Reiniciar Contraseña */
		AUTH_RESET_PASSWORD: "/login/reiniciar-clave",
		/** Politicas de Privacidad */
		PRIVACY_POLICIES: "/politicas-de-privacidad",
		/** Inversiones */
		PROJECTS: "/proyectos-de-inversion",
		/** Ficha de Proyecto */
		PROJECTS_SLUG: (project: string) => `/proyectos-de-inversion/${project}`,
		/** Ficha de Proyecto */
		PROJECTS_SLUG_VERSION: (project: string) => `/proyectos-de-inversion/v/${project}`,
		/** Inversion sobre el Proyecto */
		PROJECTS_TO_INVEST: (project: string) => `/proyectos-de-inversion/invertir/${project}`,
		/** Terminos y Condiciones */
		TERMS_AND_CONDITIONS: "/terminos-y-condiciones",
		/** Referido por */
		REFERRER: (uid: string) => `/c/${uid}`,
		/** Terminos y Condiciones de Referidos */
		REFERRALS_TERMS_AND_CONDITIONS: "/referidos/terminos-y-condiciones",
		/** Calculadora */
		CALCULATOR: "/calculadora",
		/** Calculadora del Proyecto */
		CALCULATOR_PROJECT: (slug: string) => `/calculadora/${slug}`,
	};
}

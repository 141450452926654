<footer class="footer-container container">
	<div class="footer-contact-us">
		<div class="row">
			<div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
				<div class="phone-link">
					<a [href]="phoneLink" target="_blank" rel="noopener noreferrer" class="media">
						<span class="btn btn-icon btn-soft-primary rounded-circle mr-3">
							<i class="fas fa-phone"></i>
						</span>
						<div class="media-body">
							<h4 class="text-secondary font-weight-normal mb-0">
								<span class="link-title">{{ "footer.rotativeslines.title" | translate }}</span>
								<span class="link-text">{{ "footer.timeContact.text" | translate }}</span>
							</h4>
							<span class="link-number d-block">{{ rotativeLines }}</span>
						</div>
					</a>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
				<div class="whatsapp-link">
					<a [href]="whatsappLink" target="_blank" rel="noopener noreferrer" class="media">
						<span class="btn btn-icon btn-soft-primary rounded-circle mr-3">
							<i class="fab fa-whatsapp fa-lg"></i>
						</span>
						<div class="media-body">
							<h4 class="text-secondary font-weight-normal mb-0">
								<span class="link-title">{{ "footer.whatsapp.text" | translate }}</span>
								<span class="link-text">{{ "footer.timeContact.text" | translate }}</span>
							</h4>
							<span class="link-number d-block">{{ whatsapp }}</span>
						</div>
					</a>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="follow-us">
					<h4 class="text-secondary">{{ "footer.followus" | translate }}</h4>
					<social-media></social-media>
				</div>
			</div>
		</div>
	</div>
	<hr class="my-0" />
	<div class="footer-links">
		<div class="row align-items-start">
			<div class="col-lg-3 col-md-4 mb-3 mb-md-0">
				<div class="links-countries">
					<a class="d-block mb-4" [routerLink]="Paths.GO.HOME">
						<span class="sr-only">{{ siteName }}</span>
						<icon-logo *inClient></icon-logo>
					</a>
					<ng-container *ngFor="let country of countries">
						<a *ngIf="!country.disabled" class="country-link font-weight-normal mb-2" [href]="country.url" target="_blank" rel="noopener noreferrer">
							<img class="country-flag" width="20" height="15" [src]="getFlag(country.id)" [alt]="country.id + ' Flag'" loading="lazy" />
							<span>{{ "footer.countries." + country.id | translate }}</span>
						</a>
						<span *ngIf="country.disabled" class="country-link font-weight-normal mb-2 disabled">
							<img class="country-flag" width="20" height="15" [src]="getFlag(country.id)" [alt]="country.id + ' Flag'" loading="lazy" />
							<span>{{ "footer.countries." + country.id | translate }}</span>
						</span>
					</ng-container>
				</div>
			</div>
			<div class="col-lg-2 col-md-4 col-sm-6 mb-3 mb-sm-0">
				<div class="links-access">
					<h6 class="font-weight-semi-bold">{{ "footer.linksaccess" | translate }}</h6>
					<ul class="list-group list-group-flush list-group-borderless mb-0">
						<li *ngFor="let item of linksAccess">
							<a class="list-group-item list-group-item-action footer-link" [routerLink]="item.path">{{ item.name | translate }}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-lg-3 col-md-4 col-sm-6">
				<div class="links-about">
					<h6 class="font-weight-semi-bold">{{ "footer.linksabout" | translate }}</h6>
					<ul class="list-group list-group-flush list-group-borderless mb-0">
						<li *ngFor="let item of linksAbout">
							<a class="list-group-item list-group-item-action footer-link" [routerLink]="item.path">{{ item.name | translate }}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="links-store">
					<h6 class="font-weight-semi-bold mb-4">{{ "footer.linksapps" | translate }}</h6>
					<div class="text-center">
						<button-store class="mx-1" type="apple"></button-store>
						<button-store class="mx-1" type="google"></button-store>
						<div id="pulsetic-badge"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr class="my-0" />
	<div class="footer-legal">
		<p class="copyright text-muted mb-0">{{ "footer.copyright" | translate : { year } }}</p>
		<ul class="terms-privacy list-inline mb-0">
			<li class="list-inline-item">
				<a class="terms-link link-muted" [routerLink]="Paths.GO.TERMS_AND_CONDITIONS">
					<span>{{ "menu.termsAndConditions" | translate }}</span>
				</a>
			</li>
			<li class="list-inline-item">
				<a class="terms-link link-muted" [routerLink]="Paths.GO.PRIVACY_POLICIES">
					<span>{{ "menu.privacityPolicies" | translate }}</span>
				</a>
			</li>
		</ul>
	</div>
	<div class="footer-disclaimer">
		<strong>{{ "footer.capital.disclaimer" | translate }}</strong>
		<span>{{ "footer.disclaimer" | translate }}</span>
	</div>
	<div class="footer-fiscal-authority d-flex justify-content-center pb-5">
		<a href="http://qr.afip.gob.ar/?qr=EDDvUuPlsenoSD2dY8nvvQ,," target="_F960AFIPInfo">
			<img src="../../../assets/img/DATAWEB.jpeg" width="73" height="100" loading="lazy" alt="Data Web" />
		</a>
	</div>
</footer>

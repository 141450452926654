import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "loader",
	templateUrl: "./loader.component.html",
	styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
	/** Define el color del loader */
	@Input() color: string;
	/** Define si cubrira toda la pantalla */
	@Input() fullscreen: boolean = true;
	/** Determina el minHeight en VH */
	@Input() minHeight: number | string = "250px";
	/** Determina si muestra el label */
	@Input() showLabel: boolean = true;

	constructor() {}

	ngOnInit() {}
}

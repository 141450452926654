import { Component, OnInit } from "@angular/core";
import { Config } from "src/app/config";

@Component({
	selector: "status-offline",
	templateUrl: "./offline.status.html",
	styleUrls: ["./offline.status.scss"],
})
export class OfflineStatus implements OnInit {
	public mailSupport = `mailto:${Config.support}`;

	constructor() {}

	ngOnInit(): void {}
}

<!-- Language -->
<li class="list-inline-item mr-0 set-language" [class.internal]="internalPage" ngbDropdown placement="bottom-right">
	<a ngbDropdownToggle>
		<i class="fas fa-globe-americas"></i>
	</a>

	<div ngbDropdownMenu>
		<ng-container *ngFor="let language of languages">
			<button ngbDropdownItem [class.active]="language.id === currentLang.id" [attr.aria-label]="'lang.' + language.id | translate" (click)="updateLanguage(language)">
				<img class="dropdown-item-icon" [src]="getIcon(language.flag)" [alt]="'lang.' + language.id | translate" loading="lazy" />
				<span>{{'lang.' + language.id | translate}}</span>
			</button>
		</ng-container>
	</div>
</li>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { SessionService } from "src/app/services/session.service";

@Component({
	selector: "wave-begin-last",
	templateUrl: "./wave-begin-last.component.html",
	styleUrls: ["./wave-begin-last.component.scss"],
})
export class WaveBeginLastComponent implements OnInit, OnDestroy {
	public beginLastFill: number | boolean = false;

	private sInternalPage: Subscription;

	constructor(private Session: SessionService) {}

	ngOnInit(): void {
		this.sInternalPage = this.Session.getInternalPage().subscribe((internalPage) => {
			this.beginLastFill = internalPage.beginFill;
		});
	}

	ngOnDestroy(): void {
		if (this.sInternalPage) this.sInternalPage.unsubscribe();
	}
}

import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { SessionService } from "../session.service";

@Injectable()
export class ReCaptchaInterceptor implements HttpInterceptor {
	constructor(private Session: SessionService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this.Session.reCaptchaToken) {
			request = request.clone({
				setHeaders: {
					"g-recaptcha-response": this.Session.reCaptchaToken,
				},
			});
		}
		return next.handle(request);
	}
}

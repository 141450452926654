import { ChangeDetectorRef, Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { Subscription } from "rxjs";
import { eIdentityValidationStatus } from "./interface/data/customer.interface";
import { eDBRealtime } from "./interface/data/realtime.interface";
import { eSiteStatus } from "./interface/data/site.interface";
import { eResponse } from "./interface/response.interface";
import { ApiAccountService } from "./services/api/api-account.service";
import { CookiesService } from "./services/cookies.service";
import { CrmCoreService } from "./services/crm-core.service";
import { CustomerService } from "./services/customer.service";
import { DbRealtimeService } from "./services/db-realtime.service";
import { PlatformService } from "./services/platform.service";
import { SessionService } from "./services/session.service";
import { CookieKey } from "./interface/common.interface";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
	/** Muestra un mensaje de que se perdio la conexion a internet */
	@HostListener("window:offline", ["$event"])
	handleOffline = () => this.Message.error(this.Translate.instant("offline"));
	/** Muestra un mensaje de que se recupero la conexion a internet */
	@HostListener("window:online", ["$event"])
	handleOnline = () => this.Message.success(this.Translate.instant("online"));
	/** Decta el momento en que se cierra la pestaña  */
	@HostListener("window:beforeunload", ["$event"])
	handleBeforeUnload = () => this.DBRealtime.destroySesion();
	/** Determina si el sitio esta cargando */
	public isLoading = true;
	/** Posibles estados del sitio */
	public eSiteStatus = eSiteStatus;
	/** Determina si el navbar es visible */
	public isNavbarVisible: boolean;
	/** Determina si el footer es visible */
	public isFooterVisible: boolean;
	/** Determina si el beginnow es visible */
	public isBeginVisible: boolean;
	/** Contiene la subscripción del isSearching */
	private sSearching: Subscription;
	/** Estado del Sitio */
	get status() {
		return this.Session.status || eSiteStatus.OFFLINE;
	}
	/** Determina si se esta ejecutando desde el navegador */
	get isBrowser() {
		return this.Platform.isBrowser;
	}

	constructor(
		private Platform: PlatformService,
		private CRMCore: CrmCoreService,
		private Session: SessionService,
		private Customer: CustomerService,
		private DBRealtime: DbRealtimeService,
		private APIAccount: ApiAccountService,
		private Route: ActivatedRoute,
		private Cookies: CookiesService,
		private Translate: TranslateService,
		private Message: NzMessageService,
		private CDR: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.setIsLoading();
		this.setInternalPage();
		this.setDBRealtime();
		this.setMetadata();
	}

	/** Obtiene el resultado de la carga de los datos */
	private setIsLoading() {
		this.CRMCore.isLoading.subscribe((isLoading) => {
			this.isLoading = isLoading;
			if (isLoading) {
				if (this.sSearching) this.sSearching.unsubscribe();
				return;
			}
			this.sSearching = this.Session.isSearching.subscribe((isSearching) => {
				this.isLoading = isSearching;
			});
		});
	}
	/** Obtiene los datos internos de las pestañas */
	private setInternalPage() {
		this.Session.getInternalPage().subscribe((internalPage) => {
			if (!internalPage) return;
			this.isNavbarVisible = internalPage.renderNavbar && (this.status === eSiteStatus.ACTIVE || this.Customer.isAuth);
			this.isFooterVisible = internalPage.renderFooter && (this.status === eSiteStatus.ACTIVE || this.Customer.isAuth);
			this.isBeginVisible = internalPage.renderBegin && (this.status === eSiteStatus.ACTIVE || this.Customer.isAuth);
		});
	}
	/** Obtiene los datos de la base de dato en tiempo real */
	private setDBRealtime() {
		if (this.Platform.isServer) return;
		this.DBRealtime.getDB().subscribe({
			next: (db) => {
				if (!db) return;
				const { UPDATE_MATI_STATUS, UPDATE_NOTIFICATIONS, UPDATE_USER_DATA } = db;
				if (UPDATE_MATI_STATUS !== undefined) {
					const customer = this.Customer.data;
					if (customer.identityValidation && customer.identityValidation.status !== UPDATE_MATI_STATUS) {
						customer.identityValidation.status = UPDATE_MATI_STATUS;
						this.Customer.setUserData(customer);
						switch (UPDATE_MATI_STATUS) {
							case eIdentityValidationStatus.VERIFIED:
								this.Message.success(this.Translate.instant(eResponse.IDENTITY_VALIDATION_VERIFIED));
								break;
							case eIdentityValidationStatus.PENDING:
							case eIdentityValidationStatus.REVIEW_NEEDED:
								this.Message.info(this.Translate.instant(eResponse.IDENTITY_VALIDATION_PENDING));
								break;
							case eIdentityValidationStatus.REJECTED:
								this.Message.error(this.Translate.instant(eResponse.IDENTITY_VALIDATION_REJECTED));
								break;
						}
						this.CDR.detectChanges();
					}
				}
				if (UPDATE_NOTIFICATIONS !== undefined) this.Customer.changeNotifications(UPDATE_NOTIFICATIONS);
				if (UPDATE_USER_DATA !== undefined && UPDATE_USER_DATA) {
					this.APIAccount.getUserData().subscribe((res) => this.DBRealtime.setKey(eDBRealtime.UPDATE_USER_DATA, false));
				}
			},
			error: (error) => console.error("ERROR Realtime", error),
		});
	}
	/** Obtiene la metadata de los query params */
	private setMetadata() {
		if (this.Platform.isServer) return;
		this.Route.queryParams.subscribe((params) => {
			if (!params) return;
			const utms = ["utm_campaign", "utm_medium", "utm_source"];
			utms.forEach((utm) => {
				if (params[utm]) {
					this.Cookies.set(utm as CookieKey, params[utm], { expires: 30, secure: "secure", sameSite: "strict" });
				}
			});
		});
	}
}

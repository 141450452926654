import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "icon-warning",
	templateUrl: "./warning.icon.html",
	styleUrls: ["./warning.icon.scss"],
})
export class WarningIcon implements OnInit {
	@Input() className: string;

	@Input() styleName: string;

	constructor() {}

	ngOnInit(): void {}
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AvatarComponent } from "./avatar.component";
import { OnLoadImageDirectiveModule } from "src/app/directives/on-load-image/on-load-image.module";

@NgModule({
	declarations: [AvatarComponent],
	imports: [CommonModule, OnLoadImageDirectiveModule],
	exports: [AvatarComponent],
})
export class AvatarModule {}

import { Injectable } from "@angular/core";
import { Observable, catchError, of, tap, throwError } from "rxjs";
import { iFormChannel, iFormContact } from "src/app/interface/api/sites.interface";
import { iResponse } from "src/app/interface/response.interface";
import { RouterEndpointsService } from "./router-endpoints.service";
import { XhrService } from "./xhr.service";
import { BusinessReferral } from "src/app/interface/data/business.interface";
import { eSiteStatus, iInitWebSite } from "src/app/interface/data/site.interface";
import { PlatformService } from "../platform.service";
import { makeStateKey } from "@angular/platform-browser";
import { SessionService } from "../session.service";
import { SITE_ERROR_STATE, SITE_STATE_KEY } from "src/app/constants";

@Injectable({
	providedIn: "root",
})
export class ApiSitesService {
	constructor(private Endpoints: RouterEndpointsService, private XHR: XhrService, private Platform: PlatformService, private Session: SessionService) {}

	getInfo() {
		return this.XHR.get(this.Endpoints.SITE.INFO).pipe(
			tap((site: iInitWebSite) => {
				this.Platform.setState(SITE_STATE_KEY, site);
				this.Session.setSiteInfo(site);
			}),
			catchError((err) => {
				this.Platform.setState(SITE_ERROR_STATE, err);
				this.Platform.setState(SITE_STATE_KEY, { status: eSiteStatus.OFFLINE });
				this.Session.setSiteInfo({ status: eSiteStatus.OFFLINE });
				return throwError(() => err);
			})
		);
	}

	getCurrencies() {
		return this.XHR.get(this.Endpoints.SITE.CURRENCIES).pipe(
			tap((currencies) => {
				this.Session.setSiteInfo({ ...this.Session.site, currencies });
			})
		);
	}

	postChannels(body: iFormChannel): Observable<iResponse> {
		return this.XHR.post(this.Endpoints.SITE.CHANNELS, body);
	}

	postContact(body: iFormContact): Observable<iResponse> {
		return this.XHR.post(this.Endpoints.SITE.CONTACT, body);
	}

	getReferrer(customerUid: string) {
		return this.XHR.get(this.Endpoints.SITE.REFERRER(customerUid));
	}

	getBusiness(slug: string): Observable<BusinessReferral> {
		return this.XHR.get(this.Endpoints.SITE.BUSINESS(slug));
	}

	getUser(userUid: string) {
		return this.XHR.get(this.Endpoints.SITE.USER(userUid));
	}
}

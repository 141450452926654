import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";
import { NAVBAR } from "src/app/constants";
import { iSidenavProps } from "src/app/interface/components/navbar.interface";
import { iAccount } from "src/app/interface/data/customer.interface";
import { CustomerService } from "src/app/services/customer.service";
import { LanguageService } from "src/app/services/language.service";
import { PlatformService } from "src/app/services/platform.service";
import { RouterPathsService } from "src/app/services/router-paths.service";
import { SessionService } from "src/app/services/session.service";
import { ThemeService } from "src/app/services/theme.service";

@Component({
	selector: "navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
	/** Nombre del Sitio */
	public SiteName: string;
	/** Datos del usuario */
	public userData: iAccount;
	/** Porcentaje del progreso de la cuenta */
	public accountProgressStatusPercent: number;
	/** Valor de ocultacion del menu sobre la parte superior de la pantalla */
	public offsetMenu: "content" | number;
	/** Valor que determina si el nav esta en estado `sticky` o no */
	public isSticky: boolean = false;
	/** Barra de navegacion principal del sitio */
	public navbarData = NAVBAR;
	/** Configuracion del Sidenav izquierdo */
	public sidenavLeft: iSidenavProps = { position: "left", active: false };
	/** Configuracion del Sidenav derecho */
	public sidenavRight: iSidenavProps = { position: "right", active: false };
	/** Obtiene estado de la pestaña `internal` */
	public navIsWhite: boolean;
	/** Valor de lenguages seteados en la configuracion del sitio. */
	get langs() {
		return this.Language.langs;
	}
	/** Determina si el renderizado es del lado del cliente */
	get isBrowser() {
		return this.Platform.isBrowser;
	}
	/** Subcription Customer */
	private sCustomer: Subscription;

	@HostListener("window:resize", ["$event"])
	onResize(ev?) {
		this.offsetMenu = window.innerWidth <= 991 ? 0 : 205;
		this.sidenavLeft.active = false;
		this.sidenavRight.active = false;
	}

	constructor(public Paths: RouterPathsService, public Theme: ThemeService, private Platform: PlatformService, private Session: SessionService, private Customer: CustomerService, private Language: LanguageService) {}

	ngOnInit(): void {
		if (this.isBrowser) this.onResize();
		const { name } = this.Session.site;
		this.SiteName = name;

		this.sCustomer = this.Customer.getCustomer().subscribe((customer) => {
			this.userData = customer;
			if (!this.userData) return;
			this.accountProgressStatusPercent = this.Customer.getProgressStatusPercent();
		});

		this.Session.getInternalPage().subscribe((internalPage) => (this.navIsWhite = internalPage.navIsWhite));
	}

	ngOnDestroy(): void {
		if (this.sCustomer) this.sCustomer.unsubscribe();
	}

	/** Evento que se dispara cuando se hace window:scroll */
	public handleIsSticky(isSticky: boolean) {
		this.isSticky = isSticky;
	}
}

import { Component, OnInit } from "@angular/core";

@Component({
	selector: "asset-building",
	templateUrl: "./building.asset.html",
	styleUrls: ["./building.asset.scss"],
})
export class BuildingAsset implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

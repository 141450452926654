import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "icon-building",
	templateUrl: "./building.icon.html",
	styleUrls: ["./building.icon.scss"],
})
export class BuildingIcon implements OnInit {
	@Input() className: string;

	@Input() styleName: string;

	constructor() {}

	ngOnInit(): void {}
}

import { Component, OnInit } from "@angular/core";

@Component({
	selector: "icon-benefits",
	templateUrl: "./benefits.icon.html",
	styleUrls: ["./benefits.icon.scss"],
})
export class BenefitsIcon implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

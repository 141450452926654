import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BuildingAsset } from "./building.asset";

@NgModule({
	declarations: [BuildingAsset],
	imports: [CommonModule],
	exports: [BuildingAsset],
})
export class BuildingAssetModule {}

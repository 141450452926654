import { eChannel, iInfoBy } from "../common.interface";

/** Solicitud de Documentos */
export interface iRequiredDocument {
	/** Uid de la Peticion */
	uid: string;
	/** Nombre de la peticion  */
	name: string;
	/** Tipo de Información */
	type: eInformationType;
	/** Document description */
	description: string;
	/** Estado de la peticion */
	status: eDocStatus;
	/** Determina esta eliminada */
	isDeleted: boolean;
	/** Determina si es requerida o opcional la solicitud */
	isRequired: boolean;
	/** Informacion de creación */
	createdBy: iInfoBy;
	/** Fecha de creación */
	createdAt: number;
	/** Informacion de actualizacion de estado */
	updatedStatusBy: iInfoBy;
	/** Fecha de Actualización del estado */
	updatedStatusAt: number;
	/** Ultima Modificacìon */
	updatedBy: iInfoBy;
	/** Fecha de Actualización */
	updatedAt: number;
	/** Documentos subidos  */
	uploadedFiles: iDocUploadFile[];
}

export interface iDocUploadFile {
	/** Id de Documento */
	id: number;
	/** Uid del Documento */
	uid: string;
	/** Canal de la subida del documento */
	channel: eChannel;
	/** Estado del documento */
	status: eDocUploadFileStatus;
	/** Determina si el documento a sido eliminado */
	isDeleted: boolean;
	/** Nombre del Documento */
	name: string;
	/** Titulo del Documento */
	documentName: string;
	/** Formato (mimeType) */
	format: string;
	/** Tamaño del archivo */
	size: number;
	/** URL del documento */
	urlView: string;
	/** URL de descarga */
	urlDownload: string;
	/** Informacion de adjuntado */
	attachedBy: iInfoBy;
	/** Fecha de adjuntado */
	attachedAt: number;
	/** Informacion de edicion */
	updatedBy: iInfoBy;
	/** Fecha de edicion */
	updatedAt: string;
}

/** Estado de verificacion de Documentos del Usuario */
export enum eStatusDocument {
	/** Documentos en espera de envio */
	PENDING = "PENDING",
	/** Documentos en revision */
	REVIEW = "REVIEW",
	/** Documentos Validados */
	SUCCESS = "SUCCESS",
}

/** Tipo de documento requerido */
export enum eInformationType {
	/** Documentos de Contacto */
	CONTACT_DATA = "contactData",
	/** Documentos Personales */
	PERSONAL_DATA = "personalData",
	/** Documentos Laborales */
	LABOR_DATA = "laborData",
	/** Documentos de Empresa */
	BUSINESS_DATA = "businessData",
	/** Documentos de Representantes */
	REPRESENTATIVES = "representativeData",
	/** Otro */
	OTHER = "other",
}

/** Estado de la solicitud */
export enum eDocStatus {
	/** Solicitud Abierta */
	OPEN = "OPEN",
	/** Solicitud Cerrada */
	CLOSED = "CLOSED",
}

/** Estado de validacion del documento */
export enum eDocUploadFileStatus {
	/** Documento Aprobado */
	APPROVED = "APPROVED",
	/** Documento Pendiente */
	PENDING = "PENDING",
	/** Documento Rechazado */
	REJECTED = "REJECTED",
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavbarComponent } from "./navbar.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { LogoIconModule } from "../icons/logo/logo.module";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { AvatarModule } from "../avatar/avatar.module";
import { SidenavModule } from "../sidenav/sidenav.module";
import { CallusNavbar } from "./callus/callus.navbar";
import { SetLanguageNavbar } from "./set-language/set-language.navbar";
import { LinkNavbar } from "./link/link.navbar";
import { DropdownNavbar } from "./dropdown/dropdown.navbar";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { AcademyIconModule } from "../icons/academy/academy.module";
import { ButtonNavbar } from "./button/button.navbar";
import { NotificationSubmenu } from "./submenu/notification/notification.submenu";
import { LoaderModule } from "../loader/loader.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { SearchPageIconModule } from "../icons/search-page/search-page.module";
import { UserSubmenu } from "./submenu/user/user.submenu";
import { BtnDirectiveModule } from "src/app/directives/btn/btn.directive.module";
import { OnStickyDirectiveModule } from "src/app/directives/on-sticky/on-sticky.module";
import { InClientDirectiveModule } from "src/app/directives/in-client/in-client.module";

@NgModule({
	declarations: [NavbarComponent, CallusNavbar, SetLanguageNavbar, LinkNavbar, DropdownNavbar, ButtonNavbar, NotificationSubmenu, UserSubmenu],
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		LogoIconModule,
		NzIconModule,
		NzProgressModule,
		AvatarModule,
		BtnDirectiveModule,
		OnStickyDirectiveModule,
		InClientDirectiveModule,
		SidenavModule,
		NgbDropdownModule,
		NgbTooltipModule,
		AcademyIconModule,
		LoaderModule,
		SearchPageIconModule,
		ScrollingModule,
	],
	exports: [NavbarComponent],
})
export class NavbarModule {}

import { Component } from "@angular/core";
import { CustomerService } from "src/app/services/customer.service";
import { RouterPathsService } from "src/app/services/router-paths.service";

@Component({
	selector: "begin-last",
	templateUrl: "./begin-last.component.html",
	styleUrls: ["./begin-last.component.scss"],
})
export class BeginLastComponent {
	get userStatus() {
		return this.Customer.userAuthStatus();
	}

	constructor(public Paths: RouterPathsService, private Customer: CustomerService) {}
}

import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { finalize } from "rxjs";
import { NotificationDescription, NotificationImg, NotificationPath, NotificationTitle } from "src/app/constants";
import { eNotificationType, iNotification } from "src/app/interface/data/notification.interface";
import { eDBRealtime } from "src/app/interface/data/realtime.interface";
import { ApiAccountService } from "src/app/services/api/api-account.service";
import { CustomerService } from "src/app/services/customer.service";
import { DbRealtimeService } from "src/app/services/db-realtime.service";

@Component({
	selector: "submenu-notification",
	templateUrl: "./notification.submenu.html",
	styleUrls: ["./notification.submenu.scss"],
})
export class NotificationSubmenu implements OnInit {
	@Input() isSticky: boolean = false;

	@Input() internalPage: boolean = false;

	public notifications: iNotification[] = [];

	public NotificationType = eNotificationType;

	public isFetching: boolean = false;

	public isNotRead: number = 0;

	constructor(private Customer: CustomerService, private DBRealtime: DbRealtimeService, private APIAccount: ApiAccountService, private CDR: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.setCountNotifications();
	}

	public setCountNotifications() {
		this.Customer.getChangeNotifications().subscribe((notifications) => {
			this.isNotRead = notifications;
			this.CDR.markForCheck();
		});
	}

	public setNotifications() {
		this.isFetching = true;
		this.APIAccount.getNotifications()
			.pipe(
				finalize(() => {
					this.isFetching = false;
					this.CDR.markForCheck();
				})
			)
			.subscribe({
				next: (notifications) => {
					this.notifications = notifications.map((notification) => {
						if (notification.subType) {
							notification.title = NotificationTitle[notification.subType];
							notification.description = NotificationDescription[notification.subType];
							notification.img = `assets/svg/${NotificationImg[notification.subType]}`;
							notification.path = NotificationPath[notification.subType];
							notification.isInternal = true;
						}
						return notification;
					});
				},
				error: (error) => console.error("ERROR getNotification", error),
			});
	}

	public onOpenNotification(isOpen: boolean) {
		if (isOpen) {
			this.setNotifications();
			this.DBRealtime.setKey(eDBRealtime.UPDATE_NOTIFICATIONS, 0);
			this.Customer.changeNotifications(0);
		}
	}
}

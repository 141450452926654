<div ngbDropdown placement="bottom-left" (openChange)="onOpenNotification($event)">
	<button [attr.aria-label]="'notification.title' | translate" ngbDropdownToggle class="btn-notifications" [class.btn-internal]="internalPage || isSticky">
		<div class="position-relative">
			<span *ngIf="isNotRead" class="badge badge-sm badge-outline-danger badge-pos rounded-circle">{{isNotRead}}</span>
			<i class="far fa-bell"></i>
		</div>
	</button>
	<div ngbDropdownMenu class="dropdown-menu-notifications">
		<div class="notifications">
			<loader *ngIf="isFetching else NOTIFICATION_VIEW" [fullscreen]="false"></loader>
			<ng-template #NOTIFICATION_VIEW>
				<ng-container *ngIf="notifications.length > 0 else EMPTY_NOTIFICATIONS">
					<cdk-virtual-scroll-viewport appendOnly [itemSize]="50">
						<ul class="list-group list-group-flush">
							<li ngbDropdownItem *cdkVirtualFor="let notification of notifications" [class.bg-light]="!notification.isRead" class="list-group-item">
								<ng-container *ngIf="notification else LOADER_ITEM">
									<ng-container *ngIf="notification.isInternal else IS_NOT_INTERNAL">
										<a *ngIf="notification.path else NOTIFICATION_CONTENT" [routerLink]="notification.path">
											<ng-container [ngTemplateOutlet]="NOTIFICATION_CONTENT"></ng-container>
										</a>
									</ng-container>
									<ng-template #IS_NOT_INTERNAL>
										<a *ngIf="notification.path else NOTIFICATION_CONTENT" [href]="notification.path" target="_blank">
											<ng-container [ngTemplateOutlet]="NOTIFICATION_CONTENT"></ng-container>
										</a>
									</ng-template>
									<ng-template #NOTIFICATION_CONTENT>
										<div class="media align-items-center">
											<div class="media-img">
												<avatar size="sm" [avatar]="notification.img" bgDefault="bg-white"></avatar>
											</div>
											<div class="media-body">
												<span>{{ notification.title | translate }}</span>
												<small>{{ notification.description | translate }}</small>
											</div>
										</div>
									</ng-template>
								</ng-container>
								<ng-template #LOADER_ITEM>
									<loader [fullscreen]="false"></loader>
								</ng-template>
							</li>
						</ul>
					</cdk-virtual-scroll-viewport>
				</ng-container>
				<ng-template #EMPTY_NOTIFICATIONS>
					<div class="empty-notifications">
						<icon-search-page *inClient></icon-search-page>
						<p class="mb-0">{{'account.notifications.empty.title' | translate}}</p>
					</div>
				</ng-template>
			</ng-template>
		</div>
	</div>
</div>

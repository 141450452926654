<section class="begin-last-promo space-top-2 position-relative">
	<div class="container">
		<wave-begin-last></wave-begin-last>

		<div class="begin-last-promo-container row justify-content-lg-between align-items-lg-center">
			<div class="col-lg-6 mb-5 mb-lg-0">
				<h3 class="text-white font-weight-medium mb-1" [innerHTML]="'beginLastPromo.title' | translate | safe"></h3>
				<p class="begin-last-promo-description text-white-70 mb-0">{{ "beginLastPromo.description" | translate }}</p>
			</div>

			<div class="col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end flex-column flex-sm-row">
				<a class="begin-last-promo-beginnow transition-3d-hover btn btn-wide mb-sm-0 mb-4 mr-0 mr-sm-4" [routerLink]="userStatus.link">
					<span>{{ "home." + userStatus.status | translate }}</span>
				</a>
				<a class="begin-last-promo-investment" [routerLink]="Paths.GO.PROJECTS">
					<span>{{ "home.main.investmentProjects" | translate }}</span>
				</a>
			</div>
		</div>
	</div>
</section>

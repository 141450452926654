export interface iResponse {
	code: eResponse;
	error: eResponse;
}

export enum eResponse {
	/* Respuestas Generales */
	UPDATE_OK = "UPDATE_OK",
	UPDATE_NOT_OK = "UPDATE_NOT_OK",
	SAVE_OK = "SAVE_OK",
	SAVE_NOT_OK = "SAVE_NOT_OK",
	DELETE_OK = "DELETE_OK",
	DELETE_NOT_OK = "DELETE_OK",
	COPY_OK = "COPY",
	COPY_NOT_OK = "NOT_COPY",

	NOT_FOUND = "NOT_FOUND",

	/** El usuario no fue registrado por medio de Formulario */
	NOT_SIGNUP_FORM = "NOT_SIGNUP_FORM",
	/** El email del usuario no fue registrado */
	EMAIL_NOT_FOUND = "EMAIL_NOT_FOUND",

	/** Expiro el tiempo para resetear la contraseña */
	RESET_PASSWORD_TIME_EXPIRED = "RESET_PASSWORD_TIME_EXPIRED",

	SITE_NOTIFICATION_NOT_FOUND = "SITE_NOTIFICATION_NOT_FOUND",

	/** El email fue enviado */
	EMAIL_SEND = "EMAIL_SEND",
	/** El email no fue enviado */
	EMAIL_NOT_SEND = "EMAIL_NOT_SEND",
	/** El email tiene un formato invalido */
	EMAIL_NOT_VALID_FORMAT = "EMAIL_NOT_VALID_FORMAT",
	/** El email se encuentra en uso */
	EMAIL_IN_USE = "EMAIL_IN_USE",
	/** El email esta verificado */
	THIS_EMAIL_IS_VERIFY = "THIS_EMAIL_IS_VERIFY",
	/** Email actualizado */
	EMAIL_UPDATE = "EMAIL_UPDATE",

	EMAIL_OR_PHONE_IN_USE = "EMAIL_OR_PHONE_IN_USE",

	/** Codigo en uso */
	CODE_IN_USE = "CODE_IN_USE",
	/** Codigo invalido */
	INVALID_CODE = "INVALID_CODE",
	/** Formato invalido */
	INVALID_TO = "INVALID_TO",
	/** Formato invalido */
	INVALID_TO_PHONE = "INVALID_TO_PHONE",
	/** Formato invalido */
	INVALID_TO_EMAIL = "INVALID_TO_EMAIL",
	/** Formato Invalido */
	FORMAT_INVALID = "FORMAT_INVALID",
	/** Codigo invalido */
	CODE_NOT_VALID = "CODE_NOT_VALID",

	/** Tiempo expirado */
	EXPIRATION_TIME = "EXPIRATION_TIME",

	/** En uso */
	IN_USE = "IN_USE",
	/** No existe */
	NOT_IN_USE = "NOT_IN_USE",
	/** id o taxId en uso */
	ID_IN_USE = "ID_IN_USE",
	/** id o taxId no estan en uso  */
	ID_NOT_IN_USE = "ID_NOT_IN_USE",
	/** El formato de id o taxId es invalido */
	ID_NOT_VALID_FORMAT = "ID_NOT_VALID_FORMAT",

	/** Cuenta Creada */
	CONGRATULATIONS_CREATE_ACCOUNT = "CONGRATULATIONS_CREATE_ACCOUNT",

	/** Datos de Contacto Actualizado */
	CONTACT_DATA_UPDATE = "CONTACT_DATA_UPDATE",

	/** Telefono en uso */
	PHONE_IN_USE = "PHONE_IN_USE",

	/** El telefono esta verficado  */
	THIS_PHONE_IS_VERIFY = "THIS_PHONE_IS_VERIFY",

	/** Datos personales actualizados */
	PERSONAL_DATA_UPDATE = "PERSONAL_DATA_UPDATE",

	/** Datos de estado civil actualizados */
	MARITAL_STATUS_UPDATE = "MARITAL_STATUS_UPDATE",

	/** Datos laborales actualizados */
	LABOR_DATA_UPDATE = "LABOR_DATA_UPDATE",

	/** Datos de la empresa actualizados */
	BUSINESS_DATA_UPDATE = "BUSINESS_DATA_UPDATE",

	/** Representante agregado */
	REPRESENTATIVE_ADD = "REPRESENTATIVE_ADD",

	/** Representante actualizado */
	REPRESENTATIVE_UPDATE = "REPRESENTATIVE_UPDATE",

	/** Representante eliminado  */
	REPRESENTATIVE_DELETE = "REPRESENTATIVE_DELETE",

	/** Error general de logeo */
	LOGIN_GENERAL_ERRROR = "LOGIN_GENERAL_ERRROR",
	/** El usuario existe */
	USER_HAS_ACCOUNT = "USER_HAS_ACCOUNT",
	/** Error en el login */
	LOGIN_FAILED = "LOGIN_FAILED",
	/** Bienvenido usuario */
	LOGIN_WELCOME = "LOGIN_WELCOME",
	/** Error en el registro */
	REGISTER_FAILED = "REGISTER_FAILED",
	/** Bienvenido Usuario nuevo */
	REGISTER_WELCOME = "REGISTER_WELCOME",
	/** No se encontro el usuario */
	USER_NOT_FOUND = "USER_NOT_FOUND",

	DOCUMENT_DELETE = "DOCUMENT_DELETE",
	DOCUMENT_NO_DELETE = "DOCUMENT_NO_DELETE",
	DOCUMENT_ALREADY_SIGNATURE = "DOCUMENT_ALREADY_SIGNATURE",

	//Respuestas del Frontend
	/** El usuario a cambiado la contraseña */
	ACCOUNT_SECURITY_UPDATE = "ACCOUNT_SECURITY_UPDATE",
	/** No se ha podido cambiar la contraseña */
	ACCOUNT_SECURITY_NOT_UPDATE = "ACCOUNT_SECURITY_NOT_UPDATE",

	RESET_PASSWORD_OK_UPDATE = "RESET_PASSWORD_OK_UPDATE",

	BLOG_NOT_FOUND = "BLOG_NOT_FOUND",

	REQUEST_DOCUMENT_CLOSE = "REQUEST_DOCUMENT_CLOSE",

	/* Sitio */
	INVALID_PAYMENT_METHOD = "INVALID_PAYMENT_METHOD",
	CONTACT_REQUEST_NOT_SAVED = "CONTACT_REQUEST_NOT_SAVED",
	CURRENCY_NOT_FOUND = "CURRENCY_NOT_FOUND",

	/* Auth */
	AUTH_ERROR_REGISTERED = "AUTH_ERROR_REGISTERED",
	AUTH_ERROR_TOKEN = "AUTH_ERROR_TOKEN",
	AUTH_ALREADY_REGISTERED = "AUTH_ALREADY_REGISTERED",
	AUTH_ERROR_EMAIL_OR_PASSWORD = "AUTH_ERROR_EMAIL_OR_PASSWORD",
	AUTH_USER_OTHER_SITE = "AUTH_USER_OTHER_SITE",
	AUTH_USER_NOT_FOUND = "AUTH_USER_NOT_FOUND",

	/* Cliente */
	CUSTOMER_AVATAR_UPDATED = "CUSTOMER_AVATAR_UPDATED",
	CUSTOMER_AVAILABLE_INSUFFICIENT = "CUSTOMER_AVAILABLE_INSUFFICIENT",
	CUSTOMER_NOT_ACTIVE = "CUSTOMER_NOT_ACTIVE",
	CUSTOMER_NOT_FOUND = "CUSTOMER_NOT_FOUND",
	IDENTITY_VALIDATION_VERIFIED = "IDENTITY_VALIDATION_VERIFIED",
	IDENTITY_VALIDATION_PENDING = "IDENTITY_VALIDATION_PENDING",
	IDENTITY_VALIDATION_REJECTED = "IDENTITY_VALIDATION_REJECTED",
	PASSWORD_NOT_MATCH = "PASSWORD_NOT_MATCH",

	/* Envio de codigos de verificación */
	CODE_SENDED = "CODE_SENDED",
	CODE_NOT_SENDED = "CODE_NOT_SENDED",
	CODE_VERIFIED = "CODE_VERIFIED",
	CODE_NOT_VERIFIED = "CODE_NOT_VERIFIED",
	CODE_EXPIRE = "CODE_EXPIRE",
	CODE_INVALID = "CODE_INVALID",
	PHONE_UPDATE = "PHONE_UPDATE",
	PHONE_NOT_UPDATE = "PHONE_NOT_UPDATE",

	/* Datos Bancarios */
	BANK_ALREADY_EXISTS = "BANK_ALREADY_EXISTS",
	BANK_IS_NOT_THE_USER = "BANK_IS_NOT_THE_USER",
	BANK_SERVICE_UNAVAILABLE = "BANK_SERVICE_UNAVAILABLE",
	BANK_INVALID_FORMAT = "BANK_INVALID_FORMAT",
	BANK_SAVE = "BANK_SAVE",
	BANK_NOT_SAVE = "BANK_NOT_SAVE",
	BANK_PIN_UP = "BANK_PIN_UP",
	BANK_PIN_UP_NOT_OK = "BANK_PIN_UP_NOT_OK",
	BANK_DELETE = "BANK_DELETE",
	BANK_DELETE_NOT_OK = "BANK_DELETE_NOT_OK",
	BANK_NOT_FOUND = "BANK_NOT_FOUND",

	/* Beneficios */
	BENEFIT_ADD = "BENEFIT_ADD",
	BENEFIT_ADD_TO_INVESTMENT = "BENEFIT_ADD_TO_INVESTMENT",
	BENEFIT_NOT_FOUND = "BENEFIT_NOT_FOUND",
	BENEFIT_NOT_APPLY = "BENEFIT_NOT_APPLY",
	BENEFIT_IN_USE = "BENEFIT_IN_USE",
	BENEFIT_REMOVE = "BENEFIT_REMOVE",
	INVALID_BENEFIT = "INVALID_BENEFIT",
	INVALID_BENEFIT_PROJECT = "INVALID_BENEFIT_PROJECT",
	INVALID_BENEFIT_AMOUNT = "INVALID_BENEFIT_AMOUNT",
	INVALID_BENEFIT_EXPIRE = "INVALID_BENEFIT_EXPIRE",

	/** Encuestas */
	SURVEY_NOT_FOUND = "SURVEY_NOT_FOUND",

	/* Proyectos */
	PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND",
	PROJECT_LIKE_EXISTS = "PROJECT_LIKE_EXISTS",
	PROJECT_LIKE = "PROJECT_LIKE",
	INVALID_PROJECT = "INVALID_PROJECT",

	/* Inversiones */
	INVESTMENT_NOT_CREATED = "INVESTMENT_NOT_CREATED",
	INVESTMENT_INVALID = "INVESTMENT_INVALID",
	INVESTMENT_NOT_FOUND = "INVESTMENT_NOT_FOUND",
	INVESTMENT_NOTIFIED = "INVESTMENT_NOTIFIED",
	INVESTMENT_CANCELED = "INVESTMENT_CANCELED",
	INVESTMENT_NOT_CANCELED = "INVESTMENT_NOT_CANCELED",
	INVESTMENT_NOT_PENDING = "INVESTMENT_NOT_PENDING",

	/* Participaciones */
	SHARE_NOT_CREATED = "SHARE_NOT_CREATED",
	SHARE_NOT_FOUND = "SHARE_NOT_FOUND",

	/** Movimientos */
	MOVEMENT_NOT_CREATED = "MOVEMENT_NOT_CREATED",
	MOVEMENT_NOT_ASSIGNED = "MOVEMENT_NOT_ASSIGNED",
	MOVEMENT_NOT_DELETED = "MOVEMENT_NOT_DELETED",
	MOVEMENT_DELETED = "MOVEMENT_DELETED",
	MOVEMENT_NOT_FOUND = "MOVEMENT_NOT_FOUND",
	MOVEMENTS_PENDING = "MOVEMENTS_PENDING",
	MOVEMENT_NOT_PENDING = "MOVEMENT_NOT_PENDING",

	/* Servidor */
	INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
	SECURITY_ERROR_TOKEN_ERROR = "SECURITY_ERROR_TOKEN_ERROR",
	SECURITY_ERROR_TOKEN_EXPIRED = "SECURITY_ERROR_TOKEN_EXPIRED",
	SECURITY_ERROR_HAS_NO_PERMISSION = "SECURITY_ERROR_HAS_NO_PERMISSION",
	SECURITY_ERROR_INVALID_INSTANCEID = "SECURITY_ERROR_INVALID_INSTANCEID",
	INVALID_REQUEST_PARAMETERS = "INVALID_REQUEST_PARAMETERS",
	GENERAL_SERVER_ERROR = "GENERAL_SERVER_ERROR",
	TIMEOUT_ERROR = "TIMEOUT_ERROR",
}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { eStorageKeys, iAmount, iAuthStatus } from "../interface/common.interface";
import { ACCOUNT_TOTAL_PROGRESS_STATUS, eAccountActiveStep, eAccountProgressStatus, eAccountStatus, eIdentityValidationStatus, eUserType, iAccount } from "../interface/data/customer.interface";
import { eDocStatus } from "../interface/data/documents.interface";
import { RouterPathsService } from "./router-paths.service";
import { SessionService } from "./session.service";
import { StorageService } from "./storage.service";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { isNil } from "lodash";
import { DEFAULT_AVATAR } from "../constants";
import { CookiesService } from "./cookies.service";

@Injectable({
	providedIn: "root",
})
export class CustomerService {
	/** Usuario */
	private readonly $data = new BehaviorSubject<iAccount>(null);
	public get data() {
		return this.$data.getValue();
	}
	/** Token del usuario */
	public token!: string;
	/** Fecha de Vencimiento del token */
	public expiration!: number;
	/** Cambios en el auth del usuario */
	private readonly $isAuth = new BehaviorSubject<boolean>(false);
	public get isAuth() {
		return this.$isAuth.getValue();
	}
	/** Determina si hay notificaciones pendientes a revisar */
	private $notifications = new BehaviorSubject<number>(0);
	public get notifications() {
		return this.$notifications.getValue();
	}

	constructor(
		private Paths: RouterPathsService,
		private Translate: TranslateService,
		private Message: NzMessageService,
		private Session: SessionService,
		private Storage: StorageService,
		private Cookies: CookiesService,
		private router: Router
	) {}

	/** Inicializa el servicio de Obtencion de Datos */
	public loadUser() {
		this.token = this.Storage.getToken();
		if (this.token) this.Cookies.set("user-token", this.token, { maxAge: 60 * 60 * 24 * 365 });
		this.changeAuth(this.token ? true : false);
	}
	/** Establece los datos del usuario */
	public setUserData(userData: iAccount) {
		if (!userData.userAvatar) userData.userAvatar = DEFAULT_AVATAR;
		this.$data.next(userData);
	}
	/** Establece el token y la fecha de vencimiento del usuario */
	public setToken(token: string) {
		this.token = token;
		this.Cookies.set("user-token", token, { maxAge: 60 * 60 * 24 * 365 });
		this.Storage.saveToken(this.token);
	}
	/** Elimina los datos del usuario */
	public logout() {
		if (this.data) {
			const { isLegalEntity, personalData, businessData } = this.data;
			this.Message.success(this.Translate.instant("logout.bye", { name: isLegalEntity ? businessData.businessName : personalData.firstName }));
		}
		this.token = null;
		this.expiration = null;
		this.Storage.removeUser();
		this.Cookies.remove(eStorageKeys.TOKEN);
		this.$isAuth.next(false);
		this.$data.next(null);
		this.Session.isSiteBlocked() ? this.router.navigateByUrl(this.Paths.GO.AUTH) : this.router.navigateByUrl(this.Paths.GO.HOME);
	}
	/** Setea el estado de Confirmacion del Usuario */
	public changeAuth = (isAuth: boolean) => this.$isAuth.next(isAuth);
	/** Se mantiene a la escucha del estado de Autenticacion del usuario */
	public getChangeAuth = () => this.$isAuth.asObservable();
	/** Configura las notificaciones pendientes */
	public changeNotifications = (notifications: number) => this.$notifications.next(notifications);
	/** Obtiene si existen notificaciones pendientes */
	public getChangeNotifications = () => this.$notifications.asObservable();
	/** Obtiene la observación de los datos del usuario */
	public getCustomer = () => this.$data.asObservable();

	public userAuthStatus(): iAuthStatus {
		if (this.isAuth) {
			if (this.data.status !== eAccountStatus.ACTIVE) {
				if (!this.data.accountProgressStatus.includes(eAccountProgressStatus.CREATED)) {
					return {
						link: this.Paths.GO.CREATE_ACCOUNT,
						status: "createdAccount",
					};
				}
				return {
					link: this.Paths.GO.ACCOUNT,
					status: "activatedAccount",
				};
			}
			return {
				link: this.Paths.GO.ACCOUNT,
				status: "account",
			};
		}
		return {
			link: this.Paths.GO.AUTH_REGISTER,
			status: "login",
		};
	}

	public getAvailable() {
		const available: iAmount[] = [];
		const { selectedCurrencies } = this.Session.site.account;
		if (selectedCurrencies && this.data.available) {
			selectedCurrencies.forEach((currency) => {
				available.push({
					amount: this.Session.exchangeRate(this.data.available?.amount, this.data.available?.currency, currency),
					currency,
				});
			});
		}
		return available;
	}
	/** Determina si el usuario tiene documentos requiredos */
	public isDocumentRequired(isRequired: boolean = true) {
		const { requiredDocuments } = this.data as iAccount;
		let pendingDocuments: number = 0;
		if (!isNil(requiredDocuments)) {
			const documents = requiredDocuments.filter((doc) => doc.isRequired === isRequired && !doc.isDeleted && doc.status === eDocStatus.OPEN);
			if (documents) pendingDocuments += documents.length;
		}
		return pendingDocuments > 0;
	}
	/** Determina si la cuenta se encuentra en revisión */
	public customerProgress() {
		const {
			identityValidation: siteIdentityValidation,
			isPersonalDataValidation,
			isBusinessDataValidation,
			isContactLegalValidation,
			isContactPhysicalValidation,
			isLaborDataValidation,
			isRepresentativeDataValidation,
		} = this.Session.site.account;
		const { identityValidation, accountProgressStatus, isLegalEntity, personalData, laborData, contactData, businessData, representativeData, requiredDocuments } = this.data as iAccount;
		let observer: { status: eAccountActiveStep; isValid: boolean }[] = [];

		if (!isLegalEntity) {
			if (siteIdentityValidation.enabled) observer.push({ status: eAccountActiveStep.IDENTITY_VALIDATE, isValid: identityValidation?.status === eIdentityValidationStatus.VERIFIED });

			if (isPersonalDataValidation && personalData) observer.push({ status: eAccountActiveStep.PERSONAL_DATA, isValid: personalData.isOk });

			if (isContactPhysicalValidation && contactData) observer.push({ status: eAccountActiveStep.CONTACT_DATA_PHYSICAL, isValid: contactData.isOk });

			if (isLaborDataValidation && laborData) observer.push({ status: eAccountActiveStep.LABOR_DATA, isValid: laborData.isOk });
		} else {
			if (isBusinessDataValidation && businessData) observer.push({ status: eAccountActiveStep.BUSINESS_DATA, isValid: businessData.isOk });

			if (isContactLegalValidation && contactData) observer.push({ status: eAccountActiveStep.CONTACT_DATA_LEGAL, isValid: contactData.isOk });

			if (isRepresentativeDataValidation && representativeData) observer.push({ status: eAccountActiveStep.REPRESENTATIVES_DATA, isValid: representativeData.isOk });
		}

		if (!isNil(requiredDocuments)) observer.push({ status: eAccountActiveStep.DOCUMENTS_VALIDATE, isValid: !this.isDocumentRequired() });

		const checks = observer.filter((o) => o.isValid);
		if (checks.length === observer.length && !accountProgressStatus.includes(eAccountProgressStatus.ACTIVATED)) observer.push({ status: eAccountActiveStep.REVISION_MANUALLY, isValid: false });

		return observer;
	}
	/** Obtiene el porcentaje de Progreso de la cuenta */
	public getProgressStatusPercent = () => this.data && (this.data.accountProgressStatus.length * 100) / ACCOUNT_TOTAL_PROGRESS_STATUS;
	/** Determina si el usuario creo la cuenta */
	public isCreated() {
		const userData = this.$data.getValue();
		return userData && userData.userType !== eUserType.REGISTERED_USER;
	}
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OfflineStatus } from "./offline/offline.status";
import { TranslateModule } from "@ngx-translate/core";
import { WarningIconModule } from "../icons/warning/warning.module";
import { MaintenanceStatus } from "./maintenance/maintenance.status";
import { ButtonStoreModule } from "../button-store/button-store.module";
import { SocialMediaModule } from "../social-media/social-media.module";
import { BuildingAssetModule } from "../assets/building/building.module";
import { LogoIconModule } from "../icons/logo/logo.module";
import { SafePipeModule } from "src/app/pipes/safe/safe.module";

@NgModule({
	declarations: [OfflineStatus, MaintenanceStatus],
	imports: [CommonModule, TranslateModule, WarningIconModule, ButtonStoreModule, SafePipeModule, SocialMediaModule, BuildingAssetModule, LogoIconModule],
	exports: [OfflineStatus, MaintenanceStatus],
})
export class StatusModule {}
